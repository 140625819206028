import { Amount } from "./Amount";
import { BasicClient } from "./index";

export enum OrderTypeEnum {
    buy = 1,
    sell = 2,
}

export enum OrderStatusEnum {
    open = 1,
    completed = 3,
    canceled = 4,
}

export enum BookTypeEnum {
    btcUsdc = 1,
    btcReal = 2,
    usdcReal = 3,
}

export interface Order {
    id: string;
    client: BasicClient
    createdAt: Date;
    status: OrderStatusEnum;
    market: BookTypeEnum;
    orderType: OrderTypeEnum;
    pricePerCoin: number;
    quantity: number;
    closedAt?: Date;
}

export interface OrderExchange {
    id: string;
    source: Amount;
    target: Amount;
    createdAt: Date;
    order: Order
}