import { Coin, CoinEnum } from './Coin';
import { coinOptions } from '../utils/constants';

export enum PixKeyTypeEnum {
    phoneNumber = 1,
    cpf = 2,
    cnpj = 3,
    random = 4,
    email = 5,
}

export interface PixKey {
    keyType: PixKeyTypeEnum;
    value: string;
}

export type WalletCoins = CoinEnum.usdt | CoinEnum.btc | CoinEnum.usdc | CoinEnum.eth | CoinEnum.brs | CoinEnum.brl;
export type WalletCoin = Coin & { id: WalletCoins };
export const walletCoinOptions: WalletCoin[] = coinOptions.filter(x => x.id !== CoinEnum.usd)
    .map(x => ({ ...x, id: x.id as WalletCoins }));

export interface CryptoWallet {
    coin: CoinEnum.usdt | CoinEnum.btc | CoinEnum.usdc | CoinEnum.eth | CoinEnum.brs;
    key: string;
    alias?: string;
}

export interface PaperWallet {
    coin: CoinEnum.brl;
    key: PixKey;
}

export type WithdrawWallet = CryptoWallet | PaperWallet;

export type DepositWallet = [{
    coin: CoinEnum,
    address: string
}]
