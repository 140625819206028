import React, { useEffect, useState } from "react";
import styles from "./client-overdraft-limit.module.scss";
import Save from '../../../../assets/images/icons/save.svg';
import { Field } from "../../../utils/field";
import { FloatingActionButton } from "../../../utils/floating-action-button";
import {
    fetchOverdraftLimit,
    getOverdraftLimit,
    updateOverdraftLimit, updateOverdraftLimitSelectedClient
} from "../../../../store/clientSlice";
import { CoinEnum } from "../../../../types/Coin";
import { OverdraftLimit } from "../../../../types/OverdraftLimit";
import { useAppDispatch } from "../../../../hooks";
import { useSelector } from "react-redux";

export const ClientOverdraftLimit = () => {
    const dispatch = useAppDispatch();
    const overdraftLimit = useSelector(getOverdraftLimit);

    const [realLimit, setRealLimit] = useState(0);
    const [bitcoinLimit, setBitcoinLimit] = useState(0);
    const [ethLimit, setEthLimit] = useState(0);
    const [usdtLimit, setUsdtLimit] = useState(0);
    const [usdcLimit, setUsdcLimit] = useState(0);
    const [brsLimit, setBrsLimit] = useState(0);

    useEffect(() => {
        dispatch(fetchOverdraftLimit());

        return () => {
            dispatch(updateOverdraftLimitSelectedClient(null))
        }
    }, []);

    useEffect(() => {
        if (!overdraftLimit) {
            return;
        }

        const getValue = (coin: CoinEnum): number =>
            overdraftLimit.amounts.find(x => x.coin === coin)?.value ?? 0;

        setRealLimit(getValue(CoinEnum.brl));
        setBitcoinLimit(getValue(CoinEnum.btc));
        setEthLimit(getValue(CoinEnum.eth));
        setUsdtLimit(getValue(CoinEnum.usdt));
        setUsdcLimit(getValue(CoinEnum.usdc));
        setBrsLimit(getValue(CoinEnum.brs));
    }, [overdraftLimit]);

    const updateLimits = (): void => {
        const payload: OverdraftLimit = {
            clientId: overdraftLimit!.clientId,
            amounts: [
                { coin: CoinEnum.brl, value: realLimit },
                { coin: CoinEnum.btc, value: bitcoinLimit },
                { coin: CoinEnum.eth, value: ethLimit },
                { coin: CoinEnum.usdt, value: usdtLimit },
                { coin: CoinEnum.usdc, value: usdcLimit },
                { coin: CoinEnum.brs, value: brsLimit },
            ]
        };

        dispatch(updateOverdraftLimit(payload))
    }

    if (!overdraftLimit)
        return <span>Loading</span>

    return (
        <div className={styles.overdraftLimit}>
            <Field
                placeholder="0"
                type="number"
                prefix="R$"
                value={realLimit}
                onChange={setRealLimit}
                className={styles.field}
            />

            <Field
                placeholder="0"
                type="number"
                prefix="BTC"
                value={bitcoinLimit}
                onChange={setBitcoinLimit}
                className={styles.field}
            />

            <Field
                placeholder="0"
                type="number"
                prefix="ETH"
                value={ethLimit}
                onChange={setEthLimit}
                className={styles.field}
            />

            <Field
                placeholder="0"
                type="number"
                prefix="USDT"
                value={usdtLimit}
                onChange={setUsdtLimit}
                className={styles.field}
            />

            <Field
                placeholder="0"
                type="number"
                prefix="USDC"
                value={usdcLimit}
                onChange={setUsdcLimit}
                className={styles.field}
            />

            <Field
                placeholder="0"
                type="number"
                prefix="BRS"
                value={brsLimit}
                onChange={setBrsLimit}
                className={styles.field}
            />

            <FloatingActionButton onClick={updateLimits} icon={Save}/>
        </div>
    )
}