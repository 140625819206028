import React from "react"
import { Card } from "../../utils/card";
import { getPendingDepositNotice, } from "../../../store/depositSlice";
import { DepositNoticeList } from "../deposit-notice-list";
import styles from './peding-deposit.module.scss'
import { useSelector } from "react-redux";

export const PendingDepositNotice = () => {
    const lstPendingDepositNotice = useSelector(getPendingDepositNotice);

    if (!lstPendingDepositNotice.length)
        return null;

    return (
        <Card
            title={"Pending Deposit Notice"}
            className={styles.pendingDepositNotice}>
            <DepositNoticeList
                data={lstPendingDepositNotice}
                showClient={true}
            />
        </Card>
    );
};