import React, { useCallback, useEffect, useState } from "react";
import { Dialog } from "../../../utils/dialog";
import {
    addWallet,
    getSelectedClient,
    getWalletFormIsOpened,
    updateAddWalletIsOpen
} from "../../../../store/clientSlice";
import { getFullName, labelKeyPix } from "../../../../utils";
import styles from './add-wallet.module.scss';
import { Field } from "../../../utils/field";
import { SelectField, SelectFieldOptions } from "../../../utils/select-field";
import { CoinEnum } from "../../../../types/Coin";
import { PixKeyTypeEnum, walletCoinOptions, WalletCoins, WithdrawWallet } from "../../../../types/Wallet";
import { useAppDispatch } from "../../../../hooks";
import { useSelector } from "react-redux";

export const AddWallet = () => {
    const dispatch = useAppDispatch();

    const [type, setType] = useState<WalletCoins>(CoinEnum.btc);
    const [value, setValue] = useState('');
    const [name, setName] = useState('');
    const [pixKeyType, setPixKeyType] = useState<PixKeyTypeEnum>(PixKeyTypeEnum.cpf);

    const selectedClient = useSelector(getSelectedClient);
    const shouldBeOpen = useSelector(getWalletFormIsOpened);

    const walletTypeOptions: SelectFieldOptions<WalletCoins> = walletCoinOptions
        .map(x => ({value: x.id, label: x.label}));

    useEffect(() => {
        setValue('')
    }, [shouldBeOpen]);

    const getWallet = (): WithdrawWallet | undefined => {
        if (!value)
            return;

        if (type === CoinEnum.brl)
            return {
                coin: type,
                key: {keyType: pixKeyType, value}
            };

        return {
            coin: type,
            key: value,
            alias: name
        }
    };

    const wallet = getWallet();
    const isWalletPaper = type === CoinEnum.brl;

    const pixKeyOptions: SelectFieldOptions<PixKeyTypeEnum> =
        Array.from(labelKeyPix.entries())
            .map(([value, label]) => ({label, value,}));

    const cleanDialog = useCallback(() => {
        setType(CoinEnum.btc)
        setValue('');
        setName('');
        setPixKeyType(PixKeyTypeEnum.cpf)
    }, [setType, setValue, setName, setPixKeyType])

    const handleAddWallet = useCallback(() => {
        dispatch(addWallet(wallet!));
        cleanDialog();
    }, [dispatch, cleanDialog, wallet])

    const handleClose = useCallback(() => {
        dispatch(updateAddWalletIsOpen(false));
        cleanDialog();
    }, [dispatch, cleanDialog])

    return (
        <Dialog
            isOpen={shouldBeOpen}
            onClose={handleClose}
            title='Add Wallet'
            subtitle={`For ${getFullName(selectedClient)}`}
            action='Create'
            onAction={handleAddWallet}
            actionDisabled={!wallet}>
            <div className={styles.addWallet}>
                {!isWalletPaper &&
                (<Field
                    label='Name'
                    placeholder='Code Wallet'
                    value={name}
                    onChange={setName}/>)}
                <SelectField options={walletTypeOptions} onChange={setType} value={type} label="Coin"/>
                {isWalletPaper && (
                    <SelectField options={pixKeyOptions} onChange={setPixKeyType} value={pixKeyType} label="Pix Key"/>
                )}
                <Field
                    label='Value'
                    placeholder='1EiGFTC2avw8CpfTUbVgUyaNQHFanebHyG'
                    value={value}
                    onChange={setValue}/>
            </div>
        </Dialog>
    );
}
