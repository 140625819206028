import {FunctionComponent} from "react";
import React from "react";
import style from './label.module.scss';

interface Props {
    text: string;
    content?: string;
    className?: string;
    children?: React.ReactNode;
}

export const Label: FunctionComponent<Props> = props => {
    return (
        <div className={style.label}>
            <span className={style.title}>{props.text}</span>
            <div className={`${style.content} ${props.className}`}>{props.content || props.children}</div>
        </div>
    );
}
