import { createSlice } from "@reduxjs/toolkit";
import { DownloadReportRequest } from "../types/Report";
import { AppThunk } from "./index";
import ReportService from "../services/ReportService";
import { ReportPerPeriodEnum } from "../types/Reports";
import { downloadData } from "../utils/downloadData";
import { Client } from "../types/Client";

interface ReportState {
}

const initialState: ReportState = {}

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {}
});

type getReportData = (since: Date | undefined, until: Date | undefined) => Promise<string>;

interface fileReportData {
    action: getReportData,
    fileName: string,
}

const mapUrls: Record<ReportPerPeriodEnum, fileReportData> = {
    [ReportPerPeriodEnum.Client]: { fileName: 'Clients list', action: ReportService.listClients },
    [ReportPerPeriodEnum.Exchange]: { fileName: 'List Exchanges', action: ReportService.listExchanges },
    [ReportPerPeriodEnum.Withdraw]: { fileName: 'List Withdraws', action: ReportService.listWithdraws },
    [ReportPerPeriodEnum.Deposit]: { fileName: 'Deposits List', action: ReportService.listDeposits },
    [ReportPerPeriodEnum.CreditAnnotation]: { fileName: 'Credit Annotation', action: ReportService.listCreditAnnotation },
    [ReportPerPeriodEnum.BookOrder]: { fileName: 'Book Order', action: ReportService.listBookOrders },
    [ReportPerPeriodEnum.BookTransaction]: { fileName: 'Book Transaction', action: ReportService.listBookTransactions },
    [ReportPerPeriodEnum.TravelexManualTrade]: {fileName: 'Travelex Manual Trades', action: ReportService.listTravelexManualTrades }
}

const csv = 'text/csv';
export const downloadReportPerPeriod = (obj: DownloadReportRequest): AppThunk => dispatch => {
    const { action, fileName } = mapUrls[obj.type];

    console.log('since', obj.since?.startOfTheDay())
    action(obj.since?.startOfTheDay(), obj.until?.endOfTheDay())
        .then(reportData =>
            downloadData(reportData, csv, `${fileName} ${new Date().toISOString()}.csv`));
};

export const downloadStatement = (client: Client): AppThunk => dispatch => {
    const fileName = `${client.firstName}'s statement ${new Date().toISOString()}.csv`;

    ReportService.getAllStatement(client.id)
        .then(allStatement => downloadData(allStatement, csv, fileName))
}

export const downloadReportDepositNotice = (client: Client): AppThunk => dispatch => {
    const fileName = `${client.firstName}'s deposit notices ${new Date().toISOString()}.csv`;

    ReportService.getAllDepositNotices(client.id)
        .then(allDepositNotices => downloadData(allDepositNotices, csv, fileName))
}

export const downloadAllBalances = (): AppThunk => dispatch => {
    const fileName = `All balances ${new Date().toISOString()}.csv`;

    ReportService.getAllBalances()
        .then(report => downloadData(report, csv, fileName))
}

export default reportSlice.reducer;
