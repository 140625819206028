import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Withdraw, WithdrawStatusEnum} from '../types/Withdraw';
import {AppThunk, RootState} from './index';
import WithdrawService from '../services/WithdrawService';
import {delay, updateOnList} from "../utils";
import {showAlert} from "./notificationSlice";
import {apiResponseFormatter} from "../services/Api";
import { Socket } from "socket.io-client";

interface WithdrawState {
    pendingWithdraws: Withdraw[];
    selectedWithdraw: Withdraw | null;
    markAsCompletedIsOpen: boolean;
    detailIsOpen: boolean;
}

const initialState: WithdrawState = {
    pendingWithdraws: [],
    selectedWithdraw: null,
    markAsCompletedIsOpen: false,
    detailIsOpen: false
};


const withdrawSlice = createSlice({
    name: 'withdraw',
    initialState,
    reducers: {
        openWithdrawDetail: (state, action: PayloadAction<Withdraw | null>) => {
            state.selectedWithdraw = action.payload;
            state.detailIsOpen = true;
        },
        openMarkAsCompleted: (state, action: PayloadAction<Withdraw | null>) => {
            state.detailIsOpen = false;
            state.markAsCompletedIsOpen = true;
            state.selectedWithdraw = action.payload;
        },
        closePopups: (state) => {
            state.selectedWithdraw = null;
            state.markAsCompletedIsOpen = false;
            state.detailIsOpen = false;
        },
        updatePendingWithdraws: (state, action: PayloadAction<Withdraw[]>) => {
            state.pendingWithdraws = action.payload;
        },
        addNewWithdraw: (state, action: PayloadAction<Withdraw>) => {
            state.pendingWithdraws = [...state.pendingWithdraws, action.payload]
        },
        updateWithdraw: (state, action: PayloadAction<Withdraw>) => {
            if (action.payload.status === WithdrawStatusEnum.done) {
                state.pendingWithdraws = state.pendingWithdraws.filter(x => x.id !== action.payload.id);
                state.selectedWithdraw = null;
                return;
            }

            if (state.selectedWithdraw?.id === action.payload.id) {
                state.selectedWithdraw = action.payload;
            }

            state.pendingWithdraws = updateOnList(state.pendingWithdraws, action.payload);
        }
    },
});

export const {closePopups, openMarkAsCompleted, openWithdrawDetail, updatePendingWithdraws, addNewWithdraw, updateWithdraw} = withdrawSlice.actions;

const fetchPendingWithdraws = (): AppThunk => dispatch => {
    WithdrawService.listPending()
        .then(x => {
            dispatch(updatePendingWithdraws(x));
        });
}

export const updateWithdrawStatus = (withdraw: Withdraw, status: WithdrawStatusEnum, txId?: string): AppThunk => dispatch => {
    WithdrawService.updateStatus(withdraw.id, status, txId)
        .then(x => {
            dispatch(showAlert('Withdraw updated'));
        });
}

export const getSelectedWithdraw = (state: RootState): Withdraw | null => state.withdrawSlice.selectedWithdraw;
export const getMarkAsCompleteIsOpen = (state: RootState): boolean => state.withdrawSlice.markAsCompletedIsOpen;
export const getDetailIsOpen = (state: RootState): boolean => state.withdrawSlice.detailIsOpen;
export const getPendingWithdraws = (state: RootState): Withdraw[] => state.withdrawSlice.pendingWithdraws || [];

let wsConnection: Socket;
export const subscribeSocketWithdraw = (): AppThunk => async dispatch => {
    if (wsConnection?.connected) {
        dispatch(unsubscribeSocketWithdraw());
        await delay(1000)
    }

    wsConnection = WithdrawService.connectWebsocket();
    wsConnection.on('withdraw/addNewWithdraw', (withdraw: Withdraw) => {
        const withdrawFormatted = apiResponseFormatter(withdraw);
        dispatch(addNewWithdraw(withdrawFormatted));
    });

    wsConnection.on('withdraw/updateWithdraw', (withdraw: Withdraw) => {
        const withdrawFormatted = apiResponseFormatter(withdraw);
        dispatch(updateWithdraw(withdrawFormatted));
    });

    wsConnection.on('connect', () => dispatch(fetchPendingWithdraws()));
}

export const unsubscribeSocketWithdraw = (): AppThunk => dispatch => {
    if (!wsConnection?.connected)
        return;

    wsConnection?.close();
}

export default withdrawSlice.reducer;
