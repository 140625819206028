import React, { FunctionComponent, useEffect, useState } from 'react';
import { BaseUser, User } from '../../../types/User';
import { Field } from '../field';
import styles from './base-form-user.module.scss';

type ChangedUser = (data: User) => void;
type ChangedBaseUser = (data: BaseUser) => void;
interface Props {
    value?: User,
    onChange: ChangedUser | ChangedBaseUser;
}

export const BaseFormUser: FunctionComponent<Props> = props => {
    const [ email, setEmail ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');

    useEffect(() => {
        if (!props.value)
            return;

        setEmail(props.value.email || '');
        setPhoneNumber(props.value.phoneNumber || '');
        setFirstName(props.value.firstName || '');
        setLastName(props.value.lastName || '');
    }, []); //dont fix the fucking warning, unless if you test it

    useEffect(() => {
        const user = { id: props.value?.id, firstName, lastName, email, phoneNumber } as any;
        props.onChange(user);
    }, [ firstName, lastName, email, phoneNumber ]); //dont fix the fucking warning, unless if you test it
    
    return (
        <div className={styles.formDefault}>
            <Field
                label="First Name"
                placeholder="Jhon"
                type="text"
                required={true}
                onChange={setFirstName}
                value={firstName}
                className={styles.field}
            />
            <Field
                label='Last Name'
                placeholder='Doe'
                type="text"
                required={true}
                onChange={setLastName}
                value={lastName}
                className={styles.field}
            />

            <Field
                label='Email'
                placeholder='jhonDoe@icloud.com'
                type="email"
                required={true}
                onChange={setEmail}
                value={email}
                className={styles.field}
            />

            <Field
                label='Phone Number'
                placeholder='5511931093258'
                type="phoneNumber"
                required={true}
                onChange={setPhoneNumber}
                value={phoneNumber}
                className={styles.field}
            />
        </div>
    );
};
