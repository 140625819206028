import React, { useCallback, useState } from 'react';
import { createOperator } from '../../../store/operatorSlice';
import { BaseFormUser } from '../../utils/base-form-user';
import styles from './operator.module.scss';
import { HeaderApp } from '../../utils/header';
import { Button } from '../../utils/button';
import { User } from '../../../types/User';
import { Card } from '../../utils/card';
import { useAppDispatch } from "../../../hooks";
import { useNavigate } from "react-router-dom";


export const AddOperator = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [ operator, setOperator ] = useState<User|null>(null);

    const updateDataOperator = useCallback((data: any) => {
        if (!data)
            return

        setOperator(data);
    }, [setOperator]);

    const goToOperators = () => navigate('/operators');

    const isCreateDisable = !operator?.email || !operator?.firstName || !operator?.lastName || !operator?.phoneNumber

    return (
        <Card className={styles.operator}>
            <HeaderApp title={`Create Operator`}/>
            <div className={styles.form}>
                <BaseFormUser onChange={updateDataOperator} />
                <Button 
                    className={styles.button} 
                    label='Create' 
                    disabled={isCreateDisable}
                    onClick={() => dispatch(createOperator(operator!, goToOperators))}/>
            </div>
        </Card>
    );
};
