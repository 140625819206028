import React, { FunctionComponent } from 'react';
import { DepositNotice, DepositNoticeStatusEnum } from "../../types/Deposit";
import { updateDepositNoticeStatus } from "../../store/depositSlice";
import { Button } from "../utils/button";
import styles from "./ActionDepositNotice.module.scss"
import { useAppDispatch } from "../../hooks";

interface Props {
    notice: DepositNotice;
    showActions?: boolean;
}

export const ActionDepositNotice: FunctionComponent<Props> = props => {
    const dispatch = useAppDispatch();

    const status = props.notice.status;

    const completedStatus = [
        DepositNoticeStatusEnum.Denied,
        DepositNoticeStatusEnum.Approved,
    ]

    if (completedStatus.includes(status))
        return null;

    if (props.notice.status === DepositNoticeStatusEnum.Pending) {
        const action = () => dispatch(updateDepositNoticeStatus(props.notice, DepositNoticeStatusEnum.Doing));
        return <Button
            label="Assign to Me"
            appearance='outline'
            onClick={action}/>;
    }

    const actionApprove = () => dispatch(updateDepositNoticeStatus(props.notice, DepositNoticeStatusEnum.Approved));
    const actionDeny = () => dispatch(updateDepositNoticeStatus(props.notice, DepositNoticeStatusEnum.Denied));

    if (!props.showActions)
        return null;

    return (
        <div className={styles.actions}>
            <Button appearance='error' label="Deny" onClick={actionDeny}/>
            <Button label="Approve" className={styles.approve} onClick={actionApprove}/>
        </div>
    );
}


