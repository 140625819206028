import {
    closePopUp,
    fetchReceiptImage,
    getDocumentImage,
    getSelectedDepositNotice,
    getSelectedDepositNoticeAmount,
    updateDepositNoticeAmount,
} from "../../../store/depositSlice";
import React, { useCallback, useEffect } from "react";
import { Dialog } from "../../utils/dialog";
import { Label } from "../../utils/label";
import styles from './deposit-detail.module.scss'
import { DepositNoticeHistory } from "../deposit-notice-history";
import { Field } from "../../utils/field";
import { formatPrice, getFullName, labelDepositNoticeStatus, phoneFormatter } from "../../../utils";
import { getCoinInfo } from "../../../utils/constants";
import { ActionDepositNotice } from "../ActionDepositNotice";
import { DepositNoticeStatusEnum } from "../../../types/Deposit";
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

export const DepositNoticeDetail = () => {
    const dispatch = useAppDispatch();

    const selectedDepositNotice = useSelector(getSelectedDepositNotice);
    const value = useSelector(getSelectedDepositNoticeAmount)!;
    const selectDepositNoticeImage = useSelector(getDocumentImage);
    const isOpen = !!selectedDepositNotice;

    useEffect((): void => {
        if (!selectedDepositNotice)
            return;

        dispatch(fetchReceiptImage(selectedDepositNotice.imageId))
    }, [selectedDepositNotice]);

    const setValue = useCallback((newValue: number) => {
        dispatch(updateDepositNoticeAmount(newValue))
    }, [])

    if (!selectedDepositNotice)
        return null;

    const status = selectedDepositNotice.status;

    const renderAmount = () => {
        if(status === DepositNoticeStatusEnum.Doing)
            return <Field
                placeholder='1005'
                value={value}
                type="number"
                onChange={setValue}
                label='Amount'
                prefix={getCoinInfo(selectedDepositNotice.amount.coin).symbol}/>

        return <Label text={'Amount'} content={formatPrice(selectedDepositNotice.amount.coin, value,)}/>
    }

    return (
        <Dialog className={styles.depositNoticeDetail} isOpen={isOpen} onClose={() => dispatch(closePopUp())}
                title={'Deposit Notice'}>
            <div className={styles.content}>
                <div className={styles.info}>
                    <Label text={'Client'} content={getFullName(selectedDepositNotice.client)}/>
                    <Label text={'Phone'} content={phoneFormatter(selectedDepositNotice.client.phoneNumber)}/>
                    {renderAmount()}
                    <Label text={'Status'} content={labelDepositNoticeStatus(selectedDepositNotice)}/>
                    <DepositNoticeHistory depositNotice={selectedDepositNotice}/>
                    <ActionDepositNotice notice={selectedDepositNotice} showActions={true}/>
                </div>
                <div className={styles.img}>
                    {!!selectedDepositNotice && (<img
                        src={selectDepositNoticeImage!}
                        alt="Deposit"/>
                    )}
                </div>
            </div>
        </Dialog>
    )
}