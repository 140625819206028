import { Coin, CoinEnum } from '../types/Coin';
import { DeltaTypeEnum } from '../types/Stats';

export const createMap = <T, U extends { id: T }>(list: U[]): Map<T, U> => {
    const result = new Map<T, U>();
    list.forEach(x => result.set(x.id, x));

    return result;
};

export const coinOptions: Coin[] = [
    { id: CoinEnum.brl, label: 'Real', symbol: 'R$', placeholder: '1324.23', maxDecimalPlaces: 2 },
    { id: CoinEnum.usdt, label: 'Tether', symbol: 'USDT', placeholder: '89721', maxDecimalPlaces: 4 },
    { id: CoinEnum.btc, label: 'Bitcoin', symbol: 'BTC', placeholder: '0.000003', maxDecimalPlaces: 8 },
    { id: CoinEnum.usdc, label: 'USDC Coin', symbol: 'USDC', placeholder: '89785', maxDecimalPlaces: 4 },
    { id: CoinEnum.eth, label: 'Ethereum', symbol: 'ETH', placeholder: '0.03851', maxDecimalPlaces: 5 },
    { id: CoinEnum.brs, label: 'BRS', symbol: 'BRS', placeholder: '89785', maxDecimalPlaces: 4 },
    { id: CoinEnum.usd, label: 'USD', symbol: 'USD', placeholder: '28192', maxDecimalPlaces: 2 },
    { id: CoinEnum.trx, label: 'Tron', symbol: 'TRX', placeholder: '125.800044', maxDecimalPlaces: 6 },
    { id: CoinEnum.bcase, label: 'Biocase', symbol: 'BCASE', placeholder: '2500.25', maxDecimalPlaces: 2 },
];
export const coins = createMap(coinOptions);
export const getCoinInfo = (type: CoinEnum): Coin => coins.get(type)!;

const deltaTypeOptions = [
    { id: DeltaTypeEnum.yesterday, label: 'yesterday' },
    { id: DeltaTypeEnum.lastWeek, label: 'last week' },
];
export const deltaTypes = createMap(deltaTypeOptions);
