import { AddressBindingReferral } from '../types/Referral';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '.';
import PartnerService, { CreateAddressBindingReferralDto } from '../services/PartnerService';

interface AddressBindingReferralState {
    lstReferrals: AddressBindingReferral[] | null,
    isAddPopUpOpen: boolean,
}

const initialState: AddressBindingReferralState = {
    lstReferrals: null,
    isAddPopUpOpen: false,
};

const addressBindingReferralSlice = createSlice({
    name: 'referral',
    initialState,
    reducers: {
        updateAddressBindingReferrals: (state, action: PayloadAction<AddressBindingReferral[]>) => {
            state.lstReferrals = action.payload;
        },
        clearAddressBindingReferrals: (state) => {
            state.lstReferrals = null;
        },
        removeAddressBindingReferral: (state, action: PayloadAction<string>) => {
            const referrers = state.lstReferrals!.filter(x => x.id !== action.payload);
            state.lstReferrals = [...referrers];
        },
        openAddAddressBindingReferralPopUp: state => {
            state.isAddPopUpOpen = true;
        },
        closeAddAddressBindingReferralPopUp: state => {
            state.isAddPopUpOpen = false;
        },
    },
});

export const {
    updateAddressBindingReferrals,
    clearAddressBindingReferrals,
    removeAddressBindingReferral,
    openAddAddressBindingReferralPopUp,
    closeAddAddressBindingReferralPopUp,
} = addressBindingReferralSlice.actions;

export const fetchAddressBindingReferrals = (): AppThunk => dispatch =>
    PartnerService.listReferrals()
        .then(lst => dispatch(updateAddressBindingReferrals(lst)));

export const newAddressBindingReferral = (dto: CreateAddressBindingReferralDto): AppThunk<Promise<void>> => dispatch =>
    PartnerService.createReferral(dto)
        .then(() => dispatch(fetchAddressBindingReferrals()));

export const deleteAddressBindingReferral = (referral: AddressBindingReferral): AppThunk => dispatch =>
    PartnerService.deleteReferral(referral.id)
        .then(() => dispatch(removeAddressBindingReferral(referral.id)));

export const getAddressBindingReferrals = (state: RootState): AddressBindingReferral[] | null => state.referrerSlice.lstReferrals;
export const getIsAddPopUpOpen = (state: RootState): boolean => state.referrerSlice.isAddPopUpOpen;

export default addressBindingReferralSlice.reducer;
