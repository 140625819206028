import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { showAlert } from '../store/notificationSlice';
import { getMessageFromError } from '../utils/errorMessage';
import { logoff, updateToken } from '../store/authSlice';
import { io as connect, Socket } from 'socket.io-client';

type dispatcher = ThunkDispatch<RootState, null, AnyAction>;

export class Api {
    private static dispatch: dispatcher | undefined;
    private static token: string | null;

    public static setDispatcher = (dispatcher: dispatcher): void => {
        Api.dispatch = dispatcher;
    };

    public static setToken = (token: string): void => {
        Api.token = token;
    };

    public static get = <T>(url: string): Promise<T> =>
        axios.get<T>(Api.formatUrl(url), Api.options).then(Api.handleSuccess).catch(Api.handleError('Get'));

    public static post = <T>(url: string, obj?: any): Promise<T> =>
        axios.post<T>(Api.formatUrl(url), obj ?? {}, Api.options).then(Api.handleSuccess).catch(Api.handleError('Post'));

    public static put = <T>(url: string, obj: any): Promise<T> =>
        axios.put<T>(Api.formatUrl(url), obj, Api.options).then(Api.handleSuccess).catch(Api.handleError('Put'));

    public static patch = <T>(url: string, obj: any): Promise<T> =>
        axios.patch<T>(Api.formatUrl(url), obj, Api.options).then(Api.handleSuccess).catch(Api.handleError('Patch'));

    public static delete = <T>(url: string): Promise<T> =>
        axios.delete<T>(Api.formatUrl(url), Api.options).then(Api.handleSuccess).catch(Api.handleError('Delete'));

    public static createWebsocketConnection = (url: string, basePath: string): Socket => {
        const connection = connect(url, {
            path: `${basePath}/socket/`,
            reconnectionDelay: 0,
            forceNew: true,
            transports: ['websocket'],
            query: { authorization: Api.token! },
        });

        connection.on('connect', () => {
            console.log(`WS: Connection to ${url} established`);
        });

        connection.on('disconnect', () => {
            console.log(`WS: Connection to ${url} was closed`);
        });

        return connection;
    };

    private static handleSuccess = <T>(res: AxiosResponse<T>): T => {
        const newToken = res.headers['x-token'];

        if (!!newToken && !!Api.dispatch) Api.dispatch(updateToken(newToken));

        return apiResponseFormatter(res.data);
    };

    private static handleError = (type: string) => (err: any): any => {
        if (process.env.NODE_ENV !== 'production') {
            console.error(`API - Error during ${type}`);
            console.error(err.response?.data);
        }

        if (!Api.dispatch) throw new Error('Missing Dispatch, please use API.setDispatcher');

        if (err?.response?.status === 403) {
            Api.dispatch(logoff());
            return;
        }

        if (!!Api.token)
            Api.dispatch(showAlert(getMessageFromError(err.response?.data), 'error'));

        throw err.response?.data || err;
    };

    private static get options(): AxiosRequestConfig {
        return {
            headers: {
                Authorization: `Bearer ${Api.token}`,
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
            },
        };
    }

    private static formatUrl = (url: string): string => {
        const indexInterrogation = url.indexOf('?');
        if (indexInterrogation === -1)
            return url.replace(/\/$/, '') + '/';

        const path = url.substr(0, indexInterrogation).replace(/\/$/, '') + '/';
        return path + url.substr(indexInterrogation);
    };
}

export function apiResponseFormatter<T>(obj: T): T {
    if (!obj) return obj;

    if (Array.isArray(obj)) {
        return obj.map(i => apiResponseFormatter(i)) as any;
    }

    if (typeof obj === 'string' && isValidDateString(obj)) {
        return new Date(obj) as any;
    }

    if (typeof obj === 'object' && !(obj instanceof Date)) {
        return Object.keys(obj).reduce((acc, key) => {
            // @ts-ignore
            acc[key] = apiResponseFormatter(obj[key]);
            return acc;
        }, {}) as T;
    }

    return obj;
}

export const isValidDateString = (value: any): boolean =>
    /^(\d{4})-(\d{2})-(\d{2})([T\s](\d{2}):(\d{2}):(\d{2})(\.(\d+)(Z)?)?)?$/.test(value);
