import { Settings } from "../types/Settings";
import { Api } from "./Api";

const baseUrl = process.env.REACT_APP_SETTINGS_API!;

export default class SettingsService {
    public static getSettings(): Promise<Settings> {
        return Api.get<Settings>(baseUrl)
    }

    public static updateSettings(obj: Settings): Promise<Settings>{
        return Api.put<Settings>(baseUrl, obj)
    } 
}
