export enum RoleEnum {
    admin = 1,
    operator= 2
}

export interface BaseUser{
    id?: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    role?: RoleEnum
}
export interface User extends BaseUser{
    id: string;
}

export interface LoginRequest {
    email: string;
    password: string;
    twoFactorAuthCode?: number;
}

export interface LoginResponse {
    token: string;
    passwordMustBeChanged: boolean;
}

export const userIsEqual = (newObj: Partial<User>|null, oldObj: Partial<User>|null): boolean => {
    if (oldObj === null || newObj === null)
        return false;

    const isEqual = newObj.id === oldObj.id &&
        newObj.firstName === oldObj.firstName &&
        newObj.lastName === oldObj.lastName &&
        newObj.phoneNumber === oldObj.phoneNumber &&
        newObj.email === oldObj.email;

    if (!oldObj.role || !newObj.role)
        return isEqual;

    return isEqual && newObj.role === oldObj.role;
}
