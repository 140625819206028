import React, { useCallback, useEffect, useState } from "react";
import {
    closeClientAddress,
    getIsAddressOpened,
    getSelectedClient,
    updateClientInfo
} from "../../../../store/clientSlice";
import { Dialog } from "../../../utils/dialog";
import { Field } from "../../../utils/field";
import styles from "./client.address.module.scss";
import { Button } from "../../../utils/button";
import { Client } from "../../../../types/Client";
import { HasId } from "../../../../types";
import { useAppDispatch } from "../../../../hooks";
import { useSelector } from "react-redux";

export const ClientAddress = () => {
    const dispatch = useAppDispatch()
    const client = useSelector(getSelectedClient)!;
    const isOpen = useSelector(getIsAddressOpened);

    const [ street, setStreet ] = useState('');
    const [ number, setNumber ] = useState('');
    const [ neighborhood, setNeighborhood ] = useState('');
    const [ city, setCity ] = useState('');
    const [ symbolState, setSymbolState ] = useState('');
    const [ complement, setComplement ] = useState('');
    const [ postalCode, setPostalCode ] = useState('');

    const updateFromSlice = useCallback(() => {
        if (!client.address)
            return;

        setStreet(client.address?.street);
        setNumber(client.address.number);
        setNeighborhood(client.address.neighborhood);
        setCity(client.address.city);
        setSymbolState(client.address.state);

        if (!!client.address.complement)
            setComplement(client.address.complement)

    }, [ client ])

    useEffect(updateFromSlice, [ updateFromSlice ]);

    const clientForm: Partial<Client>&HasId = {
        id: client.id,
        address: {
            postalCode,
            street,
            city,
            state: symbolState,
            neighborhood,
            number,
            complement
        }
    }

    return (
        <Dialog
            isOpen={isOpen}
            onClose={() => dispatch(closeClientAddress())}
            title='Address Client'
            className={styles.dialogAddressClient}
        >
            <Field
                label='Postal Code'
                placeholder='78945663'
                value={postalCode}
                onChange={setPostalCode}
            />
            <Field
                label='Street'
                placeholder='Melo Viela'
                value={street}
                onChange={setStreet}
            />
            <Field
                label='Number'
                placeholder='2'
                value={number}
                onChange={setNumber}
            />
            <Field
                label='Neighborhood'
                placeholder='Buckinghamshire'
                value={neighborhood}
                onChange={setNeighborhood}
            />
            <Field
                label='City'
                placeholder='Município de Juliano do Descoberto'
                value={city}
                onChange={setCity}
            />
            <Field
                label='State'
                placeholder='US'
                value={symbolState}
                onChange={setSymbolState}
            />

            <Field
                label='Complement'
                placeholder=''
                value={complement}
            />

            <div className={styles.actions}>
                <Button label="Discard" appearance="outline" onClick={updateFromSlice}/>
                <Button label='Update' onClick={() => dispatch(updateClientInfo(clientForm))}/>
            </div>
        </Dialog>
    )
}