import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import ClientService from "../services/ClientService";
import {Client} from "../types/Client";
import {AppThunk, RootState} from "./index";
import {LoadingStateEnum, PaginationParams} from "../types";

interface SearchState {
    listClients: Client[],
    searchTerm: string | undefined;
    loadingState: LoadingStateEnum;
}

const initialState: SearchState = {
    listClients: [],
    searchTerm: undefined,
    loadingState: LoadingStateEnum.idle
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        updateSearchTerm: (state, action: PayloadAction<string | undefined>) => {
            state.searchTerm = action.payload;
        },
        updateLoading: (state, action: PayloadAction<LoadingStateEnum>) => {
            state.loadingState = action.payload;
        },
        updateListClients: (state, action: PayloadAction<Client[]>) => {
            state.listClients = action.payload;
        }
    },
});

const {updateLoading, updateListClients, updateSearchTerm} = searchSlice.actions;

export const searchClients = (params: PaginationParams): AppThunk => dispatch => {
    dispatch(updateSearchTerm(params.term));

    if(!params.term){
        dispatch(updateListClients([]))
        return;
    }

    dispatch(updateLoading(LoadingStateEnum.loading));

    ClientService.getClients(params)
        .then(({results}) => {
            dispatch(updateListClients(results));
            dispatch(updateLoading(LoadingStateEnum.idle));
        });
}
export const getSearchedClients = (state: RootState): Client[] => state.searchSlice.listClients;
export const getSearchIsLoading = (state: RootState): boolean => state.searchSlice.loadingState === LoadingStateEnum.loading;
export const getHasSearchTerm = (state: RootState): boolean => !!state.searchSlice.searchTerm;


export default searchSlice.reducer;
