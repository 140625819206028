import React, { FunctionComponent } from "react";
import styles from "./header.module.scss";

interface Props {
    title: string;
    children?: React.ReactNode;
}

export const HeaderApp: FunctionComponent<Props> = (props) => {
    return (
        <div className={styles.header}>
            <h1>{props.title}</h1>
            <div className={styles.actions}>
                {props.children}
            </div>
        </div>
    );
};
