import React, { useEffect, useState } from 'react';
import styles from './search-bar.module.scss';
import SearchIcon from '../../../assets/images/icons/search.svg';
import Refresh from '../../../assets/images/icons/refresh.svg';
import { IconButton } from '../../utils/icon-button';
import Person from '../../../assets/images/icons/person.svg';
import { getHasSearchTerm, getSearchedClients, getSearchIsLoading, searchClients } from '../../../store/searchSlice';
import { getFullName, phoneFormatter } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

export const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const lstClients = useSelector(getSearchedClients);
    const hasSearchTerm = useSelector(getHasSearchTerm);
    const isLoading = useSelector(getSearchIsLoading);

    useEffect(() => {
        const typingTimer = setTimeout(() => {
            dispatch(
                searchClients({
                    pageIndex: 0,
                    pageSize: 10,
                    term: searchTerm,
                })
            );
        }, 500);

        return () => {
            clearTimeout(typingTimer);
        };
    }, [searchTerm]);

    const clearTerm = () => {
        setSearchTerm('');
    };

    const openClient = (id: string) => {
        clearTerm();
        navigate(`/clients/${id}`);
    };

    const hasResultSearch = !!lstClients.length;
    const displayResult = hasResultSearch ? styles.hasResult : styles.noResult;

    return (
        <div className={styles.searchBar}>
            <img src={SearchIcon} alt="Search icon" />
            <input type="text" value={searchTerm} onChange={el => setSearchTerm(el.target.value)} />

            <div className={styles.icon}>
                {isLoading && <img className={styles.loading} src={Refresh} alt="" />}

                {!!searchTerm && !isLoading && (
                    <IconButton icon="cancel" size="normal" styleWeight="slim" onClick={clearTerm} />
                )}
            </div>

            {hasSearchTerm && (
                <div className={`${styles.componentSearch} ${displayResult}`}>
                    {!hasResultSearch && <span>No result found</span>}
                    {lstClients.map(x => (
                        <div className={styles.resultSearch}>
                            <img src={Person} alt="" />
                            <div className={styles.infoClient} onClick={() => openClient(x.id)}>
                                <div>{getFullName(x)}</div>
                                <div>
                                    {x.email} {!!x.phoneNumber ? `- ${phoneFormatter(x.phoneNumber)}` : ''}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
