import React, { useEffect } from "react";
import styles from './client-deposit-notice.module.scss';
import {
    clearLstNoticesByClient,
    fetchDepositNoticeByClient,
    getDepositNoticesByClient,
} from "../../../../store/depositSlice";
import { getSelectedClient } from "../../../../store/clientSlice";
import { DepositNoticeList } from "../../../deposit/deposit-notice-list";
import { useAppDispatch } from "../../../../hooks";
import { useSelector } from "react-redux";

export const ClientDepositHistory = () => {
    const lst = useSelector(getDepositNoticesByClient);
    const client = useSelector(getSelectedClient);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchDepositNoticeByClient(client!.id));

        return () => {
            dispatch(clearLstNoticesByClient());
        }
    }, [client])

    return (
        <DepositNoticeList data={lst!} showClient={false} className={styles.depositNotice}/>
    )
}