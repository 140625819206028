import { EthereumAddresses } from '../types/EthereumAddresses';
import { EthereumStandaloneTransferRequest, EthereumTransferResponse } from '../types/EthereumTransfer';
import { getUrlGenerator } from '../utils';
import { Api } from './Api';

const baseUrl = process.env.REACT_APP_ETHEREUM_GATEWAY_API;
const generateUrl = getUrlGenerator(baseUrl);

export default class EthereumGatewayService {
    public static listBalances = (): Promise<EthereumAddresses> =>
        Api.get(generateUrl('balances'));

    public static transfer = (req: EthereumStandaloneTransferRequest): Promise<EthereumTransferResponse> =>
        Api.post(generateUrl('transfer', 'standalone'), req);
}
