import { FunctionComponent } from "react";
import React from 'react';
import styles from './stats-item.module.scss';

interface Props {
    inverted?: boolean;
    label: string;
    percent: number;
}

export const StatsItem: FunctionComponent<Props> = props => {
    const { label, inverted } = props;
    const positive = props.percent >= 0;
    const percent = props.percent.toFixed(2);

    let style;
    if (inverted)
        style = positive ? styles.red : styles.green;
    else
        style = positive ? styles.green : styles.red;

    const percentage = positive ? `+${percent}` : percent;
    return (
        <div className={`${styles.statsDetail} ${style}`}>
            <span>{percentage}%</span>
            <span>{label}</span>
        </div>
    );
}
