import React, {FunctionComponent} from "react"
import {WithData} from "../../../types";
import styles from './breadcrumb.module.scss';
import { useNavigate } from "react-router-dom";

export interface BreadcrumbItem{
    label: string;
    link?: string;
}

export const Breadcrumb: FunctionComponent<WithData<BreadcrumbItem[]>> = ({data}) => {
    const navigate = useNavigate();

    const renderItem = (item: BreadcrumbItem, key: number) => {
        if(!item.link)
            return <span key={key}>{item.label}</span>

        return <span key={key} className={styles.hasLink} onClick={() => navigate(item.link!)}>{item.label}</span>
    }

    return <div className={styles.breadCrumb}> {data.map(renderItem)} </div>
}
