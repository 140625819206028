import React, { FunctionComponent } from 'react';
import { Withdraw, WithdrawStatusEnum } from '../../types/Withdraw';
import { Button } from '../utils/button';
import { openMarkAsCompleted, updateWithdrawStatus } from '../../store/withdrawSlice';
import { useAppDispatch } from "../../hooks";

interface Props {
    withdraw: Withdraw,
}

export const ActionWithdraw: FunctionComponent<Props> = props => {
    const dispatch = useAppDispatch();

    if (props.withdraw.status === WithdrawStatusEnum.done)
        return null;

    if (props.withdraw.status === WithdrawStatusEnum.pending){
        const action = () => dispatch(updateWithdrawStatus(props.withdraw, WithdrawStatusEnum.doing));
        return <Button
            label="Assign to Me"
            appearance={'outline'}
            onClick={action}/>;
    }

    const action = () => dispatch(openMarkAsCompleted(props.withdraw));
    return <Button label="Mark as Completed" onClick={action}/>;
}
