import { getUrlGenerator } from "../utils";
import { IdWallReport } from "../types/idWall";
import { Api } from "./Api";

const baseUrl = process.env.REACT_APP_ID_WALL_API!;
const url = getUrlGenerator(baseUrl);

export class IdWallService {
    public static getReport = (clientId: string): Promise<IdWallReport | null> =>
        Api.get(url('report', clientId))
}