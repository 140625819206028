import { createAsyncThunk, createSlice, isFulfilled, isPending, PayloadAction } from '@reduxjs/toolkit';
import { DepositService } from '../services/DepositService';
import { Balance, Client, ClientStatusEnum } from '../types/Client';
import { HasId, PaginationResponse } from '../types';
import ClientService from '../services/ClientService';
import { AppThunk, RootState } from './index';
import { showAlert } from './notificationSlice';
import { getFullName, updateOnList } from '../utils';
import AccountingService from "../services/AccountingService";
import { DepositWallet, WithdrawWallet } from '../types/Wallet';
import StorageService from '../services/StorageService';
import UserService from "../services/UserService";
import { IdWallReport } from "../types/idWall";
import { IdWallService } from "../services/IdWallService";
import { OverdraftLimit } from "../types/OverdraftLimit";

interface ClientState {
    lstClients: PaginationResponse<Client> | null;
    selectedClient: Client | null;
    selectedDocumentImages: string[] | null;
    selectedDepositWallets: DepositWallet | null;
    changeStatusIsOpened: boolean;
    purchaseFeeIsOpened: boolean;
    bookFeeIsOpened: boolean;
    walletFormIsOpened: boolean;
    loadingState: 'idle' | 'loading' | 'loaded';
    clientInfoIsOpened: boolean;
    clientAddressIsOpened: boolean;
    idWallReport: IdWallReport | null;
    overdraftLimit: OverdraftLimit | null;
}

const initialState: ClientState = {
    lstClients: null,
    selectedClient: null,
    selectedDocumentImages: null,
    selectedDepositWallets: null,
    changeStatusIsOpened: false,
    purchaseFeeIsOpened: false,
    bookFeeIsOpened: false,
    walletFormIsOpened: false,
    loadingState: 'idle',
    clientInfoIsOpened: false,
    clientAddressIsOpened: false,
    idWallReport: null,
    overdraftLimit: null
};

export const fetchClients = createAsyncThunk('clients/fetchClients', ClientService.getClients);
export const fetchClient = createAsyncThunk('clients/fetchClient', ClientService.getClient);

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        openChangeStatusClient: (state, action: PayloadAction<Client>) => {
            state.changeStatusIsOpened = true;
            state.selectedClient = action.payload;
        },
        closeChangeStatusClient: state => {
            state.changeStatusIsOpened = false;
        },
        openChangePurchaseFee: (state, action: PayloadAction<Client>) => {
            state.purchaseFeeIsOpened = true;
            state.selectedClient = action.payload;
        },
        closeChangePurchaseFee: state => {
            state.purchaseFeeIsOpened = false;
        },
        openChangeBookFee: (state, action: PayloadAction<Client>) => {
            state.bookFeeIsOpened = true;
            state.selectedClient = action.payload;
        },
        closeChangeBookFee: state => {
            state.bookFeeIsOpened = false;
        },
        openClientInfo: (state, action: PayloadAction<Client>) => {
            state.clientInfoIsOpened = true;
            state.selectedClient = action.payload;
        },
        closeClientInfo: state => {
            state.clientInfoIsOpened = false;
        },
        openClientAddress: state => {
            state.clientAddressIsOpened = true;
        },
        closeClientAddress: state => {
            state.clientAddressIsOpened = false;
        },
        updateSelectedClient: (state, action: PayloadAction<Client | Partial<Client>>) => {
            const updatedClient = { ...(state.selectedClient || {}), ...action.payload } as Client;

            state.selectedClient = updatedClient;

            if (!state.lstClients || !updatedClient) return;

            state.lstClients.results = updateOnList(state.lstClients.results, updatedClient);
        },
        unselectClient: state => {
            state.selectedClient = null;
            state.selectedDocumentImages = null;
        },
        updateImages: (state, action: PayloadAction<string[]>) => {
            state.selectedDocumentImages = action.payload;
        },
        updateAddWalletIsOpen: (state, action: PayloadAction<boolean>) => {
            state.walletFormIsOpened = action.payload;
        },
        closeAddWallet: state => {
            state.walletFormIsOpened = false;
        },
        updateBalanceSelectedClient: (state, action: PayloadAction<Balance>) => {
            if (!state.selectedClient)
                return;

            state.selectedClient.balance = action.payload;
        },
        updateDepositWalletSelectedClient: (state, action: PayloadAction<DepositWallet>) => {
          state.selectedDepositWallets = action.payload;
        },
        updateIdWallReport: (state, action: PayloadAction<IdWallReport | null>) => {
            state.idWallReport = action.payload;
        },
        updateOverdraftLimitSelectedClient: (state, action: PayloadAction<OverdraftLimit | null>) => {
            state.overdraftLimit = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchClients.fulfilled, (state, action) => {
            state.lstClients = action.payload;
        });
        builder.addCase(fetchClient.fulfilled, (state, action) => {
            state.selectedClient = action.payload;
        });
        builder.addMatcher(isPending(fetchClient, fetchClients), state => {
            state.loadingState = 'loading';
        });
        builder.addMatcher(isFulfilled(fetchClient, fetchClients), state => {
            state.loadingState = 'loaded';
        });
    },
});

export const {
    openChangeStatusClient,
    closeChangeStatusClient,
    openChangePurchaseFee,
    closeChangePurchaseFee,
    openChangeBookFee,
    closeChangeBookFee,
    updateSelectedClient,
    updateAddWalletIsOpen,
    closeAddWallet,
    unselectClient,
    updateBalanceSelectedClient,
    openClientInfo,
    closeClientInfo,
    updateImages,
    openClientAddress,
    closeClientAddress,
    updateIdWallReport,
    updateOverdraftLimitSelectedClient,
    updateDepositWalletSelectedClient
} = clientSlice.actions;

export const fetchDocumentImages = (): AppThunk => (dispatch, getState) => {
    const selectedClient = getState().clientSlice.selectedClient!;

    const imagePromises = [
        selectedClient.selfieId,
        selectedClient.document?.frontId,
        selectedClient.document?.backId,
        selectedClient.companyInfo?.document.imageId,
        selectedClient.companyInfo?.document.socialContractImageId,
    ]
        .filter(x => !!x)
        .map(x => StorageService.getFile(x!));

    Promise.all(imagePromises)
        .then(lst => dispatch(updateImages(lst)));
};

export const fetchIdWallReport = (): AppThunk => (dispatch, getState) => {
    const clientId = getState().clientSlice.selectedClient!.id;

    IdWallService.getReport(clientId)
        .then(report => {
            dispatch(updateIdWallReport(report));
        });
}

export const fetchOverdraftLimit = (): AppThunk => (dispatch, getState ) => {
    const clientId = getState().clientSlice.selectedClient!.id;

    AccountingService.getOverdraftLimit(clientId)
        .then(result => {
            dispatch(updateOverdraftLimitSelectedClient(result))
        })
}

export const fetchDepositWallets = (): AppThunk => (dispatch, getState) => {
    const clientId = getState().clientSlice.selectedClient!.id;

    DepositService.getDepositWallets(clientId)
        .then(result => {
            dispatch(updateDepositWalletSelectedClient(result))
        })
}

export const updateOverdraftLimit = (overdraftLimit: OverdraftLimit): AppThunk => (dispatch) => {
    AccountingService.updateOverdraftLimit(overdraftLimit)
        .then(result => {
            dispatch(updateOverdraftLimitSelectedClient(result));
            dispatch(showAlert('Limits updated'));
        })
}

export const updateStatusClient = (status: ClientStatusEnum): AppThunk => (dispatch, getState) => {
    const state = getState().clientSlice;
    const client = state.selectedClient!;

    const onSuccess =
        status === ClientStatusEnum.frozen
            ? () => dispatch(showAlert(`Client ${getFullName(client)} is frozen`))
            : () => dispatch(showAlert(`Client ${getFullName(client)} was reactivated`));

    dispatch(closeChangeStatusClient());
    ClientService.patchClient(client.id, { status }).then(() => {
        onSuccess();
        dispatch(updateSelectedClient({ status }));
    });
};

export const updateClientInfo = (client: Partial<Client> & HasId): AppThunk => dispatch => {
    ClientService.patchClient(client.id, client).then(x => {
        dispatch(showAlert('Client updated'));
        dispatch(updateSelectedClient(client));
    });
};

export const updatePurchaseFee = (purchaseFee: number): AppThunk => (dispatch, getState) => {
    const state = getState().clientSlice;
    const client = state.selectedClient!;

    dispatch(closeChangePurchaseFee());
    ClientService.patchClient(client.id, { purchaseFee }).then(x => {
        dispatch(showAlert(`The purchase fee for ${getFullName(client)} is ${purchaseFee}%`));
        dispatch(updateSelectedClient({ purchaseFee }));
    });
};

export const updateBookFee = (bookFee: number): AppThunk => (dispatch, getState) => {
    const { selectedClient } = getState().clientSlice;

    dispatch(closeChangeBookFee());
    ClientService.patchClient(selectedClient!.id, { bookFee }).then(x => {
        dispatch(showAlert(`The book fee for ${getFullName(selectedClient)} is ${bookFee}%`));
        dispatch(updateSelectedClient({ bookFee }));
    });
};

export const removeWallet = (wallet: WithdrawWallet): AppThunk => (dispatch, getState) => {
    const { selectedClient } = getState().clientSlice;
    if (!selectedClient)
        return;

    const { withdrawWallets } = selectedClient;

    const newWallets = withdrawWallets.filter(x => x !== wallet);

    ClientService.patchClient(selectedClient.id, { withdrawWallets: newWallets }).then(x => {
        dispatch(showAlert('Wallet removed'));
        dispatch(updateSelectedClient({ withdrawWallets: newWallets }));
    });
};

export const addWallet = (wallet: WithdrawWallet): AppThunk => (dispatch, getState) => {
    const { selectedClient } = getState().clientSlice;
    if (!selectedClient) return;

    const { withdrawWallets } = selectedClient;

    const newWallets = [...(withdrawWallets || []), wallet];
    dispatch(closeAddWallet());
    ClientService.patchClient(selectedClient.id, { withdrawWallets: newWallets }).then(x => {
        dispatch(showAlert('Wallet was added'));
        dispatch(updateSelectedClient({ withdrawWallets: newWallets }));
    });
};

export const updateBalance = (): AppThunk => (dispatch, getState) => {
    const { selectedClient } = getState().clientSlice;
    if (!selectedClient) return;

    AccountingService.getBalance(selectedClient.id)
        .then(x => {
            dispatch(updateBalanceSelectedClient(x));
        });
};

export const resetPassword = (): AppThunk => (dispatch, getState) => {
    const { selectedClient } = getState().clientSlice;
    if (!selectedClient) return;

    UserService.resetClientPassword(selectedClient.userId)
        .then(() => {
            dispatch(showAlert('Password was reset'))
        });
};

export const getLstClients = (state: RootState): Client[] | undefined => state.clientSlice.lstClients?.results;
export const getTotalClients = (state: RootState): number => state.clientSlice.lstClients?.total || 0;
export const getSelectedClient = (state: RootState): Client | null => state.clientSlice.selectedClient;
export const getIsChangeStatusOpened = (state: RootState): boolean => state.clientSlice.changeStatusIsOpened;
export const getIsChangePurchaseFeeOpened = (state: RootState): boolean => state.clientSlice.purchaseFeeIsOpened;
export const getIsChangeBookFeeOpened = (state: RootState): boolean => state.clientSlice.bookFeeIsOpened;
export const getIsInfoOpened = (state: RootState): boolean => state.clientSlice.clientInfoIsOpened;
export const getIsAddressOpened = (state: RootState): boolean => state.clientSlice.clientAddressIsOpened;
export const getWalletFormIsOpened = (state: RootState): boolean => state.clientSlice.walletFormIsOpened;
export const getDocumentImages = (state: RootState): string[] | null => state.clientSlice.selectedDocumentImages;
export const getIdWallReport = (state: RootState): IdWallReport | null => state.clientSlice.idWallReport;
export const getOverdraftLimit = (state: RootState): OverdraftLimit | null => state.clientSlice.overdraftLimit;
export const getDepositWallets = (state: RootState): DepositWallet | null => state.clientSlice.selectedDepositWallets;

export default clientSlice.reducer;
