export enum CoinEnum {
    brl = 1,
    usdt = 2,
    btc = 3,
    usdc = 4,
    eth = 5,
    brs = 6,
    usd = 7,
    trx = 8,
    bcase = 9,
}

export interface Coin {
    id: CoinEnum;
    label: string;
    symbol: string;
    placeholder: string;
    maxDecimalPlaces?: number;
}

