import React, { FunctionComponent } from 'react';
import { BasePagination } from '../../../types';
import { IconButton } from '../icon-button';
import styles from './paginator.module.scss';

interface Props {
    pagination: BasePagination;
    totals: number;
    setPage: (page: BasePagination) => void;
}

export const Paginator: FunctionComponent<Props> = (props) => {
    const start = props.pagination.pageIndex * props.pagination.pageSize + 1;
    const endPage = start + props.pagination.pageSize - 1;
    const end = Math.min(endPage, props.totals);

    const previousPage = () => props.setPage({
        pageSize: props.pagination.pageSize,
        pageIndex: props.pagination.pageIndex - 1
    });
    const nextPage = () => props.setPage({
        pageSize: props.pagination.pageSize,
        pageIndex: props.pagination.pageIndex + 1
    });

    const isLastPage = (props.pagination.pageIndex + 1) * props.pagination.pageSize >= props.totals;
    return (
        <div className={styles.paginator}>

            <span>{props.pagination.pageSize} items per page </span>
            <span>{start} - {end} of {props.totals} </span>

            <IconButton
                icon="arrow-left"
                onClick={previousPage}
                size="big"
                disabled={props.pagination.pageIndex === 0}/>
            <IconButton icon="arrow-right" onClick={nextPage} size="big" disabled={isLastPage}/>

        </div>
    );
};
