import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DepositNotice, DepositNoticeStatusEnum } from "../types/Deposit";
import { AppThunk, RootState } from "./index";
import { DepositService } from "../services/DepositService";
import StorageService from '../services/StorageService';
import { showAlert } from "./notificationSlice";
import { delay, updateOnList } from "../utils";
import { apiResponseFormatter } from "../services/Api";
import { Socket } from "socket.io-client";

interface DepositState {
    lstNoticesByClient: DepositNotice[]|null;
    lstPendingDepositNotice: DepositNotice [];
    selectedDepositNotice: DepositNotice|null;
    selectedDepositImage: string|null;
    selectedDepositAmount: number|null;
}

const initialState: DepositState = {
    lstNoticesByClient: null,
    selectedDepositNotice: null,
    selectedDepositImage: null,
    lstPendingDepositNotice: [],
    selectedDepositAmount: null
}

const depositSlice = createSlice({
    name: 'deposit',
    initialState,
    reducers: {
        openDepositNoticeDetail: (state, action: PayloadAction<DepositNotice>) => {
            state.selectedDepositNotice = action.payload;
            state.selectedDepositAmount = action.payload.amount.value;
        },
        closePopUp: (state) => {
            state.selectedDepositNotice = null;
            state.selectedDepositImage = null;
            state.selectedDepositAmount = null;
        },
        updateLstNoticesByClient: (state, action: PayloadAction<DepositNotice[]|null>) => {
            state.lstNoticesByClient = action.payload;
        },
        clearLstNoticesByClient: (state) => {
            state.lstNoticesByClient = null;
            state.selectedDepositNotice = null;
            state.selectedDepositImage = "";
        },
        updateImage: (state, action) => {
            state.selectedDepositImage = action.payload;
        },
        updatePendingDepositNotice: (state, action) => {
            state.lstPendingDepositNotice = action.payload;
        },
        addNewDepositNotice: (state, action: PayloadAction<DepositNotice>) => {
            state.lstPendingDepositNotice = [ ...state.lstPendingDepositNotice, action.payload ];

            const [ first ] = state.lstNoticesByClient || [];
            if (first?.client.id === action.payload.client.id)
                state.lstNoticesByClient = [ action.payload, ...state.lstNoticesByClient! ];
        },
        updateDepositNotice: (state, action: PayloadAction<DepositNotice>) => {
            if (!!state.lstNoticesByClient)
                state.lstNoticesByClient = updateOnList(state.lstNoticesByClient, action.payload);

            if (state.selectedDepositNotice?.id === action.payload.id)
                state.selectedDepositNotice = action.payload;

            const { status } = action.payload;

            if (status === DepositNoticeStatusEnum.Approved || status === DepositNoticeStatusEnum.Denied) {
                state.lstPendingDepositNotice = state.lstPendingDepositNotice.filter(x => x.id !== action.payload.id);
                return;
            }

            state.lstPendingDepositNotice = updateOnList(state.lstPendingDepositNotice, action.payload);
        },
        updateDepositNoticeAmount: (state, action: PayloadAction<number>) => {
            state.selectedDepositAmount = action.payload;
        }
    }
});

export const {
    addNewDepositNotice,
    openDepositNoticeDetail,
    closePopUp,
    clearLstNoticesByClient,
    updateImage,
    updateDepositNoticeAmount
} = depositSlice.actions;

const { updateLstNoticesByClient, updateDepositNotice, updatePendingDepositNotice } = depositSlice.actions;

export const fetchDepositNoticeByClient = (clientId: string): AppThunk => dispatch => {
    DepositService.getDepositNoticesByClientId(clientId)
        .then(lst => {
            const sortedList = lst.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
            dispatch(updateLstNoticesByClient(sortedList));
        });
};

export const fetchPendingDepositNotice = (): AppThunk => dispatch => {
    DepositService.getPendingDepositNotice()
        .then(lst => dispatch(updatePendingDepositNotice(lst)))
}

export const fetchReceiptImage = (idImage: string): AppThunk => dispatch => {
    StorageService.getFile(idImage)
        .then(x => {
            dispatch(updateImage(x));
        });
}

export const updateDepositNoticeStatus = (depositNotice: DepositNotice, status: DepositNoticeStatusEnum): AppThunk =>
    (dispatch, getState) => {
        const state = getState().depositSlice;
        const amount: number = state.selectedDepositAmount || depositNotice.amount.value;
        DepositService.updateStatus(depositNotice.id, status, amount)
            .then(x => {
                dispatch(showAlert('Withdraw updated'));
            });
    };

export const getDepositNoticesByClient = (state: RootState): DepositNotice[]|null => state.depositSlice.lstNoticesByClient;
export const getSelectedDepositNotice = (state: RootState): DepositNotice|null => state.depositSlice.selectedDepositNotice;
export const getDocumentImage = (state: RootState): string|null => state.depositSlice.selectedDepositImage;
export const getPendingDepositNotice = (state: RootState): DepositNotice[] => state.depositSlice.lstPendingDepositNotice || [];
export const getSelectedDepositNoticeAmount = (state: RootState): number|null => state.depositSlice.selectedDepositAmount;

let wsConnection: Socket;
export const subscribeSocketDepositNotice = (): AppThunk => async dispatch => {
    if (wsConnection?.connected) {
        dispatch(unsubscribeSocketDepositNotice());
        await delay(1000);
    }

    wsConnection = DepositService.connectWebsocket();
    wsConnection.on('deposit/addNewNotice', (depositNotice: DepositNotice) => {
        const noticeFormatted = apiResponseFormatter(depositNotice)
        dispatch(addNewDepositNotice(noticeFormatted));
    });

    wsConnection.on('deposit/updateNotice', (depositNotice: DepositNotice) => {
        const noticeFormatted = apiResponseFormatter(depositNotice);
        dispatch(updateDepositNotice(noticeFormatted));
    });

    wsConnection.on('connect', () => dispatch(fetchPendingDepositNotice()));
};

export const unsubscribeSocketDepositNotice = (): AppThunk => dispatch => {
    if (!wsConnection?.connected)
        return;

    wsConnection?.close();
}

export default depositSlice.reducer;
