import React, {CSSProperties, FunctionComponent, useEffect, useState} from "react";
import styles from './avatar.module.scss';
import { BasicUser } from "../../../types";

type sizeOptionsType = 'small' | 'normal' | 'big';

interface Props {
    user: BasicUser;
    onClick?: Function;
    size?: sizeOptionsType;
    primary?: boolean;
    className?: string;
}

const sizeOptions = new Map<sizeOptionsType, string>();
sizeOptions.set('small', styles.small);
sizeOptions.set('normal', styles.normal);
sizeOptions.set('big', styles.big);

const colorOptions = [
    '#cb1804',
    '#0f0220',
    '#920005',
    '#623a06'
]

export const Avatar: FunctionComponent<Props> = (props) => {
    const [color, setColor] = useState('');
    const initials = props.user.firstName[0] + props.user.lastName[0];

    useEffect(() => {
        const randomColor = colorOptions[Math.floor(Math.random() * colorOptions.length)];
        setColor(randomColor);
    }, []);

    const style = sizeOptions.get(props.size || 'normal')!;

    const colorStyle: CSSProperties = props.primary ? {} : {backgroundColor: color};
    return (
        <div style={colorStyle} className={`${styles.avatar} ${style} ${props.className}`} onClick={() => props.onClick && props.onClick()}>
            <div>{initials}</div>
        </div>
    )
}
