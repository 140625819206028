import React, { FunctionComponent, useEffect, useState } from 'react';
import { Withdraw } from '../../../types/Withdraw';
import { generateQrCode } from '../../../utils';

interface Props {
    withdraw: Withdraw;
}

export const PixQrCode: FunctionComponent<Props> = props => {
    const [base64, setBase64] = useState('');

    useEffect(() => {
        generateQrCode(props.withdraw).then(setBase64);
    }, [props.withdraw]);

    if(!base64)
        return null

    return <img src={base64} alt="" />;
};
