import React, { useEffect } from 'react';
import Add from '../../../../assets/images/icons/add.svg';
import { openConfirmation } from '../../../../store/appSlice';
import {
    fetchDepositWallets,
    getDepositWallets,
    getSelectedClient,
    removeWallet,
    updateAddWalletIsOpen,
} from '../../../../store/clientSlice';
import { CryptoWallet, WithdrawWallet } from '../../../../types/Wallet';
import { getWalletInfo } from '../../../../utils';
import { FloatingActionButton } from '../../../utils/floating-action-button';
import { IconButton } from '../../../utils/icon-button';
import { Label } from '../../../utils/label';
import { AddWallet } from '../add-wallet';
import styles from './client-wallets.module.scss';
import { useAppDispatch } from '../../../../hooks';
import { useSelector } from 'react-redux';

const DepositWallets = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchDepositWallets());
    }, []);

    const depositWallets = useSelector(getDepositWallets);
    if (!depositWallets)
        return null;

    const wallets: CryptoWallet[] = depositWallets.map(x => ({ key: x.address, coin: x.coin } as CryptoWallet));

    const renderWallet = (wallet: CryptoWallet, index: number) => {
        const walletInfo = getWalletInfo(wallet);
        return (
            <Label key={index} text={walletInfo.title}>
                {walletInfo.label}
            </Label>
        );
    };
    if (!wallets.length) return null;

    return (
        <>
            <h3>Deposit Wallets</h3>
            {wallets.map(renderWallet)}
        </>
    );
};

const WithdrawWallets = () => {
    const dispatch = useAppDispatch();
    const client = useSelector(getSelectedClient);

    if (!client)
        return null;

    const renderWalletItem = (item: WithdrawWallet, index: number) => {
        const walletInfo = getWalletInfo(item);

        const onDelete = () =>
            dispatch(
                openConfirmation({
                    title: `Remove ${walletInfo.title}`,
                    text: `Are you sure you want delete the ${walletInfo.title} ${walletInfo.label}`,
                    onConfirm: () => dispatch(removeWallet(item)),
                }),
            );
        return (
            <Label key={index} text={walletInfo.title}>
                {walletInfo.label}
                <IconButton icon='delete' onClick={onDelete} styleWeight='slim' />
            </Label>
        );
    };

    const hasWallet = client.withdrawWallets?.length;
    return (
        <>
            <h3>Withdraw wallets</h3>
            {!hasWallet && <div>No wallet found</div>}
            {hasWallet && client.withdrawWallets?.map(renderWalletItem)}
            <FloatingActionButton onClick={() => dispatch(updateAddWalletIsOpen(true))} icon={Add} />
            <AddWallet />
        </>
    );
};

export const ClientWallets = () => (
    <div className={styles.clientWallets}>
        <DepositWallets />
        <WithdrawWallets />
    </div>
);
