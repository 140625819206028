import React from 'react';
import { Dialog } from '../../utils/dialog';
import { closePopups, getDetailIsOpen, getSelectedWithdraw } from '../../../store/withdrawSlice';
import { Label } from '../../utils/label';
import { labelWithdrawStatus, getWalletInfo, formatAmount, formatValueAmount, phoneFormatter } from '../../../utils';
import styles from './withdraw-detail.module.scss';
import { CopyButton } from '../../utils/copy-button';
import { PixQrCode } from '../../utils/pix-qrcode';
import { ActionWithdraw } from '../ActionWithdraw';
import { WithdrawHistory } from '../withdraw-history';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

export const WithdrawDetail = () => {
    const selectedWithdraw = useSelector(getSelectedWithdraw);
    const isOpen = useSelector(getDetailIsOpen);

    const dispatch = useAppDispatch();
    const handleClose = () => dispatch(closePopups());

    if (!selectedWithdraw) return null;

    const walletInfo = getWalletInfo(selectedWithdraw.wallet);

    return (
        <Dialog className={styles.withdrawDetail} title="Withdraw" isOpen={isOpen} onClose={handleClose}>
            <div className={styles.info}>
                <Label
                    text="Client"
                    content={`${selectedWithdraw.client.firstName} ${selectedWithdraw.client.lastName}`}
                />
                <Label text='Phone' content={phoneFormatter(selectedWithdraw.client.phoneNumber)}/>
                <Label text="Amount">
                    {formatAmount(selectedWithdraw.amount)}
                    <CopyButton styleWeight="slim" value={formatValueAmount(selectedWithdraw.amount)} label="Amount" />
                </Label>
                <Label
                    text="Status"
                    content={labelWithdrawStatus(selectedWithdraw)}
                />
                <Label text="Fee" content={formatAmount(selectedWithdraw.fee)} />
                <Label text="Key | Wallet">
                    {walletInfo.label}
                    <CopyButton styleWeight="slim" label={walletInfo.title} value={walletInfo.value} />
                </Label>
            </div>

            <div className={styles.qrCode}>
                <PixQrCode withdraw={selectedWithdraw} />
            </div>

            <WithdrawHistory withdraw={selectedWithdraw}  />
            <ActionWithdraw withdraw={selectedWithdraw} />
        </Dialog>
    );
};
