import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SettingsService from "../services/SettingsService";
import { Settings } from "../types/Settings";
import { AppThunk, RootState } from "./index";
import { showAlert } from "./notificationSlice";

interface SettingsState {
    settingsData: Settings | null;
}

const initialState: SettingsState = {
    settingsData: null
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateSettings: (state, action: PayloadAction<Settings>) => {
            state.settingsData = action.payload;
        }
    }
});

const { updateSettings } = settingsSlice.actions;

export const fetchSetting = (): AppThunk => dispatch => {
    SettingsService.getSettings().then(x => dispatch(updateSettings(x)));
}

export const putSettings = (obj: Settings): AppThunk => dispatch => {
    SettingsService.updateSettings(obj)
        .then(x => {
            dispatch(updateSettings(x));
            dispatch(showAlert('Settings save'));
        });
}

export const getSettings = (state: RootState): Settings | null => state.settingsSlice.settingsData;

export default settingsSlice.reducer;



