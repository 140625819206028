import Decimal from 'decimal.js';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hooks';
import {
    closeEthereumTransferPopUp,
    findEthereumWalletName,
    getEthereumAddressClients,
    getEthereumTransactionId,
    getEthereumTransferIsLoading,
    getSelectedEthereumAddress,
    makeEthereumTransfer,
} from '../../../store/ethereumWalletsSlice';
import { CoinEnum } from '../../../types/Coin';
import { EthereumStandaloneTransferRequest } from '../../../types/EthereumTransfer';
import { formatAmount } from '../../../utils';
import { coinOptions } from '../../../utils/constants';
import { Dialog } from '../../utils/dialog';
import { Field } from '../../utils/field';
import { IconButton } from '../../utils/icon-button';
import { SelectField } from '../../utils/select-field';
import { SearchEthereumAddress } from '../search-ethereum-address';
import styles from './transfer-ethereum.module.scss';

const allowedCoins = [CoinEnum.eth, CoinEnum.usdc, CoinEnum.usdt];
const coinEnumOptions = coinOptions.filter(x => allowedCoins.includes(x.id)).map(x => ({
    label: x.label,
    value: x.id,
}));

export const TransferEthereum = () => {
    const dispatch = useAppDispatch();

    const selectedAddress = useSelector(getSelectedEthereumAddress);
    const transferId = useSelector(getEthereumTransactionId);

    const isOpen = !!selectedAddress && !transferId;

    const [coin, setCoin] = useState<CoinEnum>(CoinEnum.eth);
    const [target, setTarget] = useState<string>();
    const [amount, setAmount] = useState<number>();
    const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

    const clients = useSelector(getEthereumAddressClients);
    const name = findEthereumWalletName(selectedAddress, clients);

    useEffect(() => {
        setCoin(CoinEnum.eth);
        setTarget(undefined);
        setAmount(undefined);
        setSearchIsOpen(false);
    }, [isOpen]);

    const chosenCoinBalance = selectedAddress?.balance.find(x => x.coin === coin)?.value || 0;
    const afterBalanceValue = new Decimal(chosenCoinBalance).minus(amount || 0).toNumber();

    const transfer = () => {
        const transferRequest: EthereumStandaloneTransferRequest = {
            source: selectedAddress!.address,
            target: target!,
            amount: { coin: coin!, value: amount! },
        };

        dispatch(makeEthereumTransfer(transferRequest));
    };

    const isLoading = useSelector(getEthereumTransferIsLoading);
    const actionDisabled: boolean =
        !selectedAddress ||
        !target ||
        !coin ||
        !amount ||
        afterBalanceValue < 0 ||
        selectedAddress.address === target;

    const fillWithBalance = useCallback(() => {
        setAmount(chosenCoinBalance);
    }, [chosenCoinBalance]);

    const closeSearch = useCallback(() => setSearchIsOpen(false), []);

    if (searchIsOpen)
        return (
            <SearchEthereumAddress
                isOpen={isOpen}
                onCancel={closeSearch}
                sourceAddress={selectedAddress?.address}
                onChosenAddress={(address) => {
                    closeSearch();
                    setTarget(address);
                }}/>
        );

    return (
        <Dialog
            isOpen={isOpen}
            className={styles.transferEthereum}
            title="Transfer"
            subtitle={`Source: ${name}`}
            onClose={() => dispatch(closeEthereumTransferPopUp())}
            action="Send"
            actionDisabled={actionDisabled}
            onAction={transfer}
            loading={isLoading}
        >
            <div className={styles.form}>
                <Field
                    className={styles.field}
                    label="Target"
                    placeholder="0x"
                    value={target}
                    required={true}
                    onChange={setTarget}
                    type="text"
                    suffix={<IconButton icon="search" onClick={() => setSearchIsOpen(true)}/>}
                />
                <SelectField
                    options={coinEnumOptions}
                    onChange={setCoin}
                    value={coin}
                    label="Coin"
                />
                <Field
                    className={styles.field}
                    label="Amount"
                    placeholder="0.1"
                    value={amount}
                    required={true}
                    onChange={setAmount}
                    type="number"
                    suffix={<IconButton icon="wallet" onClick={fillWithBalance}/>}
                />
                <div>
                    <p>Balance after</p>
                    <p>{formatAmount({ coin: coin, value: afterBalanceValue })}</p>
                </div>
            </div>
        </Dialog>
    );
};
