import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import style from './field.module.scss';
import { isEmailValid } from '../../../../src/utils/';
import { Decimal } from 'decimal.js';

interface BaseProps {
    placeholder: string;
    minLength?: number;
    maxLength?: number;
    disabled?: boolean;
    suffix?: string | JSX.Element;
    prefix?: string;
    value: number | string | undefined | Date;
    required?: boolean;
    maxDecimalPlaces?: number;
    min?: number;
    max?: number;
    label?: string;
    className?: string;
}

interface PropsString extends BaseProps {
    type?: 'text' | 'password' | 'email' | 'textArea' | 'phoneNumber';
    onChange?: (val: string) => void;
}

interface PropsNumber extends BaseProps {
    type: 'number';
    onChange?: (val: number) => void;
}

interface PropsToggle extends BaseProps {
    type: 'boolean';
    onChange?: (val: boolean) => void;
}

interface PropsDate extends BaseProps {
    type: 'date',
    onChange?: (val: Date) => void;
}

type Props = PropsString | PropsNumber | PropsToggle | PropsDate;

interface Validation {
    isValid: boolean;
    error: string;
}

const getDateVal = (value: string | number | boolean | Date): string => {
    if (typeof value === 'object') {
        const pad = (n: number, size = 2) => n.toString().padStart(size, '0');
        return `${pad(value.getFullYear(), 4)}-${pad(value.getMonth() + 1)}-${pad(value.getDate())}`;
    }

    return value as string;
};

export const Field: FunctionComponent<Props> = props => {
    const [blurred, setBlurred] = useState(false);
    const [val, setVal] = useState('');

    const getVal = useCallback((value: string | number | boolean | Date): string | number | boolean | Date => {
        if (!value)
            return '';

        if (props.type === 'number')
            return new Decimal(value as string).toNumber();

        if (props.type === 'date') {
            return getDateVal(value);
        }

        return value;
    }, [props.type]);

    useEffect(() => {
        if (props.type === 'date') {
            if (!props.value)
                return;

            const newVal = getDateVal(props.value);
            if (newVal === val)
                return;

            setVal(newVal);
            return;
        }

        const newVal = props.value?.toString() || '';
        if (getVal(newVal) === getVal(val) || !newVal)
            return;

        setVal(newVal);
    }, [props.value, props.type, getVal, val]);

    const isValid = useCallback((value = val): Validation => {
        let isValid = true;
        let error = '';

        if (props.type === 'email') {
            isValid = isEmailValid(value);
            error = 'Not a valid email';
        }

        if (props.type === 'date') {
            isValid = new Date(value).toString() !== 'Invalid Date';
            error = 'Not a valid date';
        }

        if (props.minLength !== undefined && props.minLength > value.length) {
            isValid = false;
            error = `Must have at least ${props.minLength} digits`;
        }

        if (props.min !== undefined && props.min > Number(value) && props.type === 'number') {
            isValid = false;
            error = `Number must be higher than ${props.minLength}`;
        }

        if (props.maxLength !== undefined && props.maxLength > value.length) {
            isValid = false;
            error = `Must have at most ${props.maxLength} digits`;
        }

        if (props.max !== undefined && props.max > Number(value) && props.type === 'number') {
            isValid = false;
            error = `Number must be less than ${props.maxLength}`;
        }

        if (props.required && !value) {
            isValid = false;
            error = 'Field must be full filled';
        }

        return { isValid, error };
    }, [props.type, props.minLength, props.min, props.max, props.maxLength, props.required, val]);

    const hasInvalidCharacter = (value: string): boolean => {
        if (props.type === 'date') {
            const onlyNumbersAndSlash = value.replace(/[a-zA-Z]/ig, '');
            return onlyNumbersAndSlash.length !== value.length;
        }

        if (props.type !== 'number')
            return false;

        const asNumber = new Decimal(value).toNumber();

        if (isNaN(asNumber))
            return true;

        if (!props.maxDecimalPlaces)
            return false;

        const getValWithCorrectDecimalPlaces = (): number =>
            new Decimal(asNumber).toDecimalPlaces(props.maxDecimalPlaces).toNumber();

        return asNumber !== getValWithCorrectDecimalPlaces();
    };

    const propagateNewValue = (value: string) => {
        if (!props.onChange)
            return;

        if (!isValid(value).isValid) {
            props.onChange(undefined as never);
            return;
        }

        if (props.type === 'date') {
            props.onChange(new Date(getVal(value) as string));
            return;
        }

        const valToPropagate = getVal(value) as never;
        props.onChange(valToPropagate);
    };

    const handleChange = (el: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        let { value } = el.target;

        if (props.type === 'number') {
            value = value.replace(/,/gi, '.');
        }

        if (hasInvalidCharacter(value))
            return;

        setVal(value);
        propagateNewValue(value);
    };

    const validation = isValid();
    const hasError = blurred && !validation.isValid;

    const handleBlur = () => {
        setBlurred(true);
    };

    const styleError = hasError ? style.hasError : '';
    const disableStyle = props.disabled ? style.disabled : '';

    const inputType = props.type === 'number' ? 'text' : props.type;

    const renderInput = () => {
        if (props.type === 'textArea')
            return <textarea
                className={style.color}
                onBlur={() => handleBlur()}
                onChange={handleChange}
                placeholder={props.placeholder}
                disabled={props.disabled}
                value={val}
            />;

        return (
            <input
                className={style.color}
                onBlur={() => handleBlur()}
                onChange={handleChange}
                type={inputType || 'text'}
                placeholder={props.placeholder}
                disabled={props.disabled}
                value={val}
            />
        );
    };
    return (
        <div>
            <div className={`${style.field} ${styleError} ${props.className} ${disableStyle}`}>
                {!!props.label && <span className={style.label}>{props.label}</span>}
                <div className={style.input}>
                    {props.prefix && (
                        <div className={style.prefix}>{props.prefix}</div>
                    )}

                    {renderInput()}

                    {props.suffix && (
                        <div className={style.suffix}>{props.suffix}</div>
                    )}
                </div>
            </div>
            {hasError && <span className={style.error}>{validation.error}</span>}
        </div>
    );
};
