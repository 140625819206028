import React, { FunctionComponent } from 'react';
import styles from './form-field-with-hint.module.scss';

interface Props {
    label: string;
    hint: string;
    children: React.ReactNode;
}

export const FormFieldWithHint: FunctionComponent<Props> = props => {
    return (
        <div className={styles.formFieldWithHint}>
            <div className={styles.label}>
                <span>{props.label}</span>
                <p>{props.hint}</p>
            </div>
            {props.children}
        </div>
    );
};
