import { Amount } from "./Amount";
import { BasicClient } from "./index";

export enum Supplier {
    grootbit = 8,
    binance = 19,
    escada = 97,
    awesome = 108,
    travelex = 250,
    okcoin = 725
}

export interface ExecutedOnSupplier {
    supplier: Supplier;
    orderId: string;
    source: Amount;
    target: Amount;
}

export interface ExchangeCommission {
    clientId: string;
    referralCode: string;
    percentage: number;
    amount: Amount;
}

export interface Exchange {
    id?: string;
    client: BasicClient;
    sourceAmount: Amount;
    targetAmount: Amount;
    executedOnSuppliers: ExecutedOnSupplier[];
    fee: Amount;
    commission: ExchangeCommission;
    createdAt: Date;
}
