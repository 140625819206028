import { getUrlGenerator } from '../utils';
import { Api } from './Api';
import { EthereumStandaloneTransferRequest, EthereumTransferResponse } from '../types/EthereumTransfer';
import { TronAddress, TronBalance } from '../types/TronAddress';

const baseUrl = process.env.REACT_APP_TRON_GATEWAY_API;
const generateUrl = getUrlGenerator(baseUrl);

export default class TronGatewayService {
    public static listWallets = (): Promise<TronAddress[]> =>
        Api.get(generateUrl('wallet', 'list'));

    public static transfer = (req: EthereumStandaloneTransferRequest): Promise<EthereumTransferResponse> =>
        Api.post(generateUrl('transfer'), req);

    public static getBalance = (key: string): Promise<TronBalance> =>
        Api.get(generateUrl('wallet', 'balance', key));
}
