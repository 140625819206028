import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfirmationParams } from "../types";
import { RootState } from "./index";

interface AppState {
    confirm: ConfirmationParams | null;
    menuIsOpened: boolean;
    profileIsOpened: boolean;
}

const initialState: AppState = {
    confirm: null,
    menuIsOpened: false,
    profileIsOpened: false
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        openConfirmation: (state, action: PayloadAction<ConfirmationParams>) => {
            state.confirm = action.payload;
        },
        closeConfirmation: state => {
            state.confirm = null;
        },
        toggleMenuIsOpen: state => {
            state.menuIsOpened = !state.menuIsOpened;
        },
        toggleProfileOpen: state => {
            state.profileIsOpened = !state.profileIsOpened;
        }
    },
});

export const { openConfirmation, closeConfirmation, toggleMenuIsOpen, toggleProfileOpen } = appSlice.actions;

export const getConfirmationIsOpen = (state: RootState): ConfirmationParams | null => state.appSlice.confirm;
export const getMenuIsOpen = (state: RootState): boolean => state.appSlice.menuIsOpened;
export const getProfileIsOpen = (state: RootState): boolean => state.appSlice.profileIsOpened;

export default appSlice.reducer;

