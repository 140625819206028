import { getUrlGenerator } from "../utils";
import { OrderExchange, } from "../types/Order";
import { Api } from "./Api";

const basUrl = process.env.REACT_APP_BOOK_API!;

const url = getUrlGenerator(basUrl);

export class BookService {
    public static getById = (id: string, clientId: string): Promise<OrderExchange> =>
        Api.get(url('transaction', 'admin', id, {clientId}));
}
