import React, { FunctionComponent } from "react";
import { DepositNotice, DepositNoticeHistory as ModelDepositHistory } from "../../../types/Deposit";
import { formatPrice, labelDepositNoticeStatus } from "../../../utils";

interface PropsHistory {
    history: ModelDepositHistory;
    notice: DepositNotice;
}

const HistoryItem: FunctionComponent<PropsHistory> = ({history,notice}) => (
    <tr>
        <td data-title='Amount'>{formatPrice(notice.amount.coin, history.amount)}</td>
        <td data-title='Status' >{labelDepositNoticeStatus(history)}</td>
        <td data-title='Date'>{history.date.toLocaleString()}</td>
    </tr>
);

interface Props {
    depositNotice: DepositNotice
}

export const DepositNoticeHistory: FunctionComponent<Props> = ({depositNotice}) => {
    return <table>
        <thead>
        <tr>
            <th>Value</th>
            <th>Status</th>
            <th>Date</th>
        </tr>
        </thead>
        <tbody>
        {depositNotice.history.map((x, key) => (
            <HistoryItem history={x} notice={depositNotice} key={key}/>
        ))}
        </tbody>
    </table>
}
