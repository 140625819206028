import React, { Dispatch, SetStateAction, useState } from 'react';
import styles from './login.module.scss';
import Logo from '../../../assets/images/logo.png';
import { Field } from '../../utils/field';
import { Button } from '../../utils/button';
import { LoginRequest } from '../../../types/User';
import {
    create2FA,
    getErrorAuth,
    getLoginIsStarted,
    login,
    updateError,
} from '../../../store/authSlice';
import { getErrorMessage } from '../../../utils/errorMessage';
import useKeyPress from '../../../hooks/useKeyPress';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

type HandleChange = <T>(callback: Dispatch<SetStateAction<T>>) => (value: T) => void;

export function Login() {
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [twoFA, setTwoFA] = useState<number | undefined>();

    const loginStarted = useSelector(getLoginIsStarted);
    const errorLogin = useSelector(getErrorAuth);
    const isDisabled = !email || !password || (!twoFA && loginStarted);

    const action = loginStarted ? login : create2FA;
    const payload: LoginRequest = {
        email,
        password,
        twoFactorAuthCode: twoFA,
    };

    useKeyPress('Enter', () => {
        if (isDisabled)
            return;

        dispatch(action(payload));
    }, [email, password, twoFA]);

    const handleChange: HandleChange = callback => value => {
        dispatch(updateError(''));

        callback(value);
    };

    return (
        <div className={styles.login}>
            <div className={styles.logo}>
                <img src={Logo} alt="logo"/>
            </div>

            <Field
                placeholder="Email"
                type="email"
                value={email}
                onChange={handleChange(setEmail)}
                disabled={loginStarted}
            />
            <Field
                placeholder="Password"
                type="password"
                value={password}
                required={true}
                onChange={handleChange(setPassword)}
                disabled={loginStarted}
            />

            {loginStarted && (
                <Field
                    placeholder="Verification Code"
                    type="number"
                    value={twoFA}
                    onChange={handleChange(setTwoFA)}
                    minLength={6}
                />
            )}

            <Button
                label="Sign In"
                onClick={() => dispatch(action(payload))}
                disabled={isDisabled}
                className={styles.signInButton}
            />
            {loginStarted && (
                <Button
                    label="Did not get a verification code?"
                    onClick={() => dispatch(create2FA(payload))}
                    appearance={"text"}
                />
            )}
            {!!errorLogin && (
                <div className={styles.alert}>{getErrorMessage(errorLogin)}</div>
            )}
        </div>
    );
}
