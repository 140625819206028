import styles from './alerts.module.scss';
import React, { FunctionComponent } from 'react';
import { closeAlert, getAlerts } from '../../../store/notificationSlice';
import { Alert, typeAlert, WithData } from "../../../types";
import Close from '../../../assets/images/icons/close.svg';
import Check from '../../../assets/images/icons/done.svg';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

interface AlertOption {
    type: typeAlert;
    style: string;
    label: string;
    icon: any
}

const alertOptions: AlertOption[] = [
    { type: 'success', style: styles.success, label: 'Success', icon: Check },
    { type: 'error', style: styles.error, label: 'Error', icon: Close }
];

const getAlertOption = (type: typeAlert): AlertOption => alertOptions.find(x => x.type === type)!;

const AlertItem: FunctionComponent<WithData<Alert>> = ({ data }) => {
    const { style, label, icon } = getAlertOption(data.type);
    const dispatch = useAppDispatch();
    const showStyle = data.show ? styles.show : styles.hide;

    return (
        <div className={`${styles.alertItem} ${style} ${showStyle}`}>
            <div className={styles.icon}>
                <img src={icon} alt={`${label} icon`}/>
            </div>
            <div className={styles.info}>
                <span>{label}</span>
                <span>{data.text}</span>
            </div>
            <img className={styles.close} src={Close} alt="close icon" onClick={() => dispatch(closeAlert(data))}/>
        </div>
    )
}

export const Alerts = () => {
    const lst = useSelector(getAlerts);

    return <div className={styles.alerts}>
        {lst.map((x, i) => <AlertItem data={x} key={i}/>)}
    </div>
}
