import { FunctionComponent } from "react";
import { HasActiveProp } from "../../clients/client/client-details/clientDetails";
import { getSelectedClient } from "../../../store/clientSlice";
import { getDepositNoticesByClient } from "../../../store/depositSlice";
import React from "react";
import { IconButton } from "../../utils/icon-button";
import { downloadReportDepositNotice } from "../../../store/reportSlice";
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

export const DownloadDepositNotice: FunctionComponent<HasActiveProp> = ({ active }) => {
    const dispatch = useAppDispatch();
    const client = useSelector(getSelectedClient);
    const lstDepositNotice = useSelector(getDepositNoticesByClient);

    if (!active || !lstDepositNotice?.length)
        return <div style={{ width: '32px' }}>&nbsp;</div>;

    return (
        <IconButton
            styleWeight='slim'
            icon='download'
            onClick={() => dispatch(downloadReportDepositNotice(client!))}/>
    );
}
