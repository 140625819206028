import React, { FunctionComponent, useCallback, useEffect } from 'react';
import {
    clearEthereumBalances, fetchEstimateFee,
    fetchEthereumBalances,
    findEthereumWalletName,
    getEthereumAddressClients,
    getEthereumAddresses, getEthereumEstimatedFee,
    openEthereumTransferPopUp,
} from '../../store/ethereumWalletsSlice';
import { WithData } from '../../types';
import { CoinEnum } from '../../types/Coin';
import { EthereumAddress } from '../../types/EthereumAddresses';
import { formatAmount } from '../../utils';
import { Card } from '../utils/card';
import { CopyButton } from '../utils/copy-button';
import { HeaderApp } from '../utils/header';
import { IconButton } from '../utils/icon-button';
import styles from './ethereum-wallets.module.scss';
import { SuccessTransferEthereum } from './success-transfer-ethereum';
import { TransferEthereum } from './transfer-ethereum';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import { Label } from '../utils/label';

const EthereumWalletItem: FunctionComponent<WithData<EthereumAddress>> = ({ data }) => {
    const dispatch = useAppDispatch();

    const { clientId, address, balance } = data;

    const ethBalance = balance.find(x => x.coin === CoinEnum.eth)!;
    const usdcBalance = balance.find(x => x.coin === CoinEnum.usdc)!;
    const usdtBalance = balance.find(x => x.coin === CoinEnum.usdt)!;

    const clients = useSelector(getEthereumAddressClients);
    const name = findEthereumWalletName(data, clients);

    return (
        <tr className={!!clientId ? '' : styles.main}>
            <td data-title="Client">{name}</td>
            <td data-title="Address">
                <div className={styles.address}>
                    <span>{address}</span>
                    <CopyButton label="Ethereum address" value={address} />
                </div>
            </td>
            <td data-title="ETH">
                {formatAmount(ethBalance)}
            </td>
            <td data-title="USDC">
                {formatAmount(usdcBalance)}
            </td>
            <td data-title="USDT">
                {formatAmount(usdtBalance)}
            </td>
            <td>
                <IconButton icon={'share'} onClick={() => dispatch(openEthereumTransferPopUp(data))} />
            </td>
        </tr>
    );
};

export const EthereumWallets = () => {
        const lst = useSelector(getEthereumAddresses);

        const dispatch = useAppDispatch();
        const estimatedFee = useSelector(getEthereumEstimatedFee);

        const reEstimateFee = useCallback(() => {
            dispatch(fetchEstimateFee());
        }, [dispatch]);

        useEffect(() => {
            reEstimateFee();
            dispatch(fetchEthereumBalances());

            return () => {
                clearEthereumBalances();
            };
        }, [dispatch, reEstimateFee]);

        if (!lst)
            return (<div>loading...</div>);

        return (
            <>
                <Card className={styles.ethereumWallets}>
                    <HeaderApp title="Ethereum Wallets">
                        <Label text="Estimated Fee">{formatAmount(estimatedFee)}</Label>
                        <IconButton icon="replay" onClick={reEstimateFee} />
                    </HeaderApp>
                    <div className={styles.tableWallets}>
                        <table>
                            <thead>
                            <tr>
                                <th>Client</th>
                                <th>Address</th>
                                <th>ETH</th>
                                <th>USDC</th>
                                <th>USDT</th>
                            </tr>
                            </thead>
                            <tbody>
                            {lst.map((x, key) => (
                                <EthereumWalletItem data={x} key={key} />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </Card>
                <TransferEthereum />
                <SuccessTransferEthereum />
            </>
        );
    }
;
