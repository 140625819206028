import { Amount } from './Amount';

export enum DeltaTypeEnum {
    yesterday = 1,
    lastWeek = 2,
}

export interface DeltaStats {
    percentage: number;
    type: DeltaTypeEnum;
}

export type AmountWithStats = Amount & { stats: DeltaStats[] };

export interface Summary {
    totalBalances: Amount[];
    withdraw: AmountWithStats[];
    deposits: AmountWithStats[];
}
