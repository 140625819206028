import React, { useEffect, useState } from 'react';
import styles from './renew-token-helper.module.scss'
import { Button } from "../../utils/button";
import { getTokenExpiresAt, logoff, renewToken } from "../../../store/authSlice";
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

export const RenewTokenHelper = () => {
    const dispatch = useAppDispatch();
    const [expiresInSeconds, setExpiresInSeconds] = useState(100000);

    const tokenExpiresAt = useSelector(getTokenExpiresAt);

    useEffect(() => {
        if (!tokenExpiresAt)
            return;

        const action = () =>
            setExpiresInSeconds(Math.floor((tokenExpiresAt - Date.now()) / 1000));

        const timer = setInterval(action, 250);

        return () => clearTimeout(timer);
    }, [tokenExpiresAt]);

    if (tokenExpiresAt === null || expiresInSeconds > 60)
        return null;

    if (expiresInSeconds < 0)
        dispatch(logoff());

    return (
        <div className={styles.renewTokenHelper}>
            <span className={styles.label}>Are you there?</span>
            <div className={styles.action}>
                <span className={styles.expireInTime}>{expiresInSeconds}</span>
                <Button
                    label='Sim' onClick={() => {
                    dispatch(renewToken())
                }}/>
            </div>
            <div className={styles.progressBar} style={{ width: `${100 * expiresInSeconds / 60}%` }}/>
        </div>)
};