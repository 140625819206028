import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hooks';
import {
    closeTransferPopUp,
    getTransactionId,
    getSelectedAddress,
} from '../../../store/tronWalletsSlice';
import { Button } from '../../utils/button';
import { Dialog } from '../../utils/dialog';
import styles from './success-transfer-tron.module.scss';

export const SuccessTransferTron = () => {
    const dispatch = useAppDispatch();

    const selectedBalance = useSelector(getSelectedAddress);
    const transferId = useSelector(getTransactionId);

    const isOpen = !!selectedBalance && !!transferId;

    const closePopUp = useCallback(() => dispatch(closeTransferPopUp()), []);
    const getLink = (text: string) => <a rel="noreferrer" href={`https://tronscan.org/#/transaction/${transferId}`} target="_blank">{text}</a>;

    return (
        <Dialog
            isOpen={isOpen}
            title="Transfer Created Successfully"
            onClose={closePopUp}
            className={styles.successTransfer}
        >
            <div className={styles.successMessage}>
                Your transfer has been created. {transferId} its its transaction ID.<br/><br/>
                Click {getLink('here')} to see it on Tronscan.
            </div>

            <div className={styles.action}>
                <Button label="Close" onClick={closePopUp} appearance="outline"/>
            </div>
        </Dialog>
    );
};

