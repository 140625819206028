import React, { FunctionComponent } from "react";
import styles from './tabs.module.scss';

export interface TabOption {
    label: string;
    onClick: Function;
    active: boolean;
    Suffix?: FunctionComponent<any>;
}

interface Props {
    options: TabOption[];
}

export const Tabs: FunctionComponent<Props> = ({ options }) => {
    const renderItem = (item: TabOption, key: number) => {
        const style = item.active ? styles.active : '';

        return (
            <span key={key} className={style} onClick={() => item.onClick()}>
                {item.label}
                {!!item.Suffix && (<item.Suffix {...item} />)}
            </span>
        );
    };

    return (
        <div className={styles.tabs}>
            {options.map(renderItem)}
        </div>
    )
}
