import {IconButton} from "../icon-button";
import React, {FunctionComponent} from "react";
import {showSnack} from "../../../store/notificationSlice";
import { useAppDispatch } from "../../../hooks";

interface Props{
    label: string;
    value: string | number;
    styleWeight?: 'default' | 'slim';
    className?: string;
}

export const CopyButton: FunctionComponent<Props> = props => {
    const dispatch = useAppDispatch();
    const handleClick = async () => {
        await navigator.clipboard.writeText(props.value.toString());
        dispatch(showSnack(`${props.label} copied to clipboard`));
    };

    return <IconButton icon="copy" onClick={handleClick} styleWeight={props.styleWeight} className={props.className}/>;
}
