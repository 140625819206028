import React from "react";
import { Confirm } from "../../utils/confirm";
import { closeConfirmation, getConfirmationIsOpen } from "../../../store/appSlice";
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

export const Confirmation = () => {
    const dispatch = useAppDispatch();
    const confirmation = useSelector(getConfirmationIsOpen);

    if (!confirmation)
        return null;

    const onClose = () => dispatch(closeConfirmation());

    const onConfirmed = () => {
        confirmation.onConfirm();
        onClose();
    }
    return (
        <Confirm
            isOpen={!!confirmation}
            title={confirmation.title}
            subtitle={confirmation.subtitle}
            text={confirmation.text}
            onConfirmed={onConfirmed}
            onClose={onClose}
        />
    )
}
