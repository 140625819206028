import { getUrlGenerator } from '../utils';
import { PaginationParams, PaginationResponse } from '../types';
import { Client } from '../types/Client';
import { Api } from './Api';

const baseUrl = process.env.REACT_APP_CLIENT_API!;
const url = getUrlGenerator(baseUrl);

export default class ClientService {
    public static getClients = (req: PaginationParams): Promise<PaginationResponse<Client>> => Api.get(url(req));
    public static patchClient = (id: string, client: Partial<Client>): Promise<Client> => Api.patch(url(id), client);
    public static getClient = (id: string): Promise<Client> => Api.get(url('withBalance', id));
}
