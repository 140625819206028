import React, { FunctionComponent } from 'react';
import ArrowLeft from '../../../assets/images/icons/arrow-left.svg';
import ArrowRight from '../../../assets/images/icons/arrow-right.svg';
import Block from '../../../assets/images/icons/block.svg';
import Book from '../../../assets/images/icons/book.svg';
import Cancel from '../../../assets/images/icons/cancel.svg';
import Close from '../../../assets/images/icons/close.svg';
import Download from '../../../assets/images/icons/cloudDownload.svg';
import Copy from '../../../assets/images/icons/copy.svg';
import Create from '../../../assets/images/icons/create.svg';
import Delete from '../../../assets/images/icons/delete.svg';
import Check from '../../../assets/images/icons/done.svg';
import Eye from '../../../assets/images/icons/eye.svg';
import More from '../../../assets/images/icons/more.svg';
import Replay from '../../../assets/images/icons/replay.svg';
import Share from '../../../assets/images/icons/share.svg';
import Freeze from '../../../assets/images/icons/snow-flake.svg';
import TaskDone from '../../../assets/images/icons/task_done.svg';
import Search from '../../../assets/images/icons/search.svg';
import Wallet from '../../../assets/images/icons/wallet.svg';
import Analytics from '../../../assets/images/icons/analytics.svg';
import styles from './icon-button.module.scss';

type iconTypes =
    'eye'
    | 'copy'
    | 'close'
    | 'replay'
    | 'edit'
    | 'freeze'
    | 'check'
    | 'more'
    | 'arrow-left'
    | 'arrow-right'
    | 'cancel'
    | 'delete'
    | 'task-done'
    | 'block'
    | 'download'
    | 'book'
    | 'share'
    | 'search'
    | 'analytics'
| 'wallet';

type styleTypes = 'default' | 'slim';
type sizeTypes = 'normal' | 'big';

interface Props {
    icon: iconTypes;
    onClick: Function;
    styleWeight?: styleTypes;
    size?: sizeTypes;
    disabled?: boolean;
    className?: string;
}

const iconOptions: { icon: iconTypes; image: any }[] = [
    { icon: 'eye', image: Eye },
    { icon: 'copy', image: Copy },
    { icon: 'close', image: Close },
    { icon: 'replay', image: Replay },
    { icon: 'edit', image: Create },
    { icon: 'more', image: More },
    { icon: 'arrow-left', image: ArrowLeft },
    { icon: 'arrow-right', image: ArrowRight },
    { icon: 'analytics', image: Analytics },
    { icon: 'cancel', image: Cancel },
    { icon: 'freeze', image: Freeze },
    { icon: 'check', image: Check },
    { icon: 'delete', image: Delete },
    { icon: 'task-done', image: TaskDone },
    { icon: 'block', image: Block },
    { icon: 'book', image: Book },
    { icon: 'download', image: Download },
    { icon: 'share', image: Share },
    { icon: 'search', image: Search},
    { icon: 'wallet', image: Wallet }
];

const styleOptions = new Map<styleTypes, string>();
styleOptions.set('default', styles.default);
styleOptions.set('slim', styles.slim);

const sizeOptions = new Map<sizeTypes, string>();
sizeOptions.set('normal', styles.normal);
sizeOptions.set('big', styles.big);

export const IconButton: FunctionComponent<Props> = props => {
    const { image } = iconOptions.find(x => x.icon === props.icon)!;
    const style = styleOptions.get(props.styleWeight || 'default');
    const size = sizeOptions.get(props.size || 'normal');
    const disabledStyle = props.disabled ? styles.disabled : '';

    const handleClick = () => {
        if (props.disabled) return;

        props.onClick();
    };

    return (
        <div className={`${styles.iconButton} ${style} ${size} ${disabledStyle} ${props.className}`}
             onClick={handleClick}>
            <img src={image} alt={props.icon}/>
        </div>
    );
};
