import styles from './client-partnership.module.scss';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../hooks';
import { useSelector } from 'react-redux';
import { getSelectedClient } from '../../../../store/clientSlice';
import {
    clearCommissionContract,
    delCommissionContract,
    fetchCommissionContract,
    getCommissionContract,
    newCommissionContract,
    openAddReferral,
    putCommissionContract,
} from '../../../../store/partnershipSlice';
import { CreateCommissionContractDto, UpdateCommissionContractDto } from '../../../../services/PartnerService';
import { Field } from '../../../utils/field';
import { FloatingActionButton } from '../../../utils/floating-action-button';
import Save from '../../../../assets/images/icons/save.svg';
import { Switch } from '../../../utils/switch';
import { FormFieldWithHint } from '../../../utils/form-field-with-hint';
import { WithData } from '../../../../types';
import { CommissionContractStatus } from '../../../../types/CommissionContract';
import { CommissionReferral } from '../../../../types/Referral';
import { CopyButton } from '../../../utils/copy-button';
import { IconButton } from '../../../utils/icon-button';
import { AddCommissionReferral } from './client-partnership-add-commission-referral';

const ReferralItem: React.FC<WithData<CommissionReferral>> = ({ data }) => {
    const { id, referralCode, alias } = data;
    const copyLabel = `${alias}'s referral code`;

    const dispatch = useAppDispatch();

    return (
        <tr>
            <td data-title={'Alias'}>{alias}</td>
            <td data-title={'Referral Code'}>
                <div className={styles.referralCode}>
                    <span>{referralCode}</span>
                    <CopyButton label={copyLabel} value={referralCode}/>
                </div>
            </td>
            <td>
                <IconButton icon={'delete'} onClick={() => dispatch(delCommissionContract(id))}/>
            </td>
        </tr>
    );
};

export const ClientPartnership = () => {
    const dispatch = useAppDispatch();
    const client = useSelector(getSelectedClient);
    const commissionContract = useSelector(getCommissionContract);

    const [status, setStatus] = useState<CommissionContractStatus>();
    const [percentage, setPercentage] = useState<number>();
    const [expiresAt, setExpiresAt] = useState<Date>();

    const [isActive, setIsActive] = useState<boolean>(true);

    useEffect(() => {
        dispatch(fetchCommissionContract(client!.id));

        return () => {
            dispatch(clearCommissionContract());
        };
    }, [client]);

    useEffect(() => {
        const _status = isActive ? CommissionContractStatus.Active : CommissionContractStatus.Inactive;
        setStatus(_status);
    }, [isActive]);

    useEffect(() => {
        if (!commissionContract)
            return;

        setPercentage(commissionContract.percentage);
        setExpiresAt(commissionContract.expiresAt);
        setStatus(commissionContract.status);
        setIsActive(commissionContract.status === CommissionContractStatus.Active);
    }, [commissionContract]);

    const canUpdate = [status, percentage, expiresAt].every(x => !!x)
        && [CommissionContractStatus.Inactive, CommissionContractStatus.Active].includes(status!)
        && percentage! >= 0.1
        && percentage! <= 100
        && expiresAt! > new Date();

    const canCreate = [percentage, expiresAt].every(x => !!x)
        && percentage! >= 0.1
        && percentage! <= 100
        && expiresAt! > new Date();

    const createCommissionContract = () => {
        const dto: CreateCommissionContractDto = {
            clientId: client!.id,
            percentage: percentage!,
            expiresAt: expiresAt!,
        };

        dispatch(newCommissionContract(dto));
    };

    const updateCommissionContract = (): void => {
        const dto: UpdateCommissionContractDto = {
            clientId: client!.id,
            status: isActive ? CommissionContractStatus.Active : CommissionContractStatus.Inactive,
            percentage: percentage!,
            expiresAt: expiresAt!,
        };

        dispatch(putCommissionContract(dto));
    };

    const renderCommonFields = () => {
        return (
            <div>
                <FormFieldWithHint label={'Percentage'}
                                   hint={'Commission between 0.1% and 100% that the client will receive.'}>
                    <Field
                        placeholder="10"
                        type={'number'}
                        suffix={'%'}
                        value={percentage}
                        onChange={setPercentage}
                        className={styles.field}
                    />
                </FormFieldWithHint>

                <FormFieldWithHint label={'Expiration'}
                                   hint={'Date in the future that the contract will no longer be valid.'}>
                    <Field
                        placeholder="01/01/2030"
                        type={'date'}
                        value={expiresAt}
                        onChange={setExpiresAt}
                        className={styles.field}
                    />
                </FormFieldWithHint>
            </div>
        );
    };

    if (!commissionContract)
        return (
            <div className={styles.clientCommissionContract}>
                {renderCommonFields()}
                {canCreate && <FloatingActionButton icon={Save} onClick={createCommissionContract}/>}
            </div>
        );

    return (
        <div className={styles.clientCommissionContract}>
            <FormFieldWithHint
                label="Status"
                hint="Invalidates the contract if disabled.">
                <Switch onChange={setIsActive} value={isActive}/>
            </FormFieldWithHint>
            {renderCommonFields()}
            <div className={styles.referralTitle}>
                <span>Referral</span>
                <IconButton icon={'share'} onClick={() => dispatch(openAddReferral())}/>
            </div>
            <div className={styles.referralTable}>
                <table>
                    <thead>
                    <tr>
                        <th>Alias</th>
                        <th>Referral Code</th>
                        <th>
                            <IconButton icon={'share'} onClick={() => dispatch(openAddReferral())}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {commissionContract.referrals.map((x, key) => (
                        <ReferralItem data={x} key={key}/>
                    ))}
                    </tbody>
                </table>
            </div>
            {canUpdate && <FloatingActionButton icon={Save} onClick={updateCommissionContract}/>}
            <AddCommissionReferral/>
        </div>
    );
};
