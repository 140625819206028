import { Api } from './Api';
import { AddressBindingReferral, Referral, ReferralKind } from '../types/Referral';
import { CommissionContractStatus, CommissionContractWithReferrals } from '../types/CommissionContract';

const baseUrl = process.env.REACT_APP_PARTNER_API!;

export interface CreateCommissionContractDto {
    clientId: string;
    percentage: number;
    expiresAt: Date;
}

export interface UpdateCommissionContractDto {
    clientId: string;
    percentage: number;
    expiresAt: Date;
    status: CommissionContractStatus;
}

export interface CreateAddressBindingReferralDto {
    kind: ReferralKind.AddressBinding,
    walletAddress: string,
    alias: string,
    referralCode: string;
}

export interface CreateCommissionReferralDto {
    commissionContractId: string,
    alias: string,
    referralCode: string;
}

export default class PartnerService {
    public static listReferrals(): Promise<AddressBindingReferral[]> {
        const url = `${baseUrl}/referral/admin/${ReferralKind.AddressBinding}`;
        return Api.get<AddressBindingReferral[]>(url);
    }

    public static createReferral(dto: CreateCommissionReferralDto | CreateAddressBindingReferralDto): Promise<Referral> {
        const url = `${baseUrl}/referral/admin`;
        return Api.post<Referral>(url, dto);
    }

    public static deleteReferral(referralCode: string): Promise<void> {
        const url = `${baseUrl}/referral/admin/${referralCode}`;
        return Api.delete<void>(url);
    }

    public static getCommissionContract(clientId: string): Promise<CommissionContractWithReferrals> {
        const url = `${baseUrl}/commission-contract/client/${clientId}`;
        return Api.get<CommissionContractWithReferrals>(url);
    }

    public static createCommissionContract(dto: CreateCommissionContractDto): Promise<CommissionContractWithReferrals> {
        const url = `${baseUrl}/commission-contract`;
        return Api.post<CommissionContractWithReferrals>(url, dto);
    }

    public static updateCommissionContract(dto: UpdateCommissionContractDto): Promise<CommissionContractWithReferrals> {
        const url = `${baseUrl}/commission-contract`;
        return Api.put<CommissionContractWithReferrals>(url, dto);
    }
}
