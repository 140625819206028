import {
    Deposit,
    DepositNotice,
    DepositNoticeStatusEnum,
} from '../types/Deposit';
import { DepositWallet } from '../types/Wallet';
import { Api } from './Api';
import { getUrlGenerator } from '../utils';
import { Socket } from "socket.io-client";

const baseUrl = process.env.REACT_APP_DEPOSIT_API!;

const url = getUrlGenerator(baseUrl);

export class DepositService {
    public static getById = (id: string): Promise<Deposit> => Api.get(url('admin', id));

    public static getDepositWallets = (clientId: string): Promise<DepositWallet> =>
        Api.get(url('admin' , 'wallet', clientId))

    public static getDepositNoticesByClientId = (idClient: string): Promise<DepositNotice[]> =>
        Api.get(url('notice','all', idClient));

    public static updateStatus = (id: string, status: DepositNoticeStatusEnum, amount: number): Promise<void> =>
        Api.put(url('notice', id,), { status, amount });

    public static getDepositNoticeById = (id: string): Promise<DepositNotice> =>
        Api.get(url('notice', 'admin', id))

    public static getPendingDepositNotice = (): Promise<DepositNotice[]> =>
        Api.get(url('notice',  'open'))

    public static connectWebsocket = (): Socket =>
        Api.createWebsocketConnection(url('socket', 'deposit-notice-info'), '/api/deposit')
}
