import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import OperatorService from '../services/OperatorService';
import { AppThunk, RootState } from './index';
import { showAlert } from './notificationSlice';
import { updateOnList } from '../utils';
import { User } from '../types/User';
import { HasId } from '../types';
import UserService from '../services/UserService';

interface OperatorState {
    operators: User[];
    operator: User | null;
}

const initialState: OperatorState = {
    operators: [],
    operator: null
};

export const fetchOperators = createAsyncThunk('operators', OperatorService.get);
export const fetchOperator = createAsyncThunk('operator', UserService.getById);

const operatorSlice = createSlice({
    name: 'operatorSlice',
    initialState,
    reducers: {
        cleanupState: (state) => {
            state.operator = null;
        },
        addOperatorState: (state, action: PayloadAction<User>) => {
            state.operator = action.payload;
            state.operators.push(action.payload);
        },
        updateOperatorState: (state, action: PayloadAction<User>) => {
            state.operator = action.payload;

            if (!state.operators) return;

            state.operators = updateOnList(state.operators, state.operator);
        },
        deleteOperatorState: (state, action: PayloadAction<string>) => {
            state.operators = state.operators.filter(x => x.id !== action.payload);
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchOperators.fulfilled, (state, action) => {
            state.operators = action.payload;
        });
        builder.addCase(fetchOperator.fulfilled, (state, action) => {
            state.operator = { ...action.payload } as User;
        });
    },
});

export const {
    addOperatorState,
    updateOperatorState,
    deleteOperatorState,
    cleanupState
} = operatorSlice.actions;

export const cleanupOperatorState = (): AppThunk => dispatch => dispatch(cleanupState());

export const createOperator = (operator: User, callback: Function): AppThunk => dispatch =>
    OperatorService.create(operator).then(_operator => {
        dispatch(showAlert('Operator created'));
        dispatch(addOperatorState(_operator));
        callback();
    });

export const updateOperator = (operator: User&HasId, callback: Function): AppThunk => dispatch =>
    UserService.update(operator.id, operator).then(() => {
        dispatch(showAlert('Operator updated'));
        dispatch(updateOperatorState(operator));
        callback();
    });

export const deleteOperator = (id: string, callback: Function|null = null): AppThunk => dispatch =>
    OperatorService.delete(id).then(() => {
        dispatch(showAlert('Operator deleted'));
        dispatch(deleteOperatorState(id));
        callback?.call(callback());
    });

export const resetOperatorPassword = (id: string): AppThunk => dispatch =>
    UserService.resetUserPassword(id)
        .then(() => {
            dispatch(showAlert('Password was reset'))
        });

export const getOperators = (state: RootState): User[]|undefined => state.operatorSlice.operators;
export const getOperator = (state: RootState): User|null => state.operatorSlice.operator;

export default operatorSlice.reducer;
