import React, { FunctionComponent } from 'react';
import Close from '../../../assets/images/icons/close.svg';
import useKeypress from '../../../hooks/useKeyPress';
import { Button } from '../button';
import styles from './dialog.module.scss';

interface BaseProps {
    isOpen: boolean;
    onClose?: Function;
    title: string;
    subtitle?: string;
    className?: string;
    children: React.ReactNode;
    leading?: React.ReactNode;
}

interface PropsWithAction extends BaseProps {
    action: string;
    onAction: Function;
    actionDisabled?: boolean;
    loading?: boolean;
}

type Props = BaseProps | PropsWithAction;

export const DialogActions: FunctionComponent<PropsWithAction> = props => {
    return (
        <div className={styles.actions}>
            <Button label="Cancel" onClick={() => props.onClose?.call(props.onClose)} appearance="outline"/>
            <Button
                label={props.action}
                onClick={props.onAction}
                disabled={props.actionDisabled}
                loading={props.loading}/>
        </div>
    );
};

export const Dialog: FunctionComponent<Props> = props => {
    useKeypress('Escape', () => {
        if (!props.isOpen)
            return;

        props.onClose?.call(props.onClose);
    }, [props.isOpen]);

    if (!props.isOpen) return null;

    return (
        <div className={styles.background}>
            <div className={`${styles.dialog} ${props.className}`}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        {!!props.leading && (
                            <div className={styles.leading}>{props.leading}</div>
                        )}
                        <h2>{props.title}</h2>
                    </div>
                    {!!props.onClose && (
                        <img src={Close} alt="closeIcon" onClick={() => props.onClose!()} className={styles.close}/>)}
                </div>
                {props.subtitle && (<div className={styles.subtitle}>{props.subtitle}</div>)}
                {props.children}

                {(props as PropsWithAction).action && <DialogActions {...(props as PropsWithAction)} />}
            </div>
        </div>
    );
};
