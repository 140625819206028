import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchIcon from '../../../assets/images/icons/search.svg';
import { findEthereumWalletName, getEthereumAddressesWithClients } from '../../../store/ethereumWalletsSlice';
import { EthereumAddressWithClient } from '../../../types/EthereumAddresses';
import { Dialog } from '../../utils/dialog';
import { Field } from '../../utils/field';
import { IconButton } from '../../utils/icon-button';
import styles from './search-ethereum-address.module.scss';

interface PropsSearch {
    isOpen: boolean;
    onChosenAddress: (address: string) => void;
    sourceAddress: string | undefined;
    onCancel: () => void;
}

export const SearchEthereumAddress: FunctionComponent<PropsSearch> = (
    { isOpen, onChosenAddress, onCancel, sourceAddress }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const addresses = useSelector(getEthereumAddressesWithClients);

    const renderAddress = (item: EthereumAddressWithClient) => (
        <tr key={item.address} onClick={() => onChosenAddress(item.address)}>
            <td data-title="Name">{findEthereumWalletName(item)}</td>
            <td data-title="Address">{item.address}</td>
        </tr>
    );

    const shouldShowAddress = (item: EthereumAddressWithClient): boolean => {
        if (sourceAddress === item.address)
            return false;

        if (!searchTerm)
            return true;

        const matchAddress = item.address.toLowerCase().includes(searchTerm.toLowerCase());

        if (!item.client)
            return matchAddress;

        const cli = item.client;
        const searchableName = `${cli.firstName} ${cli.lastName} ${cli.companyInfo?.name}`.toLowerCase();
        const matchName = searchableName.includes(searchTerm.toLowerCase());

        const onlyNumbersSearchTerm = searchTerm.replace(/\D/g, '');
        const onlyNumbersDocument = cli.document?.number.replace(/\D/g, '') || '';
        const matchDocument = !!onlyNumbersSearchTerm && onlyNumbersDocument.includes(onlyNumbersSearchTerm);

        return matchAddress || matchName || matchDocument;
    };

    return (
        <Dialog
            isOpen={isOpen}
            title="Send to"
            leading={<IconButton icon="arrow-left" onClick={onCancel}/>}
            className={styles.searchEthereumAddress}>
            <Field
                className={styles.field}
                placeholder="142.142.123-23"
                value={searchTerm}
                onChange={setSearchTerm}
                type="text"
                suffix={<img src={SearchIcon} alt="Search Icon" className={styles.iconSearch}/>}
            />

            <div className={styles.tableAddress}>
                <table>
                    <tbody>
                    {addresses
                        .filter(shouldShowAddress)
                        .map(renderAddress)}
                    </tbody>
                </table>
            </div>
        </Dialog>
    );
};
