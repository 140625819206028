import { getUrlGenerator } from "../utils";
import { Api } from "./Api";
import { Chat, ChatWithMessages } from "../types/Chat";
import { Message } from "../types/Message";

const baseUrl = process.env.REACT_APP_CUSTOMER_SERVICE_API;
const generateUrl = getUrlGenerator(baseUrl);

export default class CustomerService {
    public static listOpenChats = (): Promise<Chat[]> => {
        const url = generateUrl("admin/chat/open");
        return Api.get(url);
    };

    public static listClosedChats = (): Promise<Chat[]> => {
        const url = generateUrl("admin/chat/closed");
        return Api.get(url);
    };

    public static getChatMessages = (chatId: string): Promise<Message[]> => {
        const url = generateUrl(`admin/chat/${chatId}`);
        return Api.get<ChatWithMessages>(url)
            .then(x => x.messages);
    };

    public static closeChat = (chatId: string): Promise<void> => {
        const url = generateUrl(`admin/chat/close`);

        const dto = { chatId };
        return Api.post(url, dto);
    };

    public static enableAttachment = (chatId: string): Promise<void> => {
        const url = generateUrl(`admin/chat/enableAttachment`);

        const dto = { chatId };
        return Api.post(url, dto);
    };

    public static getFileBase64 = (messageId: string): Promise<string> => {
        const url = generateUrl(`chat/attachment/${ messageId }`);
        return Api.get<{ base64: string }>(url)
            .then(x => x.base64);
    };

    public static sendMessage = (chatId: string, text: string): Promise<Message> => {
        const url = generateUrl("admin/chat");

        const dto = {
            chatId,
            text
        };
        return Api.post(url, dto);
    };
}
