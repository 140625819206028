import { User } from "../types/User";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "./index";
import { showAlert } from "./notificationSlice";
import MySelfService from "../services/MySelfService";

interface MySelfState {
    mySelf: User | null
}

const initialState: MySelfState = {
    mySelf: null
}

export const fetchMySelf = createAsyncThunk('user/mySelf', MySelfService.get);

const mySelfSlice = createSlice({
    name: 'mySelfSlice',
    initialState,
    reducers: {
        cleanupState: (state) => {
            state = initialState;
        },
        updateMySelf: (state, action: PayloadAction<User | null>) => {
            state.mySelf = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchMySelf.fulfilled, (state, action) => {
            state.mySelf = action.payload || {} as User;
        });
    },
});

const { cleanupState, updateMySelf } = mySelfSlice.actions;

export const cleanupMySelfState = (): AppThunk => dispatch => dispatch(cleanupState());

export const updateMySelfInfo = (user: Partial<User>): AppThunk => dispatch =>
    MySelfService.update(user)
        .then((mySelf) => {
            dispatch(updateMySelf(mySelf));
            dispatch(showAlert('My self updated'));
        });

export const getMySelf = (state: RootState): User|null => state.mySelfSlice.mySelf;

export default mySelfSlice.reducer;
