import React from 'react';
import {
    closeChangeBookFee,
    getIsChangeBookFeeOpened,
    getSelectedClient,
    updateBookFee
} from "../../../store/clientSlice";
import { useEffect, useState } from "react";
import { Dialog } from "../../utils/dialog";
import { Field } from "../../utils/field";
import styles from './change-client-book-fee.module.scss';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

export const ChangeClientBookFee = () => {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(getIsChangeBookFeeOpened);
    const client = useSelector(getSelectedClient);

    const [bookFee, setBookFee] = useState(0);

    useEffect(() => {
        if(!client)
            return;

        setBookFee(client.bookFee);
    }, [client]);

    if(!client)
        return null;

    return (
        <Dialog
            isOpen={isOpen}
            title='Book fee'
            onClose={() => dispatch(closeChangeBookFee())}
            action='save'
            onAction={() => dispatch(updateBookFee(bookFee))}
        >
            <div className={styles.clientBookFeeBody}>
                <Field placeholder='0.5'
                   type='number'
                   onChange={setBookFee}
                   value={bookFee}
                   suffix='%' />
            </div>
        </Dialog>
    )
}