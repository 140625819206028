import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Book from '../../assets/images/icons/book.svg';
import Eye from '../../assets/images/icons/eye.svg';
import MoneySign from '../../assets/images/icons/money-sign.svg';
import Edit from '../../assets/images/icons/person.svg';
import Freeze from '../../assets/images/icons/snow-flake.svg';
import TaskDone from '../../assets/images/icons/task_done.svg';
import { useAppDispatch } from '../../hooks';
import {
    fetchClients,
    getLstClients,
    getTotalClients,
    openChangeBookFee,
    openChangePurchaseFee,
    openChangeStatusClient,
    openClientInfo,
} from '../../store/clientSlice';
import { BasePagination, WithData } from '../../types';
import { Client, ClientStatusEnum, ClientTypeEnum } from '../../types/Client';
import { basicPagination, cnpjFormatter, cpfFormatter, getFullName, phoneFormatter } from '../../utils';
import { Card } from '../utils/card';
import { DropdownItem, Dropdowns } from '../utils/dropdowns';
import { HeaderApp } from '../utils/header';
import { Paginator } from '../utils/paginator';
import { AddCreditAnnotation } from './add-credit-annotation';
import { ChangeClientBookFee } from './change-client-book-fee';
import { ChangeClientPurchaseFee } from './change-client-purchase-fee';
import { ChangeClientStatus } from './ChangeClientStatus';
import { ClientEdit } from './client/client-edit';
import styles from './clients.module.scss';
import { IconButton } from '../utils/icon-button';
import { downloadAllBalances } from '../../store/reportSlice';

const DropdownClient: FunctionComponent<WithData<Client>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const showDetailOption: DropdownItem = {
        icon: Eye,
        onClick: () => navigate(`/clients/${data.id}`),
        label: 'Details',
    };

    const changePurchase: DropdownItem = {
        icon: MoneySign,
        onClick: () => dispatch(openChangePurchaseFee(data)),
        label: 'Purchase Fee',
    };

    const changeBookFee: DropdownItem = {
        icon: Book,
        onClick: () => dispatch(openChangeBookFee(data)),
        label: 'Book Fee',
    };

    const editClient: DropdownItem = {
        icon: Edit,
        onClick: () => dispatch(openClientInfo(data)),
        label: 'Edit Client',
    };

    useEffect(() => {
    }, [data.purchaseFee]);

    const changeStatus = () => dispatch(openChangeStatusClient(data));

    if (data.status === ClientStatusEnum.active) {
        const freeze: DropdownItem = {
            icon: Freeze,
            onClick: changeStatus,
            label: 'Freeze',
        };
        return <Dropdowns options={[showDetailOption, freeze, changePurchase, changeBookFee, editClient]}/>;
    }

    if (data.status === ClientStatusEnum.pendingApproval) {
        const approve: DropdownItem = {
            icon: TaskDone,
            onClick: () => navigate(`/clients/${data.id}/documents`),
            label: 'Approve',
        };
        return <Dropdowns options={[showDetailOption, approve, editClient]}/>;
    }

    const unfreeze: DropdownItem = {
        icon: TaskDone,
        onClick: changeStatus,
        label: 'Unfreeze',
    };
    return <Dropdowns options={[showDetailOption, unfreeze]}/>;
};

const ClientComponent: FunctionComponent<WithData<Client>> = ({ data }) => {
    const navigate = useNavigate();
    const documentFormatter = data.clientType === ClientTypeEnum.business ? cnpjFormatter : cpfFormatter;

    const openClient = () => navigate(`/clients/${data.id}`);

    const labelStatus = {
        [ClientStatusEnum.closed]: 'Closed Account',
        [ClientStatusEnum.frozen]: 'Frozen',
        [ClientStatusEnum.pendingApproval]: 'Pending Approval',
        [ClientStatusEnum.active]: 'Active',
        [ClientStatusEnum.lockedPassword]: 'Locked Password',
    }[data.status];

    return (
        <tr>
            <td data-title="Name" onClick={openClient}>{getFullName(data)}</td>
            <td data-title="Email" onClick={openClient}>{data.email}</td>
            <td data-title="Phone Number" onClick={openClient}>{!!data.phoneNumber ? phoneFormatter(data.phoneNumber) : '-'}</td>
            <td data-title="Document" onClick={openClient}>{!!data.document ? documentFormatter(data.document.number) : '-'}</td>
            <td data-title="Status" onClick={openClient}>{labelStatus}</td>
            <td data-title="Created At" onClick={openClient}>{data.createdAt.toLocaleString()}</td>
            <td>
                <DropdownClient data={data}/>
            </td>
        </tr>
    );
};
export const Clients = () => {
    const dispatch = useAppDispatch();
    const [pagination, setPagination] = useState<BasePagination>(basicPagination);

    useEffect(() => {
        dispatch(fetchClients(pagination));
    }, [pagination]);

    const lst = useSelector(getLstClients);
    const total = useSelector(getTotalClients);

    return (
        <Card className={styles.clients}>
            <HeaderApp title="Clients">
                <IconButton icon='analytics' onClick={() => dispatch(downloadAllBalances())} />
            </HeaderApp>
            <div className={styles.tableClients}>
                <table>
                    <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Document</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {lst?.map(x => (
                        <ClientComponent key={x.id} data={x}/>
                    ))}
                    </tbody>
                </table>
                <Paginator pagination={pagination} totals={total} setPage={setPagination}/>
            </div>
            <ChangeClientStatus/>
            <ChangeClientPurchaseFee/>
            <ChangeClientBookFee/>
            <AddCreditAnnotation/>
            <ClientEdit/>
        </Card>
    );
};
