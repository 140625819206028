import {Exchange} from '../types/Exchange';
import {getUrlGenerator} from "../utils";
import {Api} from "./Api";

const baseUrl = process.env.REACT_APP_EXCHANGE_API!;
const url = getUrlGenerator(baseUrl)

export class ExchangeService {
    public static getById = (id: string): Promise<Exchange> => Api.get(url('admin', id));
}
