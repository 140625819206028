export enum ReferralKind {
    Commission = 1,
    AddressBinding = 2
}

interface BasicReferral {
    id: string;
    alias: string;
    referralCode: string;
}

export interface CommissionReferral extends BasicReferral {
    kind: ReferralKind.Commission;
    commissionContractId: string;
}

export interface AddressBindingReferral extends BasicReferral {
    kind: ReferralKind.AddressBinding;
    walletAddress: string;
}

export type Referral = CommissionReferral | AddressBindingReferral;