import { FunctionComponent, ReactElement } from 'react';
import styles from './button.module.scss';
import React from 'react';
import Sync from '../../../assets/images/icons/sync.svg'

interface Props {
    label: string;
    onClick: Function;
    disabled?: boolean;
    appearance?: 'primary' | 'outline' | 'text' | 'error';
    className?: string;
    prefix?: ReactElement;
    loading?: boolean;
}

const styleOptions = new Map<string, string>();
styleOptions.set('primary', styles.primary);
styleOptions.set('outline', styles.outline);
styleOptions.set('text', styles.text);
styleOptions.set('error', styles.error)

export const Button: FunctionComponent<Props> = props => {
    const styleDisabled = props.disabled || props.loading ? styles.disabled : '';
    const style = styleOptions.get(props.appearance || 'primary');

    const handleClick = () => {
        if(props.disabled || props.loading)
            return;

        props.onClick()
    };

    const renderButtonContent = () => {
        if(props.loading)
            return <div className={styles.loading}><img src={Sync} alt='loading' className='spin' /></div>

        return <>{props.prefix} {props.label}</>
    }

    return (
        <div className={`${styles.button} ${styleDisabled} ${style} ${props.className}`} onClick={handleClick}>
            {renderButtonContent()}
        </div>
    );
};
