
import { User } from "../types/User";
import { getUrlGenerator } from "../utils";
import {Api} from "./Api";

const baseUrl = process.env.REACT_APP_USER_API;

const generateUrl = getUrlGenerator(baseUrl);

export default class MySelfService {

    static get = (): Promise<User> => 
        Api.get(generateUrl('my-self'));

    static update = (user: Partial<User>): Promise<User> => 
        Api.put(generateUrl('my-self'), user);
}
