import {getUrlGenerator} from "../utils";
import {Payment} from "../types/Payment";
import {Api} from "./Api";

const baseUrl = process.env.REACT_APP_PAYMENT_API

const url = getUrlGenerator(baseUrl);

export default class PaymentService {
    public static getById = (id: string): Promise<Payment> => Api.get(url('admin', id));
}