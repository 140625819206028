import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Chat, ChatStatus, ChatWithMessages } from "../types/Chat";
import { AppThunk, RootState } from "./index";
import CustomerService from "../services/CustomerService";
import { WebSocket } from "../services/WebSocket";
import { Message } from "../types/Message";
import { sortBackwardsList, upsertOnList } from '../utils';

interface ChatState {
    chats: Chat[];
    messages: Message[];
    selectedChat: string | null;
    lastReadMessageDate: Date;
    closedChatsIsOpened: boolean;
    loadingChats: boolean;
}

const initialState: ChatState = {
    chats: [],
    messages: [],
    selectedChat: null,
    lastReadMessageDate: new Date(),
    closedChatsIsOpened: false,
    loadingChats: false
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        selectChat: (state, action: PayloadAction<string>) => {
            state.selectedChat = action.payload;
        },
        upsertChat: (state, action: PayloadAction<Chat>) => {
            state.chats = upsertOnList(state.chats, action.payload);
        },
        resetChatsInfo: (state, action: PayloadAction<Chat[]>) => {
            state.chats = action.payload;
            state.closedChatsIsOpened = false;
        },
        addChats: (state, action: PayloadAction<Chat[]>) => {
            state.chats = [...state.chats, ...action.payload];
        },
        addNewMessage: (state, action: PayloadAction<Message>) => {
            const msg = action.payload;
            state.messages = [...state.messages, msg];

            const chat = state.chats.find(x => x.id === msg.chatId)!;
            const currentChats = state.chats.filter(x => x.id !== msg.chatId);
            state.chats = [chat, ...currentChats];
        },
        updateMessagesFromChat: (state, action: PayloadAction<Message[]>) => {
            const lst = action.payload;
            if (!lst.length)
                return;

            const chatId = lst[0].chatId;
            const lstMessages = state.messages.filter(x => x.chatId !== chatId);
            state.messages = [...lstMessages, ...lst];
        },
        markChatAsOpened: state => {
            state.lastReadMessageDate = new Date();
        },
        setClosedChatsAsOpened: state => {
            state.closedChatsIsOpened = true;
        },
        setLoadingChat: (state, action: PayloadAction<boolean>) => {
            state.loadingChats = action.payload;
        }
    }
});

const {
    selectChat,
    upsertChat,
    addNewMessage,
    updateMessagesFromChat,
    resetChatsInfo,
    addChats,
    setClosedChatsAsOpened,
    setLoadingChat
} = chatSlice.actions;

export const { markChatAsOpened } = chatSlice.actions;

export const fetchOpenChats = (): AppThunk => dispatch => {
    dispatch(setLoadingChat(true));

    CustomerService.listOpenChats()
        .then(lst => {
            const sortedList = sortBackwardsList(lst);
            dispatch(resetChatsInfo(sortedList));
            dispatch(setLoadingChat(false));

            if(!!sortedList[0])
                dispatch(openChat(sortedList[0].id))
        });
};

export const fetchClosedChats = (): AppThunk => (dispatch): Promise<void> => {
    return CustomerService.listClosedChats()
        .then(lst => {
            dispatch(addChats(lst));
            dispatch(setClosedChatsAsOpened());
        })
        .then(x => {
        });
};

export const openChat = (id: string): AppThunk => (dispatch): Promise<void> => {
    dispatch(selectChat(id));
    return CustomerService.getChatMessages(id)
        .then(lst => dispatch(updateMessagesFromChat(lst)))
        .then(x => {
        });
};

export const closeChat = (id: string): AppThunk => (): Promise<void> => {
    return CustomerService.closeChat(id);
};

export const enableAttachment = (id: string): AppThunk => (): Promise<void> => {
    return CustomerService.enableAttachment(id);
};

export const sendTextMessage = (chatId: string, text: string): AppThunk => (): Promise<void> => {
    return CustomerService.sendMessage(chatId, text)
        .then(x => {
        });
};

export const setUp = (): AppThunk => dispatch => {
    WebSocket.onEvent<Message>("new-chat-message", x => dispatch(addNewMessage(x)));
    WebSocket.onEvent<Chat>("new-chat", x => dispatch(upsertChat(x)));
    WebSocket.onEvent<Chat>("chat-info-update", x => dispatch(upsertChat(x)));
};

export const getChats = (state: RootState): Chat[] => {
    const lst = state.chatSlice.chats;

    if (state.chatSlice.closedChatsIsOpened)
        return lst;

    return lst.filter(x => x.status !== ChatStatus.Closed);
};

export const getSelectedChat = (state: RootState): ChatWithMessages | undefined => {
    const chat = state.chatSlice.chats.find(x => x.id === state.chatSlice.selectedChat);
    if (!chat)
        return;

    const messages = state.chatSlice.messages.filter(x => x.chatId === chat.id);
    return { ...chat, messages };
};

export const getClosedChatsIsOpened = (state: RootState): boolean => state.chatSlice.closedChatsIsOpened;

export default chatSlice.reducer;