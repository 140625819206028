import React from 'react';
import './index.scss';
import App from './App';
import { setupApplication, store } from './store';
import { Provider } from 'react-redux';
import axios from 'axios';
import ReactDOM from 'react-dom/client';

setupApplication();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

// @ts-ignore
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';

declare global {
    interface Date {
        endOfTheDay(): Date;
        startOfTheDay(): Date;
    }
}

Date.prototype.endOfTheDay = function () {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate(), 23, 59, 59, 999);
};

Date.prototype.startOfTheDay = function () {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate(), 0, 0, 0, 0);
};
