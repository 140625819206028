import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Statement, Transaction, TransactionTypeEnum } from '../types/Statement';
import { AppThunk, RootState } from './index';
import { PaginationParams } from '../types';
import AccountingService from '../services/AccountingService';
import { Withdraw } from '../types/Withdraw';
import { Deposit, DepositNotice } from '../types/Deposit';
import { CreateCreditAnnotation, CreditAnnotation } from '../types/CreditAnnotation';
import { Exchange } from '../types/Exchange';
import WithdrawService from '../services/WithdrawService';
import { ExchangeService } from '../services/ExchangeService';
import { DepositService } from '../services/DepositService';
import { updateBalance } from './clientSlice';
import { BookService } from '../services/BookService';
import { OrderExchange } from '../types/Order';
import PaymentService from '../services/PaymentService';
import { Payment } from '../types/Payment';

export type TransactionDetail =
    Withdraw
    | Payment
    | Deposit
    | DepositNotice
    | Exchange
    | CreditAnnotation
    | OrderExchange;

type SelectedTransaction = null | {
    type: TransactionTypeEnum;
    transaction: TransactionDetail;
};

interface StatementState {
    loadingStatement: boolean;
    statement: Statement;
    lastPageIndex: number | null;
    selectedTransaction: SelectedTransaction;
    loadingSelectedTransaction: boolean;
    addCreditAnnotationIsOpened: boolean;
    total: number;
    clientId: string | null;
}

const initialState: StatementState = {
    loadingStatement: false,
    statement: [],
    lastPageIndex: null,
    selectedTransaction: null,
    loadingSelectedTransaction: false,
    addCreditAnnotationIsOpened: false,
    total: 0,
    clientId: null,
};

const statementSlice = createSlice({
    name: 'statement',
    initialState,
    reducers: {
        addStatement: (state, action: PayloadAction<Statement>) => {
            state.statement = [...state.statement, ...action.payload];
        },
        updateStatement: (state, action: PayloadAction<Statement>) => {
            state.statement = action.payload;
        },
        updateLoadingStatement: (state, action: PayloadAction<boolean>) => {
            state.loadingStatement = action.payload;
        },
        updateLoadingSelectedTransaction: (state, action: PayloadAction<boolean>) => {
            state.loadingSelectedTransaction = action.payload;
        },
        updateLastPageIndex: (state, action: PayloadAction<number>) => {
            state.lastPageIndex = action.payload;
        },
        clearStatement: state => {
            state.loadingStatement = false;
            state.statement = [];
            state.lastPageIndex = null;
            state.selectedTransaction = null;
            state.loadingSelectedTransaction = false;
            state.total = 0;
            state.clientId = null;
        },
        updateSelectedTransaction: (state, action: PayloadAction<SelectedTransaction>) => {
            state.selectedTransaction = action.payload;
        },
        updateTotal: (state, action: PayloadAction<number>) => {
            state.total = action.payload;
        },
        openAddCreditAnnotation: state => {
            state.addCreditAnnotationIsOpened = true;
        },
        closeAddCreditAnnotation: state => {
            state.addCreditAnnotationIsOpened = false;
        },
        updateClientId: (state, action: PayloadAction<string>) => {
            state.clientId = action.payload;
        },
    },
});

const {
    addStatement,
    updateStatement,
    updateLoadingStatement,
    updateLoadingSelectedTransaction,
    updateLastPageIndex,
    updateSelectedTransaction,
    updateTotal,
    updateClientId,
} = statementSlice.actions;
export const { closeAddCreditAnnotation, openAddCreditAnnotation } = statementSlice.actions;

export const { clearStatement } = statementSlice.actions;

const getDetailOptions: Record<TransactionTypeEnum, (id: string, clientId: string) => Promise<TransactionDetail>> = {
    [TransactionTypeEnum.withdraw]: WithdrawService.getById,
    [TransactionTypeEnum.exchange]: ExchangeService.getById,
    [TransactionTypeEnum.payment]: PaymentService.getById,
    [TransactionTypeEnum.deposit]: DepositService.getById,
    [TransactionTypeEnum.depositNotice]: DepositService.getDepositNoticeById,
    [TransactionTypeEnum.creditAnnotation]: AccountingService.getCreditAnnotation,
    [TransactionTypeEnum.bookExchange]: BookService.getById,
    [TransactionTypeEnum.commission]: ExchangeService.getById,
};

export const fetchedSelectedTransaction = (obj: Transaction | null): AppThunk => (dispatch, getState) => {
    if (!obj) return;

    const action = getDetailOptions[obj.type];

    dispatch(updateSelectedTransaction(null));
    dispatch(updateLoadingSelectedTransaction(true));

    action(obj.id, getState().statementSlice.clientId!)
        .then(transaction => dispatch(updateSelectedTransaction({ type: obj.type, transaction })))
        .finally(() => dispatch(updateLoadingSelectedTransaction(false)));
};

export const fetchStatement = (clientIdToRequest: string | undefined): AppThunk => async (dispatch, getState) => {
    if (!clientIdToRequest) {
        dispatch(clearStatement());
        return;
    }
    const { lastPageIndex, clientId } = getState().statementSlice;

    const differentClient = clientIdToRequest !== clientId;

    const nextPageIndex = (lastPageIndex ?? -1) + 1;
    const pageIndex = differentClient ? 0 : nextPageIndex;
    const request: PaginationParams = {
        pageIndex,
        pageSize: 5,
    };

    const action = differentClient ? updateStatement : addStatement;

    dispatch(updateLoadingStatement(true));
    AccountingService.getStatement(clientIdToRequest, request)
        .then(data => {
            dispatch(updateLastPageIndex(request.pageIndex));
            dispatch(action(data.results));
            dispatch(updateTotal(data.total));
            dispatch(updateClientId(clientIdToRequest));

            if (!getState().statementSlice.selectedTransaction)
                dispatch(fetchedSelectedTransaction(data.results[0]?.transactions[0]));
        })
        .finally(() => dispatch(updateLoadingStatement(false)));
};

export const createCreditAnnotation = (obj: CreateCreditAnnotation): AppThunk => dispatch => {
    AccountingService.createCreditAnnotation(obj).then(x => {
        dispatch(clearStatement());
        dispatch(fetchStatement(obj.clientId));
        dispatch(closeAddCreditAnnotation());
        dispatch(updateBalance());
    });
};

export const getStatementIsLoading = (state: RootState): boolean => state.statementSlice.loadingStatement;
export const getTransactionIsLoading = (state: RootState): boolean => state.statementSlice.loadingSelectedTransaction;
export const getStatement = (state: RootState): Statement => state.statementSlice.statement;
export const getSelectedTransaction = (state: RootState): SelectedTransaction => state.statementSlice.selectedTransaction;
export const getStatementTotal = (state: RootState): number => state.statementSlice.total;
export const getAddCreditAnnotationIsOpened = (state: RootState): boolean => state.statementSlice.addCreditAnnotationIsOpened;

export default statementSlice.reducer;
