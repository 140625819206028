import React, { useCallback, useState } from 'react';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import CloudDownload from '../../assets/images/icons/cloudDownload.svg';
import '../../dateRangeTheme.css';
import { downloadReportPerPeriod } from '../../store/reportSlice';
import { DownloadReportRequest } from '../../types/Report';
import { ReportPerPeriodEnum } from '../../types/Reports';
import { Button } from '../utils/button';
import { Card } from '../utils/card';
import { FormField } from '../utils/form-field';
import { HeaderApp } from '../utils/header';
import { SelectField, SelectFieldOptions } from '../utils/select-field';
import styles from './Report.module.scss';
import { useAppDispatch } from "../../hooks";

const dateSystemStartedInProduction = new Date(2021, 5, 1);
const today = new Date();
const firstDayOfThisMonth = new Date(today);
firstDayOfThisMonth.setDate(1);

const reportTypesOptions: SelectFieldOptions<ReportPerPeriodEnum> = [
    { value: ReportPerPeriodEnum.Client, label: 'Client' },
    { value: ReportPerPeriodEnum.Exchange, label: 'Exchanges' },
    { value: ReportPerPeriodEnum.Deposit, label: 'Deposits' },
    { value: ReportPerPeriodEnum.Withdraw, label: 'Withdraws' },
    { value: ReportPerPeriodEnum.CreditAnnotation, label: 'Credit Annotations' },
    { value: ReportPerPeriodEnum.BookOrder, label: 'Book Orders' },
    { value: ReportPerPeriodEnum.BookTransaction, label: 'Book Transactions' },
    { value: ReportPerPeriodEnum.TravelexManualTrade, label: 'Travelex Manual Trade' },
];

export const Index = () => {
    const dispatch = useAppDispatch();

    const [typeReport, setTypeReport] = useState<ReportPerPeriodEnum>();
    const [sinceDate, setSinceDate] = useState<Date>(firstDayOfThisMonth);
    const [untilDate, setUntilDate] = useState<Date>(today);

    const isDisabled = !typeReport;

    const objForm: DownloadReportRequest = {
        since: sinceDate,
        until: untilDate,
        type: typeReport!,
    };

    const selectionRange: Range = {
        startDate: sinceDate,
        endDate: untilDate,
        key: 'selection',
    };

    const handleSelect = useCallback((ranges: RangeKeyDict) => {
        const { startDate, endDate } = ranges['selection'];
        setSinceDate(startDate!);
        setUntilDate(endDate!);
    }, [setSinceDate, setUntilDate]);

    return (
        <Card>
            <HeaderApp title="Reports"/>

            <div className={styles.body}>
                <FormField label="Type">
                    <SelectField
                        options={reportTypesOptions}
                        onChange={setTypeReport}
                        value={typeReport}/>
                </FormField>
                <h5>Period</h5>
                <DateRange
                    minDate={dateSystemStartedInProduction}
                    maxDate={today}
                    showMonthAndYearPickers={false}
                    showPreview={false}
                    moveRangeOnFirstSelection={false}

                    ranges={[selectionRange]}
                    onChange={handleSelect}/>
                <div className={styles.actions}>
                    <Button prefix={<img className={styles.image} src={CloudDownload} alt="Download"/>}
                            label="Download"
                            onClick={() => dispatch(downloadReportPerPeriod(objForm))}
                            disabled={isDisabled}/>
                </div>
            </div>
        </Card>);
};
