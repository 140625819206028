import React, { useState } from 'react';
import { Field } from "../utils/field";
import { Button } from "../utils/button";
import { Dialog } from "../utils/dialog";
import { ChangePasswordDto } from '../../types/ChangePasswordDto';
import styles from './change-password.module.scss'
import {
    changePassword,
    closeChangePasswordDialog,
    getChangePasswordIsOpened, getPasswordMustBeChanged,
} from '../../store/authSlice';
import UserService from '../../services/UserService';
import { useAppDispatch } from "../../hooks";
import { useSelector } from "react-redux";

export const ChangePassword = () => {
    const dispatch = useAppDispatch();
    const changePasswordIsOpened = useSelector(getChangePasswordIsOpened);
    const passwordMustBeChanged = useSelector(getPasswordMustBeChanged);

    const [continueChangePassword, setContinueChangePassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [twoFactorCode, setTwoFactorCode] = useState<number | undefined>();

    const isInvalidNewPassword = !!newPassword && !!currentPassword && newPassword === currentPassword;
    const isInvalidConfirmNewPassword = newPassword !== confirmNewPassword;

    const isContinueDisabled = !currentPassword || !newPassword || !confirmNewPassword || isInvalidNewPassword || isInvalidConfirmNewPassword;
    const isUpdateDisabled = !twoFactorCode;

    const changePasswordForm: ChangePasswordDto = {
        currentPassword,
        newPassword,
        confirmNewPassword,
        twoFactorCode: twoFactorCode!
    }

    const continueDialog = () => {
        setContinueChangePassword(true);
        UserService.newTwoFA();
    }

    const closeDialog = passwordMustBeChanged ? undefined : () =>
        dispatch(closeChangePasswordDialog());

    return (
        <Dialog
            isOpen={changePasswordIsOpened}
            onClose={closeDialog}
            title='Change Password'
            className={styles.changePassword}
        >
            {!continueChangePassword && (
                <div>
                    <Field
                        label="Current Password"
                        placeholder="********"
                        type="password"
                        min={6}
                        required={true}
                        onChange={setCurrentPassword}
                        value={currentPassword}
                        className={styles.field}
                    />
                    <Field
                        label="New Password"
                        placeholder="********"
                        type="password"
                        min={6}
                        required={true}
                        onChange={setNewPassword}
                        value={newPassword}
                        className={styles.field}
                    />
                    {isInvalidNewPassword && (
                        <span className={styles.error}>Current and new passwords must be different!</span>
                    )}
                    <Field
                        label="Confirm New Password"
                        placeholder="********"
                        type="password"
                        min={6}
                        required={true}
                        onChange={setConfirmNewPassword}
                        value={confirmNewPassword}
                        className={styles.field}
                    />
                </div>
            )}

            {continueChangePassword && (
                <Field
                    label="2FA"
                    placeholder=""
                    type="number"
                    min={6}
                    max={6}
                    required={true}
                    onChange={setTwoFactorCode}
                    value={twoFactorCode}
                    className={styles.field}
                />
            )}

            <div className={styles.actions}>
                {!!closeDialog && (
                    <Button
                        label="Cancel"
                        appearance="outline"
                        onClick={closeDialog}/>
                )}
                {!continueChangePassword && (
                    <Button
                        label='Continue'
                        onClick={() => continueDialog()}
                        disabled={isContinueDisabled}/>
                )}
                {continueChangePassword && (
                    <Button
                        label='Update'
                        onClick={() => dispatch(changePassword(changePasswordForm))}
                        disabled={isUpdateDisabled}/>
                )}
            </div>
        </Dialog>
    )
}
