
import React, { useEffect, useState } from 'react';
import { Button } from "../utils/button";
import styles from './my-self.module.scss'
import { User, userIsEqual } from '../../types/User';
import { Card } from '../utils/card';
import { cleanupMySelfState, fetchMySelf, getMySelf, updateMySelfInfo } from '../../store/mySelfSlice';
import { BaseFormUser } from '../utils/base-form-user';
import { useAppDispatch } from "../../hooks";
import { useSelector } from "react-redux";

export const MySelf = () => {
    const dispatch = useAppDispatch();
    const oldMySelfInfo = useSelector(getMySelf);
    
    const [ newMySelfInfo, setNewMySelfInfo ] = useState<User|null>(null);

    useEffect(() => {
        dispatch(fetchMySelf());
        return () => {
            dispatch(cleanupMySelfState())
        };
    }, []);
    
    useEffect(() => setNewMySelfInfo(oldMySelfInfo), [oldMySelfInfo]);
    
    const updateDataMySelfInfo = (data: any) => {
        if (!data)
            return;

        setNewMySelfInfo({ id: oldMySelfInfo!.id, ...data });
    };

    const isUpdateDisable = !newMySelfInfo?.email || !newMySelfInfo?.firstName || !newMySelfInfo?.lastName || !newMySelfInfo?.phoneNumber;

    const isUpdated = userIsEqual(newMySelfInfo, oldMySelfInfo);

    if (!newMySelfInfo)
        return <div>loading...</div>;

    return (
        <Card title='My information'>
            <div className={styles.mySelf}>
                <BaseFormUser value={newMySelfInfo} onChange={updateDataMySelfInfo} />
                <div className={styles.actions}>
                    <Button 
                        className={styles.button} 
                        label='Update' 
                        disabled={isUpdateDisable || isUpdated}
                        onClick={() => dispatch(updateMySelfInfo(newMySelfInfo))}/>
                </div>
            </div>
        </Card>
    )
}
