import { Card } from '../../utils/card';
import { Label } from '../../utils/label';
import { getCoinInfo } from '../../../utils/constants';
import React, { FunctionComponent } from 'react';
import { AmountWithStats } from '../../../types/Stats';
import { Stats } from './stats';
import { formatAmount } from '../../../utils';
import styles from './summary-item.module.scss';
import { Amount } from '../../../types/Amount';

interface PropsLabels {
    inverted?: boolean;
    data: AmountWithStats | Amount;
}

const SummaryItemLabels: FunctionComponent<PropsLabels> = ({ data, inverted }) => {
    const hasStats = !!(data as AmountWithStats).stats;

    if (!hasStats) return <Label text={getCoinInfo(data.coin).label} content={formatAmount(data)} />;

    return <Stats amountWithStats={data as AmountWithStats} inverted={inverted} />;
};

interface Props {
    title: string;
    data: (AmountWithStats | Amount)[];
    inverted?: boolean;
    primary?: boolean;
}

export const SummaryItem: FunctionComponent<Props> = props => {
    const { data, inverted, primary, title } = props;
    if (!data.length) return null;

    return (
        <div className={styles.summaryItem}>
            <Card title={title} style={primary ? 'primary' : undefined}>
                {data.map(x => (
                    <div className={styles.coinValues} key={x.coin}>
                        <SummaryItemLabels data={x} inverted={inverted} />
                    </div>
                ))}
            </Card>
        </div>
    );
};
