import { getUrlGenerator } from '../utils';
import { Api } from './Api';

const baseUrl = process.env.REACT_APP_REPORT_API;
const generateUrl = getUrlGenerator(baseUrl);

export default class ReportService {
    public static listClients = (start: Date | undefined, end: Date | undefined): Promise<string> =>
        Api.get(generateUrl('client', { start, end }));

    public static listExchanges = (start: Date | undefined, end: Date | undefined): Promise<string> =>
        Api.get(generateUrl('exchange', { start, end }));

    public static listWithdraws = (start: Date | undefined, end: Date | undefined): Promise<string> =>
        Api.get(generateUrl('withdraw', { start, end }));

    public static listDeposits = (start: Date | undefined, end: Date | undefined): Promise<string> =>
        Api.get(generateUrl('deposit', { start, end }));

    public static listCreditAnnotation = (start: Date | undefined, end: Date | undefined): Promise<string> =>
        Api.get(generateUrl('accounting', 'creditAnnotation', { start, end }));

    public static listBookOrders = (start: Date | undefined, end: Date | undefined): Promise<string> =>
        Api.get(generateUrl('book', 'orders', { start, end }));

    public static listBookTransactions = (start: Date | undefined, end: Date | undefined): Promise<string> =>
        Api.get(generateUrl('book', 'transactions', { start, end }));

    public static listTravelexManualTrades = (start: Date | undefined, end: Date | undefined): Promise<string> =>
        Api.get(generateUrl('travelexManualTrade', { start, end }));

    public static getAllStatement = (clientId: string): Promise<string> =>
        Api.get(generateUrl('accounting', 'statement', clientId));

    public static getAllBalances = (): Promise<string> =>
        Api.get(generateUrl('accounting', 'allBalances'));

    public static getAllDepositNotices = (clientId: string): Promise<string> =>
        Api.get(generateUrl('deposit', 'notice', clientId));
}
