import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hooks';
import {
    closeAddAddressBindingReferralPopUp,
    getIsAddPopUpOpen,
    newAddressBindingReferral,
} from '../../../store/addressBindingReferralSlice';
import { Dialog } from '../../utils/dialog';
import { Field } from '../../utils/field';
import styles from './add-address-binding-referral.module.scss';
import { CreateAddressBindingReferralDto } from '../../../services/PartnerService';
import { ReferralKind } from '../../../types/Referral';

export const AddReferral = () => {
    const dispatch = useAppDispatch();
    const isAddPopUpOpen = useSelector(getIsAddPopUpOpen);

    const [code, setCode] = useState('');
    const [address, setAddress] = useState('');
    const [alias, setAlias] = useState('');

    const disableButton = !code || !address || !alias;

    const createReferral = () => {
        const data: CreateAddressBindingReferralDto = {
            kind: ReferralKind.AddressBinding,
            referralCode: code,
            walletAddress: address,
            alias: alias,
        };

        dispatch(newAddressBindingReferral(data))
            .then(() => dispatch(closeAddAddressBindingReferralPopUp()));
    };

    return (
        <Dialog
            className={styles.addReferral}
            isOpen={isAddPopUpOpen}
            title="Add Referral Code"
            action="Create"
            onAction={createReferral}
            actionDisabled={disableButton}
            onClose={() => dispatch(closeAddAddressBindingReferralPopUp())}
        >
            <div className={styles.form}>
                <Field label="Code" placeholder="GRTB22" value={code} onChange={setCode}/>
                <Field label="Address" placeholder="0x5be61E43f8EF4271D3C017213f1564749FE3AA77" value={address}
                       onChange={setAddress}/>
                <Field label="Alias" placeholder="John's Wallet" value={alias} onChange={setAlias}/>
            </div>
        </Dialog>
    );
};
