import { Summary } from '../types/Stats';
import { getUrlGenerator } from '../utils';
import { Api } from './Api';
import { PaginationParams, PaginationResponse } from '../types';
import { CreateCreditAnnotation, CreditAnnotation } from '../types/CreditAnnotation';
import { StatementItem } from '../types/Statement';
import { Balance } from "../types/Client";
import { OverdraftLimit } from "../types/OverdraftLimit";

const baseUrl = process.env.REACT_APP_ACCOUNTING_API;
const generateUrl = getUrlGenerator(baseUrl);

type ResponseStatement = Promise<PaginationResponse<StatementItem>>
export default class AccountingService {
    public static getSummary = (): Promise<Summary> => Api.get<Summary>(generateUrl('summary'));

    public static getStatement = (clientId: string, req: PaginationParams): ResponseStatement =>
        Api.get(generateUrl('statement', clientId, req));

    public static getCreditAnnotation = (id: string): Promise<CreditAnnotation> =>
        Api.get(generateUrl('creditAnnotation', 'admin', id));

    public static createCreditAnnotation = (obj: CreateCreditAnnotation): Promise<CreditAnnotation> =>
        Api.post(generateUrl('creditAnnotation', 'admin'), obj);

    public static getBalance = (clientId: string): Promise<Balance> =>
        Api.get(generateUrl('balance', clientId));

    public static getOverdraftLimit = (clientId: string): Promise<OverdraftLimit> =>
        Api.get(generateUrl('overdraftLimit', clientId));

    public static updateOverdraftLimit = (overdraftLimit: OverdraftLimit): Promise<OverdraftLimit> =>
        Api.post(generateUrl('overdraftLimit'), overdraftLimit);
}
