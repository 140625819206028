import { LoginRequest, User } from "../types/User";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "./index";
import UserService from "../services/UserService";
import { Api } from "../services/Api";
import { showAlert } from "./notificationSlice";
import { ChangePasswordDto } from "../types/ChangePasswordDto";
import { WebSocket } from "../services/WebSocket";

export const TOKEN_KEY = "token";

enum LoginStateEnum {
    started = 1,
    notStarted = 2
}

interface AuthState {
    loggedUser: User | null,
    loginState: LoginStateEnum;
    tokenExpiresAt: number | null;
    passwordMustBeChanged: boolean;
    changePasswordDialogIsOpened: boolean;
    error: string | null
}

const initialState: AuthState = {
    loggedUser: null,
    tokenExpiresAt: null,
    loginState: LoginStateEnum.notStarted,
    passwordMustBeChanged: false,
    changePasswordDialogIsOpened: false,
    error: ""
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateLoggedUser: (state, action: PayloadAction<User | null>) => {
            state.loggedUser = action.payload;
        },
        updateStateLogin: (state, action: PayloadAction<LoginStateEnum>) => {
            state.loginState = action.payload;
        },
        updateError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        updateTokenExpiration: (state, action: PayloadAction<number | null>) => {
            state.tokenExpiresAt = action.payload;
        },
        setPasswordMustBeChanged: (state, action: PayloadAction<boolean>) => {
            state.passwordMustBeChanged = action.payload;
        },
        openChangePasswordDialog: state => {
            state.changePasswordDialogIsOpened = true;
        },
        closeChangePasswordDialog: state => {
            state.changePasswordDialogIsOpened = false;
        }
    }
});

const { updateLoggedUser, updateStateLogin, updateTokenExpiration, setPasswordMustBeChanged } = authSlice.actions;
export const { updateError, openChangePasswordDialog, closeChangePasswordDialog } = authSlice.actions;

export const renewToken = (): AppThunk => dispatch =>
    UserService.renewToken();

export const create2FA = (obj: LoginRequest): AppThunk => dispatch =>
    UserService.create2FA(obj)
        .then(() => dispatch(updateStateLogin(LoginStateEnum.started)))
        .catch(error => dispatch(updateError(error.message)));

export const login = (obj: LoginRequest): AppThunk => dispatch =>
    UserService.login(obj)
        .then(({ token, passwordMustBeChanged }) => {
            dispatch(updateToken(token));
            dispatch(setPasswordMustBeChanged(passwordMustBeChanged));
            dispatch(updateStateLogin(LoginStateEnum.notStarted));

            if (passwordMustBeChanged)
                dispatch(openChangePasswordDialog());
        })
        .catch(({ message }) => dispatch(updateError(message)));

export const updateToken = (token: string): AppThunk => async dispatch => {
    localStorage.setItem(TOKEN_KEY, token);
    Api.setToken(token);
    WebSocket.updateToken(token);

    const payloadToken = token.split(".")[1];
    const decoded = window.atob(payloadToken);
    const user: User = JSON.parse(decoded);

    dispatch(updateLoggedUser(user));
    dispatch(updateTokenExpiration((user as any).exp));

    await WebSocket.openWebsocketConnection();
};

export const loginFromStorage = (): AppThunk => dispatch => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token)
        return;

    dispatch(updateToken(token));
};

export const logoff = (): AppThunk => dispatch => {
    localStorage.setItem(TOKEN_KEY, "");
    Api.setToken("");
    WebSocket.updateToken("");

    dispatch(updateTokenExpiration(null));
    dispatch(updateLoggedUser(null));
};

export const changePassword = (changePasswordForm: ChangePasswordDto): AppThunk => dispatch => {
    UserService.changePassword(changePasswordForm)
        .then(() => {
            dispatch(closeChangePasswordDialog());
            dispatch(showAlert("Password changed!"));
        });
};

export const getLoggedUser = (state: RootState): User | null => state.authSlice.loggedUser;
export const getLoginIsStarted = (state: RootState): boolean => state.authSlice.loginState === LoginStateEnum.started;
export const getErrorAuth = (state: RootState): string | null => state.authSlice.error;
export const getTokenExpiresAt = (state: RootState): number | null => state.authSlice.tokenExpiresAt;
export const getChangePasswordIsOpened = (state: RootState): boolean => state.authSlice.changePasswordDialogIsOpened;
export const getPasswordMustBeChanged = (state: RootState): boolean => state.authSlice.passwordMustBeChanged;

export default authSlice.reducer;
