import {
    closeChangePurchaseFee,
    getIsChangePurchaseFeeOpened,
    getSelectedClient,
    updatePurchaseFee
} from "../../../store/clientSlice";
import {useEffect, useState} from "react";
import {Dialog} from "../../utils/dialog";
import {getFullName} from "../../../utils";
import {Field} from "../../utils/field";
import React from "react";
import styles from './change-client-purchase-fee.module.scss';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

export const ChangeClientPurchaseFee = () => {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(getIsChangePurchaseFeeOpened);
    const client = useSelector(getSelectedClient);

    const [purchaseFee, setPurchaseFee] = useState(0);

    useEffect(() => {
        if(!client)
            return;
        setPurchaseFee(client.purchaseFee);
    }, [client])

    if(!client)
        return null;

    return (
        <Dialog
            isOpen={isOpen}
            onClose={() => dispatch(closeChangePurchaseFee())}
            title='Purchase fee'
            subtitle={getFullName(client)}
            action='save'
            onAction={() => dispatch(updatePurchaseFee(purchaseFee))}
        >
            <div className={styles.clientPurchaseFeeBody}>
                <Field placeholder='3.41' type='number' onChange={setPurchaseFee} value={purchaseFee} suffix='%' />
            </div>
        </Dialog>
    )
}
