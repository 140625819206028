import React, { useState } from 'react';
import { Dialog } from '../../utils/dialog';
import {
    getSelectedWithdraw,
    getMarkAsCompleteIsOpen,
    closePopups,
    updateWithdrawStatus
} from '../../../store/withdrawSlice';
import { Button } from '../../utils/button';
import { WithdrawStatusEnum } from '../../../types/Withdraw';
import { Field } from '../../utils/field';
import styles from './mark-as-completed.module.scss';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

export const MarkAsCompleted = () => {
    const [externalIdentifier, setExternalIdentifier] = useState('');
    const dispatch = useAppDispatch();
    const handleClose = () => dispatch(closePopups());

    const isOpen = useSelector(getMarkAsCompleteIsOpen);
    const selectedWithdraw = useSelector(getSelectedWithdraw);

    if(!selectedWithdraw) {
        return null;
    }

    const updateStatus = () =>
        dispatch(updateWithdrawStatus(selectedWithdraw, WithdrawStatusEnum.done, externalIdentifier));

    return (
        <Dialog className={styles.markAsCompletedWithdraw} title='Mark as Completed' isOpen={isOpen} onClose={handleClose}>
            <Field placeholder="Transaction Identifier" value={externalIdentifier} onChange={setExternalIdentifier} />
            <Button label="Complete" onClick={updateStatus} disabled={!externalIdentifier}/>
        </Dialog>
    )
}
