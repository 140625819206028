import React, { FunctionComponent } from "react";
import { DepositNotice, DepositNoticeStatusEnum } from "../../../types/Deposit";
import styles from './depositNoticeList.module.scss'
import { formatAmount, getFullName, labelDepositNoticeStatus } from "../../../utils";
import { IconButton } from "../../utils/icon-button";
import { openDepositNoticeDetail } from "../../../store/depositSlice";
import { ActionDepositNotice } from "../ActionDepositNotice";
import { useAppDispatch } from "../../../hooks";

interface Props {
    className?: string;
    data: DepositNotice[]|null;
    showClient?: boolean;
}

export const DepositNoticeList: FunctionComponent<Props> = (props) => {
    if (!props.data?.length)
        return (<div className={styles.info}>No deposit notice found for this client</div>);

    return (
        <div className={`${styles.depositNoticeList} ${props.className}`}>
            <table>
                <thead>
                <tr>
                    {props.showClient && (<th>Client</th>)}
                    <th>Value</th>
                    <th>Status</th>
                    <th>Date</th>
                </tr>
                </thead>

                <tbody>
                {props.data?.map((x, key) => (
                    <DepositNoticeItem data={x} key={key} showClient={props.showClient}/>
                ))}
                </tbody>
            </table>
        </div>
    )
}

interface PropsItem {
    data: DepositNotice;
    showClient?: boolean;
}

const DepositNoticeItem: FunctionComponent<PropsItem> = ({data, showClient}) => {
    const dispatch = useAppDispatch();

    const style = data.status === DepositNoticeStatusEnum.Denied ? styles.red : '';

    return (
        <tr>
            {showClient && (<td  data-title='Name'>{getFullName(data.client)}</td>)}
            <td data-title='Amount'>{formatAmount(data.amount)}</td>
            <td data-title='Status' className={style} >{labelDepositNoticeStatus(data)}</td>
            <td data-title='Date'>{data.createdAt.toLocaleString()}</td>
            <td>
                <ActionDepositNotice notice={data} showActions={false}/>
            </td>
            <td>
                <IconButton icon="eye" onClick={() => dispatch(openDepositNoticeDetail(data))}/>
            </td>
        </tr>
    )
}