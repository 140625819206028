import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '.';
import AccountingService from '../services/AccountingService';
import { Summary } from '../types/Stats';

interface SummaryState {
    summaryData: Summary | null;
}

const initialState: SummaryState = {
    summaryData: null,
};

const summarySlice = createSlice({
    name: 'summary',
    initialState,
    reducers: {
        updateSummary: (state, action: PayloadAction<Summary>) => {
            state.summaryData = action.payload;
        },
    },
});

const { updateSummary } = summarySlice.actions;

export const fetchSummary = (): AppThunk => dispatch =>
    AccountingService.getSummary()
        .then(x => dispatch(updateSummary(x)));

export const getSummary = (state: RootState): Summary | null => state.summarySlice.summaryData;

export default summarySlice.reducer;
