import React, { FunctionComponent } from 'react';
import styles from './switch.module.scss';

interface Props{
    onChange: (value: boolean) => void;
    value: boolean;
    className?: string;
    children?: React.ReactNode;
}

export const Switch:FunctionComponent<Props> = (props) => {
    const style = props.value ? styles.isOn : styles.isOff;
    return (
        <div className={`${styles.switch} ${props.className}`}>
            <div className={`${styles.toggle} ${style}`}  onClick={() => props.onChange(!props.value)}>
            </div>
            {!!props.children && (
                <span className={styles.label}>
                    {props.children}
                </span>
            )}
        </div>
    );
};
