export interface RealFees {
    fixed: number;
    percentage: number;
}

export interface TetherFees {
    ethereum: number;
    tron: number;
}

export interface WithdrawFees {
    real: RealFees;
    tether: TetherFees;
    bitcoin: number;
    usdc: number;
    ether: number;
}

export interface ExchangeMinimumValues {
    purchase: number;
    sell: number;
}

export enum AdapterQuoteOrigin {
    awesome = 'nitro',
    hgbrasil = 'optimus',
}

export interface ManualDeposit {
    email: string;
    companyName: string;
    companyDocument: string;
    bank: string;
    agent: string;
    account: string;
}

export interface Settings {
    deposit: {
        maxDepositAmountForNonApprovedClients: number;
        manualDeposit: ManualDeposit;
    },
    withdraw: {
        fees: WithdrawFees
    },
    exchange: {
        sellFee: number;
        defaultFeePurchase: number;
        shouldPurchaseFromSupplier: boolean;
        minimumValues: ExchangeMinimumValues;
        dollarOriginInfo: AdapterQuoteOrigin;
    },
    book: {
        defaultFee: number;
    }
}
