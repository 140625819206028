const errorMessages = new Map();

errorMessages.set('invalid-user-or-password', 'Email or password is invalid');
errorMessages.set('invalid-two-factor-auth-code', 'Verification code is invalid');
errorMessages.set('expired-two-factor-auth-code', 'Verification code is expired');
errorMessages.set('unavailable-email', 'There is already an operator with this email');
errorMessages.set('out-of-operational-window', 'Exchanges must happen between 7h11 and 19h');
errorMessages.set('blocked-user', 'Blocked user');
errorMessages.set('taken-referral-code', 'Code is taken by another referrer.');
errorMessages.set('cannot-pay-fees', 'Insufficient funds to cover fees.');
errorMessages.set('commission-contract-not-found', 'No valid contract is linked to the client.');
errorMessages.set('referral-code-taken', 'Code is taken by another referral.');
errorMessages.set('cannot-pay-fee', 'Couldn\'t pay fee. Please deposit');

export const getErrorMessage = (errorCode: string): string => errorMessages.get(errorCode) || 'Unknown error';
export const getMessageFromError = (error: Error): string => getErrorMessage(error?.message);
