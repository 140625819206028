import React, { FunctionComponent, useEffect } from 'react';
import styles from './operators.module.scss';
import { HeaderApp } from '../utils/header';
import { Card } from '../utils/card';
import { DropdownItem, Dropdowns } from '../utils/dropdowns';
import { User } from '../../types/User';
import { getFullName, phoneFormatter } from '../../utils';
import Delete from '../../assets/images/icons/delete.svg';
import { deleteOperator, fetchOperators, getOperators, resetOperatorPassword } from '../../store/operatorSlice';
import { WithData } from '../../types';
import { openConfirmation } from '../../store/appSlice';
import { FloatingActionButton } from '../utils/floating-action-button';
import Add from '../../assets/images/icons/add.svg';
import Edit from '../../assets/images/icons/edit.svg';
import VpnKey from '../../assets/images/icons/vpn_key.svg';
import { useAppDispatch } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const DropdownOperator: FunctionComponent<WithData<User>> = ({ data }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const resetPasswordOperatorItem: DropdownItem = {
        icon: VpnKey,
        onClick: () => dispatch(
            openConfirmation({
                title: `Reset password`,
                text: `Sure that you want to reset ${getFullName(data)}'s password?`,
                onConfirm: () => dispatch(resetOperatorPassword(data.id)),
            })),
        label: 'Reset password'
    };

    const editClient: DropdownItem = {
        icon: Edit,
        onClick: () => navigate(`/operators/${data.id}`),
        label: 'Edit'
    };

    const deleteOperatorItem: DropdownItem = {
        icon: Delete,
        onClick: () => dispatch(
            openConfirmation({
                title: `Delete`,
                text: `Sure that you want to delete ${getFullName(data)}'s account?`,
                onConfirm: () => dispatch(deleteOperator(data!.id)),
            })),
        label: 'Delete'
    };
    
    return <Dropdowns options={[ resetPasswordOperatorItem, editClient, deleteOperatorItem ]}/>;
};

const OperatorComponent: FunctionComponent<WithData<User>> = ({ data }) => {
    const navigate = useNavigate();

    const openOperator = () => navigate(`/operators/${data.id}`);

    return (
        <tr>
            <td data-title='Name' onClick={openOperator}>{getFullName(data)}</td>
            <td data-title='Email' onClick={openOperator}>{data.email}</td>
            <td data-title='Phone' onClick={openOperator}>{phoneFormatter(data.phoneNumber)}</td>
            <td>
                <DropdownOperator data={data}/>
            </td>
        </tr>
    );
};

const OperatorTable: FunctionComponent<WithData<User[] | undefined>> = ({data}) => {
    if(!data)
        return <span>Loading...</span>;

    if(!data.length)
        return  <span className={styles.noItemOnList}>No operators found</span>;

    return (
        <table>
            <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {data.map(x => (
                <OperatorComponent key={x.id} data={x}/>
            ))}
            </tbody>
        </table>
    )
}

export const Operators = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const newOperator = () => navigate(`/operators/add`);

    useEffect(() => {
        dispatch(fetchOperators());
    }, []);
    
    const operators = useSelector(getOperators);

    return (
        <Card className={styles.cardOperators}>
            <HeaderApp title="Operators"/>

            <div className={styles.tableOperators}>
                <OperatorTable data={operators} />
            </div>

            <FloatingActionButton onClick={() => newOperator()} icon={Add} />
        </Card>
    );
};
