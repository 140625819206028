import { getUrlGenerator } from "../utils";
import { User } from "../types/User";
import { Api } from "./Api";

const baseUrl = process.env.REACT_APP_USER_API!;
const url = getUrlGenerator(`${baseUrl}/operator`);

export default class OperatorService {
    public static get = (): Promise<User[]> => Api.get(url());
    public static create = (operator: Partial<User>): Promise<User> => 
        Api.post(url(), operator);
    public static delete = (id: string): Promise<User> => Api.delete(url(id));
}
