import React, { FunctionComponent } from "react";
import { getSelectedClient } from "../../../../store/clientSlice";
import { getStatement } from "../../../../store/statementSlice";
import { IconButton } from "../../../utils/icon-button";
import { downloadStatement } from "../../../../store/reportSlice";
import { HasActiveProp } from "../client-details/clientDetails";
import { useAppDispatch } from "../../../../hooks";
import { useSelector } from "react-redux";

export const DownloadStatement: FunctionComponent<HasActiveProp> = ({active}) => {
    const dispatch = useAppDispatch();
    const client = useSelector(getSelectedClient)!;
    const statement = useSelector(getStatement);

    if(!active || !statement.length)
        return <div style={{width: '32px'}}>&nbsp;</div>;

    return (
        <IconButton
            styleWeight='slim'
            icon='download'
            onClick={() => dispatch(downloadStatement(client))}/>
    );
}