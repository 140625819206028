import { FunctionComponent } from "react";
import styles  from './form-feild.module.scss'
import React from "react";

interface Props {
    label: string;
    children: React.ReactNode;
}

export const FormField: FunctionComponent<Props> = props => {
    return (
        <div className={styles.formField}>
            <span className={styles.label}>{props.label}</span>
            {props.children}
        </div>
    );
}