import { Amount } from './Amount';

export enum TransactionTypeEnum {
    deposit = 1,
    withdraw = 2,
    exchange = 3,
    creditAnnotation = 4,
    payment = 6,
    bookExchange = 5,
    depositNotice = 7,
    commission = 8,
}

interface BaseTransaction {
    id: string;
    type: TransactionTypeEnum;
    createdAt: Date;
}

export interface PaymentTransaction extends BaseTransaction {
    type: TransactionTypeEnum.payment,
    amount: Amount;
}

export interface DepositTransaction extends BaseTransaction {
    type: TransactionTypeEnum.deposit;
    amount: Amount;
}

export interface DepositNoticeTransaction extends BaseTransaction {
    type: TransactionTypeEnum.depositNotice,
    amount: Amount;
}

export interface WithdrawTransaction extends BaseTransaction {
    type: TransactionTypeEnum.withdraw;
    amount: Amount;
    fee: Amount;
}

export interface ExchangeTransaction extends BaseTransaction {
    type: TransactionTypeEnum.exchange;
    sourceAmount: Amount;
    targetAmount: Amount;
    fee: Amount;
}

export interface CreditAnnotationTransaction extends BaseTransaction {
    type: TransactionTypeEnum.creditAnnotation;
    amount: Amount;
}

export interface OrderExchangeTransaction extends BaseTransaction {
    type: TransactionTypeEnum.bookExchange;
    sourceAmount: Amount;
    targetAmount: Amount;
}

export interface CommissionTransaction extends BaseTransaction {
    type: TransactionTypeEnum.commission;
    amount: Amount;
}

export type Transaction =
    | WithdrawTransaction
    | ExchangeTransaction
    | PaymentTransaction
    | DepositTransaction
    | DepositNoticeTransaction
    | CreditAnnotationTransaction
    | OrderExchangeTransaction
    | CommissionTransaction

export interface StatementItem {
    date: Date;
    transactions: Transaction[];
}

export type Statement = StatementItem[];
