import { Dialog } from "../../../utils/dialog";
import React, { useCallback, useEffect, useState } from 'react';
import { Field } from "../../../utils/field";
import { Button } from "../../../utils/button";
import { closeClientInfo, getIsInfoOpened, getSelectedClient, updateClientInfo } from "../../../../store/clientSlice";
import { Client, ClientTypeEnum } from "../../../../types/Client";
import { HasId } from "../../../../types";
import styles from './client-edit.module.scss'
import { Switch } from "../../../utils/switch";
import { useAppDispatch } from "../../../../hooks";
import { useSelector } from "react-redux";

export const ClientEdit = () => {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(getIsInfoOpened);
    const client = useSelector(getSelectedClient);

    const [ individualDocument, setIndividualDocument ] = useState('');
    const [ individualFirstName, setIndividualFirstName ] = useState('');
    const [ individualLastName, setIndividualLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ companyName, setCompanyName ] = useState('');
    const [ companyDocument, setCompanyDocument ] = useState('');
    const [ receiveEmail, setReceiveEmail ] = useState(true);

    const updateFromSlice = useCallback(() => {
        if (!client)
            return;

        setEmail(client.email)
        setPhoneNumber(client.phoneNumber || '')
        setIndividualFirstName(client.firstName);
        setIndividualLastName(client.lastName);
        setIndividualDocument(client.document?.number || '');
        setReceiveEmail(client.receiveEmail);

        if (client.clientType === ClientTypeEnum.individual)
            return;

        setCompanyName(client.companyInfo!.name);
        setCompanyDocument(client.companyInfo!.document.number);
    }, [ client ]);

    useEffect(updateFromSlice, [ updateFromSlice ]);

    if (!client) return null;

    const clientForm: Partial<Client>&HasId = {
        id: client.id,
        email,
        phoneNumber,
        firstName: individualFirstName,
        lastName: individualLastName,
        document: !client.document ? undefined : {
            number: individualDocument,
            frontId: client.document.frontId,
            backId: client.document.backId,
        },
        selfieId: client.selfieId,
        companyInfo: client.clientType === ClientTypeEnum.individual
            ? undefined
            : {
                name: companyName,
                document: {
                    number: companyDocument,
                    imageId: client.companyInfo!.document.imageId,
                    socialContractImageId: client.companyInfo!.document.socialContractImageId
                }
            },
        receiveEmail,
    }

    return (
        <Dialog
            isOpen={isOpen}
            onClose={() => dispatch(closeClientInfo())}
            title='Client Info'
            className={styles.editClient}
        >
            <Field
                label="Document"
                placeholder="123.456.789.00"
                onChange={setIndividualDocument}
                value={individualDocument}
                className={styles.field}
            />
            <Field
                label="First Name"
                placeholder="Jhon"
                onChange={setIndividualFirstName}
                value={individualFirstName}
                className={styles.field}
            />
            <Field
                label='Last Name'
                placeholder='Doe'
                onChange={setIndividualLastName}
                value={individualLastName}
                className={styles.field}
            />

            {client?.clientType === ClientTypeEnum.business && (
                <>
                    <Field
                        label='Company Name'
                        placeholder='John Doe LTDA'
                        value={companyName}
                        onChange={setCompanyName}
                        required={true}
                        className={styles.field}
                    />
                    <Field
                        label='Company Document'
                        placeholder='00.623.904/0001-73'
                        value={companyDocument}
                        onChange={setCompanyDocument}
                        required={true}
                        className={styles.field}
                    />
                </>
            )}

            <div className={styles.birthDate}>
                <span className={styles.label}>Birth or Start Date: </span>
                <span className={styles.label}>{client.birthOrStartDate?.toLocaleDateString()}</span>
            </div>

            <Field
                label='Email'
                placeholder='jhonDoe@icloud.com'
                onChange={setEmail}
                value={email}
                className={styles.field}
            />
            <Field
                label='Phone Number'
                placeholder='+5511931093258'
                onChange={setPhoneNumber}
                value={phoneNumber}
                className={styles.field}
            />

            <Switch value={receiveEmail} onChange={setReceiveEmail} className={styles.toggle}>
                Notification by email
            </Switch>

            <div className={styles.actions}>
                <Button label="Discard" appearance="outline" onClick={updateFromSlice}/>
                <Button label='Update' onClick={() => dispatch(updateClientInfo(clientForm))}/>
            </div>
        </Dialog>
    )
}
