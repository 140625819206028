import {Amount} from './Amount';
import {BasicClient, BasicUser} from "./index";
import { WithdrawWallet } from './Wallet';

export enum WithdrawStatusEnum {
    pending = 1,
    doing = 2,
    done = 3,
}

export interface WithdrawHistoryEntry {
    status: WithdrawStatusEnum;
    operator?: BasicUser;
    createdAt: Date;
}

export interface Withdraw {
    id: string;
    client: BasicClient;
    amount: Amount;
    fee: Amount;
    wallet: WithdrawWallet;
    status: WithdrawStatusEnum;
    history: WithdrawHistoryEntry[];
    operator?: BasicUser;
    externalIdentifier?: string;
    createdAt: Date;
}
