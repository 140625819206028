import { CommissionReferral } from './Referral';

export enum CommissionContractStatus {
    Active = 1,
    Inactive
}

export interface CommissionContractWithReferrals {
    id: string,
    clientId: string,
    status: CommissionContractStatus,
    createdAt: Date,
    expiresAt: Date,
    percentage: number,
    referrals: CommissionReferral[]
}
