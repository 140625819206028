import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Api } from "../services/Api";
import appSlice from "../store/appSlice";
import authSlice, { loginFromStorage } from "../store/authSlice";
import clientSlice from "../store/clientSlice";
import depositSlice from "../store/depositSlice";
import notificationSlice from "../store/notificationSlice";
import searchSlice from "../store/searchSlice";
import settingsSlice from "../store/settingsSlice";
import statementSlice from "../store/statementSlice";
import summarySlice from "../store/summarySlice";
import withdrawSlice from "../store/withdrawSlice";
import ethereumWalletsSlice from "./ethereumWalletsSlice";
import mySelfSlice from "./mySelfSlice";
import operatorSlice from "./operatorSlice";
import referrerSlice from "./addressBindingReferralSlice";
import tronWalletsSlice from "./tronWalletsSlice";
import partnershipSlice from "./partnershipSlice";
import chatSlice, { setUp } from "./chatSlice";

export const store = configureStore({
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: {
        appSlice,
        authSlice,
        withdrawSlice,
        notificationSlice,
        settingsSlice,
        summarySlice,
        clientSlice,
        searchSlice,
        statementSlice,
        depositSlice,
        operatorSlice,
        mySelfSlice,
        ethereumWalletsSlice,
        referrerSlice,
        tronWalletsSlice,
        partnershipSlice,
        chatSlice
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    AnyAction>;
export type AppDispatch = typeof store.dispatch;

export const setupApplication = (): void => {
    Api.setDispatcher(store.dispatch);

    store.dispatch(loginFromStorage());
    store.dispatch(setUp());
};
