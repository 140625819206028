import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import {
    cleanupOperatorState,
    deleteOperator,
    fetchOperator,
    getOperator,
    updateOperator
} from '../../../store/operatorSlice';
import { BaseFormUser } from '../../utils/base-form-user';
import styles from './operator.module.scss';
import { HeaderApp } from '../../utils/header';
import { Button } from '../../utils/button';
import { User } from '../../../types/User';
import { userIsEqual } from '../../../types/User';
import { Card } from '../../utils/card';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";


export const UpdateOperator = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const oldOperator = useSelector(getOperator);

    const [updatedOperator, setUpdatedOperator] = useState<User | null>(null);

    useEffect(() => {
        dispatch(fetchOperator(id!));
        return () => {
            dispatch(cleanupOperatorState());
        };
    }, [id]);

    useEffect(() => {
        setUpdatedOperator(oldOperator);
    }, [oldOperator]);

    const goToOperators = () => navigate('/operators');

    if (!updatedOperator)
        return <div>loading...</div>;

    const isUpdateDisable = !updatedOperator!.email || !updatedOperator!.firstName || !updatedOperator!.lastName || !updatedOperator!.phoneNumber;

    const isUpdated = userIsEqual(updatedOperator, oldOperator);

    return (
        <Card className={styles.operator}>
            <HeaderApp title={`Edit ${updatedOperator.firstName}`}>
                <Button
                    label="Delete"
                    appearance="outline"
                    onClick={() => dispatch(deleteOperator(oldOperator!.id, goToOperators))}/>
            </HeaderApp>
            <div className={styles.form}>
                <BaseFormUser value={updatedOperator} onChange={setUpdatedOperator}/>
                <Button
                    className={styles.button}
                    label='Update'
                    disabled={isUpdateDisable || isUpdated}
                    onClick={() => dispatch(updateOperator(updatedOperator, goToOperators))}/>
            </div>
        </Card>
    );
};

