import { formatAmount, formatValueAmount, getFullName, getWalletInfo, labelWithdrawStatus } from '../../../utils';
import { CopyButton } from '../../utils/copy-button';
import { IconButton } from '../../utils/icon-button';
import React, { FunctionComponent } from 'react';
import styles from './pending-withdraws.module.scss';
import { Withdraw } from '../../../types/Withdraw';
import { getPendingWithdraws, openWithdrawDetail } from '../../../store/withdrawSlice';
import { WithData } from '../../../types';
import { ActionWithdraw } from '../ActionWithdraw';
import { WithdrawDetail } from '../withdraw-detail';
import { Card } from '../../utils/card';
import { MarkAsCompleted } from '../mark-as-completed';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

const PendingWithdrawItem: FunctionComponent<WithData<Withdraw>> = ({data}) => {
    const dispatch = useAppDispatch();
    const {client, wallet, amount} = data;

    const walletInfo = getWalletInfo(wallet);

    return (
        <tr>
            <td data-title='Name'>{getFullName(client)}</td>
            <td data-title='Amount'>
                <div className={styles.wrapperTd}>
                    {formatAmount(amount)}
                    <CopyButton value={formatValueAmount(amount)} label="Amount"/>
                </div>
            </td>
            <td data-title='Status'>{labelWithdrawStatus(data)}</td>
            <td data-title='Key/Wallet'>
                <div className={styles.wrapperTd}>
                    {walletInfo.label}
                    <CopyButton value={walletInfo.value} label={walletInfo.title}/>
                </div>
            </td>
            <td>
                <ActionWithdraw withdraw={data}/>
            </td>
            <td>
                <IconButton icon="eye" onClick={() => dispatch(openWithdrawDetail(data))}/>
            </td>
        </tr>
    );
};

export const PendingWithdraws = () => {
    const lst = useSelector(getPendingWithdraws);

    if (!lst.length)
        return null;

    return (
        <Card title="Pending Withdraws" className={styles.pendingWithdraws}>
            <div className={styles.withdraws}>
                <table>
                    <thead>
                    <tr>
                        <th>Client</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Key/Wallet</th>
                    </tr>
                    </thead>
                    <tbody>
                    {lst.map((x, key) => (
                        <PendingWithdrawItem data={x} key={key}/>
                    ))}
                    </tbody>
                </table>
                <WithdrawDetail/>
                <MarkAsCompleted/>
            </div>
        </Card>
    );
};
