import React, { FunctionComponent } from 'react';
import { Client } from '../../../../types/Client';
import { TabOption, Tabs } from '../../../utils/tabs';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Card } from '../../../utils/card';
import styles from './client.module.scss';
import { Statement } from '../statement';
import { ClientWallets } from '../client-wallets';
import { ClientDocuments } from '../client-documents';
import { ClientDepositHistory } from '../client-deposit-notice/client-deposit-notice';
import { DownloadStatement } from '../statement/DownloadStatement';
import { DownloadDepositNotice } from '../../../deposit/deposit-notice-detail/DownloadDepositNotice';
import { getProfileIsOpen } from '../../../../store/appSlice';
import { ClientOverdraftLimit } from '../client-overdraft-limit';
import { useSelector } from 'react-redux';
import { ClientPartnership } from '../client-partnership';
import { RoleEnum } from '../../../../types/User';
import { isAuthorized } from '../../../../utils';
import { getLoggedUser } from '../../../../store/authSlice';

interface Props {
    client: Client;
}

export interface HasActiveProp {
    active: boolean;
}

type RouteOption = TabOption & {
    Component: FunctionComponent,
    path: string,
    Suffix?: FunctionComponent<HasActiveProp>,
    permission?: RoleEnum
}
export const ClientDetails: FunctionComponent<Props> = (props) => {
    const navigate = useNavigate();
    const profileIsOpened = useSelector(getProfileIsOpen);

    const openRoute = (subRoute: string) => () => navigate(`${subRoute}`);
    const loggedUser = useSelector(getLoggedUser)!;
    const isActive = (subRoute: string) => window.location.pathname.includes(subRoute);

    const getBaseRoute = (subRoute: string) => ({
        onClick: openRoute(subRoute),
        active: isActive(subRoute),
        path: `${subRoute}`,
    });

    const options: RouteOption[] = [
        {
            ...getBaseRoute('statement'),
            label: 'Statement',
            Component: Statement,
            Suffix: DownloadStatement,
        },
        {
            ...getBaseRoute('overdraftLimit'),
            label: 'Overdraft Limit',
            Component: ClientOverdraftLimit,
        },
        {
            ...getBaseRoute('partnership'),
            label: 'Partnership',
            Component: ClientPartnership,
            permission: RoleEnum.admin
        },
        {
            ...getBaseRoute('depositNotice'),
            label: 'Deposit Notice',
            Component: ClientDepositHistory,
            Suffix: DownloadDepositNotice,
        },
        {
            ...getBaseRoute('walletAndKeys'),
            label: 'Wallet | Keys',
            Component: ClientWallets,
        },
        {
            ...getBaseRoute('documents'),
            label: 'Document',
            Component: ClientDocuments,
        },
    ];

    const filteredOptions = options.filter(x => !x.permission || isAuthorized(loggedUser, [x.permission]));

    return (
        <Card
            className={`${styles.details} ${profileIsOpened ? styles.isProfileOpened : ''}`}
            classNameBody={styles.detailsBody}>
            <Tabs options={filteredOptions}/>
            <Routes>
                {filteredOptions.map((item, key) =>
                    <Route key={key} path={item.path} element={<item.Component/>}/>,
                )}

                <Route path={'*'} element={<Navigate to={options[0].path}/>}/>
            </Routes>
        </Card>
    );
};