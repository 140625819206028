import { RoleEnum } from "../../../types/User";
import { getLoggedUser } from "../../../store/authSlice";
import { FunctionComponent } from "react";
import { isAuthorized } from "../../../utils";
import { useSelector } from "react-redux";

interface Props {
    authorized: RoleEnum | RoleEnum[];
    children: JSX.Element;
}

export const Prevent: FunctionComponent<Props> = (props) => {
    const loggedUser = useSelector(getLoggedUser);

    if (!loggedUser)
        return null;

    const authorizedRoles = typeof props.authorized === 'object' ? [...props.authorized] : [props.authorized];
    const authorized = isAuthorized(loggedUser, authorizedRoles);
    return authorized ? props.children : null;
}
