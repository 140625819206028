import { Amount } from './Amount';
import { WithdrawWallet } from './Wallet';

export type Balance = Amount[];

export enum ClientTypeEnum {
    individual = 1,
    business = 2,
}

export enum ClientStatusEnum {
    closed = 0,
    pendingApproval = 1,
    active = 2,
    frozen = 3,
    lockedPassword = 4
}

export interface IndividualDocument {
    number: string;
    frontId: string;
    backId: string;
}

export interface BusinessDocument {
    number: string;
    imageId: string;
    socialContractImageId: string;
}

export interface Address {
    postalCode: string;
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    complement?: string;
}

export interface CompanyInfo {
    name: string;
    document: BusinessDocument;
}

export interface Client {
    id: string;
    clientType?: ClientTypeEnum
    firstName: string;
    lastName: string;
    selfieId?: string;
    document?: IndividualDocument;
    userId: string;
    status: ClientStatusEnum;
    email: string;
    phoneNumber?: string;
    purchaseFee: number;
    bookFee: number;
    address?: Address;
    balance?: Amount[];
    withdrawWallets: WithdrawWallet[];
    companyInfo?: CompanyInfo;
    createdAt: Date;
    updatedAt: Date;
    receiveEmail: boolean,
    birthOrStartDate: Date;
    referralCode?: string;
}
