import React, { FunctionComponent } from 'react';
import { Client, ClientStatusEnum } from '../../../../types/Client';
import { Card } from '../../../utils/card';
import styles from './client-profile.module.scss';
import { Avatar } from '../../../utils/avatar';
import { formatAmount, getFullName } from '../../../../utils';
import { Label } from '../../../utils/label';
import { IconButton } from '../../../utils/icon-button';
import {
    openChangePurchaseFee,
    openChangeStatusClient,
    openClientAddress,
    openClientInfo,
    resetPassword,
} from '../../../../store/clientSlice';
import { Amount } from '../../../../types/Amount';
import { getCoinInfo } from '../../../../utils/constants';
import { BasicUser } from '../../../../types';
import { Button } from '../../../utils/button';
import { getProfileIsOpen, openConfirmation, toggleProfileOpen } from '../../../../store/appSlice';
import { useAppDispatch } from '../../../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface Props {
    client: Client;
}

export const ClientProfile: FunctionComponent<Props> = ({ client }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const profileIsOpened = useSelector(getProfileIsOpen);

    const toggleProfileIsOpened = () => dispatch(toggleProfileOpen());

    const onResetPassword = () =>
        dispatch(
            openConfirmation({
                title: `Reset Password`,
                subtitle: getFullName(client),
                text: `Are you sure you want reset ${getFullName(client)}'s password ?`,
                onConfirm: () => dispatch(resetPassword()),
            })
        );

    const { street, number, complement, neighborhood, city, state } = client.address || {};

    const labelAddress = `${street}, ${number}, ${complement}, ${neighborhood} - ${city} (${state})`

    const renderStatus = () => {
        const confirmChangeStatus = () => dispatch(openChangeStatusClient(client));

        if (client.status === ClientStatusEnum.pendingApproval)
            return (
                <Label text="Status" className={styles.pendingApproval}>
                    Pending Approval
                    <IconButton
                        styleWeight="slim"
                        icon={'task-done'}
                        onClick={() => navigate(`${location}/documents`)}
                    />
                </Label>
            );

        if (client.status === ClientStatusEnum.active)
            return (
                <Label text="Status" className={styles.activeStatus}>
                    Active <IconButton styleWeight="slim" icon="block" onClick={confirmChangeStatus}/>
                </Label>
            );

        if (client.status === ClientStatusEnum.lockedPassword)
            return (
                <Label text='Status' className={styles.lockedPasswordStatus}>
                    Locked Password <IconButton styleWeight="slim" icon="task-done" onClick={confirmChangeStatus}/>
                </Label>
            )

        if (client.status === ClientStatusEnum.closed)
            return (
                <Label text='Status' className={styles.lockedPasswordStatus}>
                    Closed Account
                </Label>
            )

        return (
            <Label text="Status" className={styles.frozenStatus}>
                Frozen <IconButton styleWeight="slim" icon="task-done" onClick={confirmChangeStatus}/>
            </Label>
        );
    };

    const renderBalance = (item: Amount) => {
        const coinInfo = getCoinInfo(item.coin);
        return <Label key={item.coin} text={coinInfo.label} content={formatAmount(item)}/>;
    };

    const renderAddress = (client: Client) => {
        if (!client.address?.postalCode) {
            return (
                <div className={styles.address}>
                    <h5>Not Registered</h5>
                    <IconButton
                        icon="edit"
                        onClick={() => dispatch(openClientAddress())}
                        styleWeight="slim"
                    />
                </div>
            )
        }

        return (
            <div className={styles.address}>
                <h5> {labelAddress}</h5>

                <IconButton
                    icon="edit"
                    onClick={() => dispatch(openClientAddress())}
                    styleWeight="slim"
                />
            </div>
        )
    }

    const basicUser: BasicUser = {
        id: client.id,
        firstName: client.firstName,
        lastName: client.lastName,
    };

    const balances = client.balance?.filter(x => !!x.value) || [];

    return (
        <Card
            className={`${styles.clientProfile} ${profileIsOpened ? styles.profileIsOpened : ''}`}
            classNameBody={styles.contentCard}>
            <div className={styles.clientProfileHeader}>
                <Avatar user={basicUser} className={styles.avatar} size="big"/>
                <div className={styles.avatarName} onClick={toggleProfileIsOpened}>
                    <h3>{getFullName(client)}</h3>
                    <IconButton
                        icon="edit"
                        onClick={() => dispatch(openClientInfo(client))}
                        styleWeight="slim"
                        className={styles.editPersonButton}
                    />
                </div>

                {renderAddress(client)}
            </div>

            <div className={styles.clientProfileBody}>
                {renderStatus()}

                {!!client.referralCode && <Label text={'Referral Code'}>{client.referralCode}</Label>}

                <Label text="Purchase Fee" className={styles.labelWithAction}>
                    {client.purchaseFee}%
                    <IconButton
                        icon="edit"
                        onClick={() => dispatch(openChangePurchaseFee(client))}
                        styleWeight="slim"
                    />
                </Label>
                <Label text='Book Fee' className={styles.labelWithAction}>
                    {client.bookFee}%
                    <IconButton
                        icon='edit'
                        styleWeight='slim'
                        onClick={() => dispatch(openChangePurchaseFee(client))}/>
                </Label>
                {balances.map(renderBalance)}
            </div>

            <div className={styles.actions}>
                <Button appearance={'outline'} label={'Reset Password'}
                        onClick={() => dispatch(onResetPassword())}/>
            </div>
        </Card>
    );
};
