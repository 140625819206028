import { Dialog } from '../../utils/dialog';
import { getSelectedClient, } from '../../../store/clientSlice';
import { add, formatAmount, getFullName } from '../../../utils';
import React, { useEffect, useState } from 'react';
import { SelectField, SelectFieldOptions } from '../../utils/select-field';
import { coinOptions, getCoinInfo } from '../../../utils/constants';
import { CoinEnum } from '../../../types/Coin';
import { Field } from '../../utils/field';
import styles from './add-credit-annotation.module.scss';
import { Label } from '../../utils/label';
import { CreateCreditAnnotation } from '../../../types/CreditAnnotation';
import {
    closeAddCreditAnnotation,
    createCreditAnnotation,
    getAddCreditAnnotationIsOpened
} from '../../../store/statementSlice';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

type transactionType = 'deposit' | 'withdraw';
const transactionTypeOptions: SelectFieldOptions<transactionType> = [
    { value: 'deposit', label: 'Deposit' },
    { value: 'withdraw', label: 'Withdraw' },
];

export const AddCreditAnnotation = () => {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(getAddCreditAnnotationIsOpened);
    const client = useSelector(getSelectedClient);

    const [coin, setCoin] = useState<CoinEnum>(CoinEnum.brl);
    const [amount, setAmount] = useState<number>();
    const [motive, setMotive] = useState<string>();
    const [transactionType, setTransactionType] = useState<transactionType>('deposit');

    useEffect(() => {
        setCoin(CoinEnum.brl);
        setAmount(undefined);
        setMotive(undefined);
        setTransactionType('deposit');
    }, [isOpen]);

    if (!client) return null;

    const coinInfo = getCoinInfo(coin);
    const balance = client.balance?.find(x => x.coin === coin)?.value || 0;

    const multiplyFactor = transactionType === 'withdraw' ? -1 : 1;
    const executeChangeBalance = () => {
        const creditAnnotation: CreateCreditAnnotation = {
            clientId: client.id,
            amount: {
                coin: coin,
                value: amount! * multiplyFactor,
            },
            motive: motive!,
        };
        dispatch(createCreditAnnotation(creditAnnotation));
    };

    const value = add(balance, (amount || 0) * multiplyFactor);
    const disabled = !coin || !amount || !motive;

    const styleBalanceAfter = value === 0 ? "" : value > 0 ? styles.positive : styles.negative

    return (
        <Dialog
            isOpen={isOpen}
            onClose={() => dispatch(closeAddCreditAnnotation())}
            title="Create Transaction"
            subtitle={`for ${getFullName(client)} `}
            action="Confirm"
            onAction={executeChangeBalance}
            actionDisabled={disabled}
        >
            <div className={styles.addCreditAnnotation}>
                <SelectField
                    options={transactionTypeOptions}
                    onChange={setTransactionType}
                    value={transactionType}
                    label="Transaction Type"
                />
                <SelectField
                    options={coinOptions.map(x => ({ label: x.label, value: x.id }))}
                    onChange={setCoin}
                    value={coin}
                    label="Coin"
                />
                <Field
                    label="Amount"
                    placeholder={coinInfo.placeholder}
                    value={amount}
                    min={0}
                    required={true}
                    onChange={setAmount}
                    prefix={coinInfo.symbol}
                    maxDecimalPlaces={coinInfo.maxDecimalPlaces}
                    type="number"
                />
                <Field
                    label="Motive"
                    placeholder="Why are you doing this operation?"
                    minLength={10}
                    type="textArea"
                    value={motive}
                    onChange={setMotive}
                />
                <Label text="Balance after transaction" content={formatAmount({ coin, value })}
                       className={styleBalanceAfter}/>
            </div>
        </Dialog>
    );
};
