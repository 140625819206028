import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '.';
import PartnerService, {
    CreateCommissionContractDto,
    CreateCommissionReferralDto,
    UpdateCommissionContractDto,
} from '../services/PartnerService';
import { showAlert } from './notificationSlice';
import { CommissionContractWithReferrals } from '../types/CommissionContract';
import { CommissionReferral } from '../types/Referral';

interface PartnershipState {
    commissionContract: CommissionContractWithReferrals | null,
    isAddingReferral: boolean,
}

const initialState: PartnershipState = {
    commissionContract: null,
    isAddingReferral: false,
};

const partnershipSlice = createSlice({
    name: 'commission-contract',
    initialState,
    reducers: {
        updateCommissionContract: (state, action: PayloadAction<CommissionContractWithReferrals>) => {
            state.commissionContract = action.payload;
        },
        clearCommissionContract: (state) => {
            state.commissionContract = null;
        },
        updateCommissionReferrals: (state, action: PayloadAction<CommissionReferral>) => {
            const commissionContract = state.commissionContract;

            if (!commissionContract)
                return;

            state.commissionContract = {
                ...commissionContract,
                referrals: [...commissionContract.referrals, action.payload],
            };
        },
        deleteCommissionReferral: (state, action: PayloadAction<string>) => {
            const commissionContract = state.commissionContract;

            if (!commissionContract)
                return;

            state.commissionContract = {
                ...commissionContract,
                referrals: commissionContract.referrals.filter(x => x.id !== action.payload),
            };
        },
        setIsAddingReferral: (state, action: PayloadAction<boolean>) => {
            state.isAddingReferral = action.payload;
        },
    },
});

export const {
    updateCommissionContract,
    clearCommissionContract,
    updateCommissionReferrals,
    deleteCommissionReferral,
    setIsAddingReferral,
} = partnershipSlice.actions;

export const fetchCommissionContract = (clientId: string): AppThunk => dispatch =>
    PartnerService.getCommissionContract(clientId)
        .then(x => dispatch(updateCommissionContract(x)));

export const newCommissionContract = (dto: CreateCommissionContractDto): AppThunk => dispatch =>
    PartnerService.createCommissionContract(dto)
        .then(x => {
            dispatch(updateCommissionContract(x));
            dispatch(showAlert('Commission contract created', 'success'));
        });

export const putCommissionContract = (dto: UpdateCommissionContractDto): AppThunk => dispatch =>
    PartnerService.updateCommissionContract(dto)
        .then(x => {
            dispatch(updateCommissionContract(x));
            dispatch(showAlert('Commission contract updated', 'success'));
        });

export const newCommissionReferral = (dto: CreateCommissionReferralDto, onFinally: () => void): AppThunk => dispatch =>
    PartnerService.createReferral(dto)
        .then(x => {
            dispatch(updateCommissionReferrals(x as CommissionReferral));
            dispatch(showAlert('Referral created', 'success'));
            dispatch(setIsAddingReferral(false));
        })
        .finally(onFinally);

export const delCommissionContract = (id: string): AppThunk => dispatch =>
    PartnerService.deleteReferral(id)
        .then(() => {
            dispatch(deleteCommissionReferral(id));
            dispatch(showAlert('Commission referral deleted', 'success'));
        });

export const openAddReferral = (): AppThunk => dispatch => {
    dispatch(setIsAddingReferral(true));
};

export const closeAddReferral = (): AppThunk => dispatch => {
    dispatch(setIsAddingReferral(false));
};

export const getCommissionContract = (state: RootState): CommissionContractWithReferrals | null => state.partnershipSlice.commissionContract;

export const getIsAddingReferral = (state: RootState): boolean => state.partnershipSlice.isAddingReferral;

export default partnershipSlice.reducer;
