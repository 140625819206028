import { ChangePasswordDto } from '../types/ChangePasswordDto';
import { LoginRequest, LoginResponse, User } from '../types/User';
import { getUrlGenerator } from '../utils';
import { Api } from './Api';

const baseUrl = process.env.REACT_APP_USER_API;

const generateUrl = getUrlGenerator(baseUrl);

export default class UserService {
    public static login = (obj: LoginRequest): Promise<LoginResponse> => {
        const request: LoginRequest = {
            ...obj,
            email: obj.email.toLowerCase(),
        };
        return Api.post<LoginResponse>(generateUrl('login'), request);
    };

    public static create2FA = (obj: LoginRequest): Promise<void> => {
        const request: LoginRequest = {
            ...obj,
            email: obj.email.toLowerCase(),
        };

        return Api.post(generateUrl('login', 'create2FA'), request);
    }

    public static renewToken = (): Promise<void> =>
        Api.get(generateUrl('login', 'renewToken'));

    public static newTwoFA = (): Promise<void> =>
        Api.post(generateUrl('twoFactorAuth'));

    public static changePassword = (payload: ChangePasswordDto): Promise<void> =>
        Api.post(generateUrl('resetPassword', 'changePasswordUser'), payload);

    public static getById = (id: string): Promise<User> => Api.get(generateUrl(id));

    public static resetClientPassword = (userId: string): Promise<void> =>
        Api.post(generateUrl('resetPassword', 'client', userId));

    public static resetUserPassword = (userId: string): Promise<void> =>
        Api.post(generateUrl('resetPassword', userId));

    public static update = (id: string, user: Partial<User>): Promise<void> =>
        Api.put(generateUrl(id), user);
}
