import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import { openConfirmation } from '../../store/appSlice';
import { WithData } from '../../types';
import { AddressBindingReferral } from '../../types/Referral';
import { Card } from '../utils/card';
import { FloatingActionButton } from '../utils/floating-action-button';
import { HeaderApp } from '../utils/header';
import { IconButton } from '../utils/icon-button';
import { AddReferral } from './add-address-binding-referral';
import styles from './address-binding-referrals.module.scss';
import AddIcon from '../../assets/images/icons/add.svg';
import {
    clearAddressBindingReferrals,
    deleteAddressBindingReferral,
    fetchAddressBindingReferrals,
    getAddressBindingReferrals, openAddAddressBindingReferralPopUp,
} from '../../store/addressBindingReferralSlice';

const ReferrerItem: FunctionComponent<WithData<AddressBindingReferral>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const { referralCode, walletAddress, alias } = data;

    const deleteItem = useCallback(() => {
        dispatch(
            openConfirmation({
                title: `Delete`,
                text: `Do you confirm referrer deletion?`,
                onConfirm: () => dispatch(deleteAddressBindingReferral(data)),
            }),
        );
    }, [dispatch, data]);

    return (
        <tr>
            <td data-title="Code">{referralCode}</td>
            <td data-title="Alias">{alias}</td>
            <td data-title="Wallet">{walletAddress}</td>
            <td>
                <IconButton icon="delete" onClick={deleteItem}></IconButton>
            </td>
        </tr>
    );
};

export const Referrals = () => {
    const dispatch = useAppDispatch();
    const lst = useSelector(getAddressBindingReferrals);

    useEffect(() => {
        dispatch(fetchAddressBindingReferrals());

        return () => {
            dispatch(clearAddressBindingReferrals());
        };
    }, []);

    if (!lst)
        return (<div>Loading...</div>);

    return (
        <>
            <Card className={styles.referrals}>
                <HeaderApp title="Referrals"/>
                <div className={styles.tableReferrers}>
                    <table>
                        <thead>
                        <tr>
                            <th>Code</th>
                            <th>Alias</th>
                            <th>Wallet</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {lst.map((x, key) => (
                            <ReferrerItem data={x} key={key}/>
                        ))}
                        </tbody>
                    </table>
                </div>
                <FloatingActionButton icon={AddIcon}
                                      onClick={() => dispatch(openAddAddressBindingReferralPopUp())}></FloatingActionButton>
            </Card>
            <AddReferral/>
        </>
    );
};
