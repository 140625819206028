import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import {
    closeChangeStatusClient,
    getIsChangeStatusOpened,
    getSelectedClient,
    updateStatusClient,
} from '../../store/clientSlice';
import { ClientStatusEnum } from '../../types/Client';
import { getFullName } from '../../utils';
import { Confirm } from '../utils/confirm';

export const ChangeClientStatus = () => {
    const dispatch = useAppDispatch();

    const isOpened = useSelector(getIsChangeStatusOpened);
    const client = useSelector(getSelectedClient);

    if (!client)
        return null;

    const fullName = getFullName(client);
    const onConfirmed = (status: ClientStatusEnum) => () => dispatch(updateStatusClient(status));
    const onClose = () => dispatch(closeChangeStatusClient());

    if (client.status === ClientStatusEnum.pendingApproval)
        return (
            <Confirm
                isOpen={isOpened}
                subtitle={fullName}
                title="Approve"
                text={`Confirm ${fullName} approval? This will unlock the limit of transactions on his account.`}
                onConfirmed={onConfirmed(ClientStatusEnum.active)}
                onClose={onClose}
            />
        );

    if (client.status === ClientStatusEnum.active)
        return (
            <Confirm
                isOpen={isOpened}
                title="Freeze Account"
                subtitle={fullName}
                onConfirmed={onConfirmed(ClientStatusEnum.frozen)}
                text={`Are you sure you want Freeze the client ${fullName}, he will not be able to login anymore`}
                onClose={onClose}
            />
        );

    if (client.status === ClientStatusEnum.lockedPassword)
        return (
            <Confirm
                isOpen={isOpened}
                title="Unlock password"
                subtitle={fullName}
                onConfirmed={onConfirmed(ClientStatusEnum.active)}
                text={`${fullName}'s attempts to sign in will be reset. Client will be able to try again. Are you sure?`}
                onClose={onClose}
            />
        );

    return (
        <Confirm
            isOpen={isOpened}
            title="Unfrozen client"
            subtitle={fullName}
            onConfirmed={onConfirmed(ClientStatusEnum.active)}
            text={`${fullName} will marked as active and will be able to sign in again. Are you sure?`}
            onClose={onClose}
        />
    );
};
