import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './client-partnership-add-commission-referral.module.scss';
import { useAppDispatch } from '../../../../../hooks';
import {
    closeAddReferral,
    getCommissionContract,
    getIsAddingReferral,
    newCommissionReferral,
} from '../../../../../store/partnershipSlice';
import { CreateCommissionReferralDto } from '../../../../../services/PartnerService';
import { Dialog } from '../../../../utils/dialog';
import { Field } from '../../../../utils/field';
import { getSelectedClient } from '../../../../../store/clientSlice';

export const AddCommissionReferral = () => {
    const dispatch = useAppDispatch();

    const isAddingReferral = useSelector(getIsAddingReferral);
    const commissionContract = useSelector(getCommissionContract);
    const client = useSelector(getSelectedClient);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [alias, setAlias] = useState<string>();
    const [referralCode, setReferralCode] = useState<string>();

    useEffect(() => {
        setIsLoading(false);
        setAlias('');
        setReferralCode('');
    }, [commissionContract]);

    const createCommissionReferral = () => {
        setIsLoading(true);

        const dto: CreateCommissionReferralDto = {
            commissionContractId: commissionContract!.id,
            alias: alias!,
            referralCode: referralCode!,
        };

        dispatch(newCommissionReferral(dto, () => setIsLoading(false)));
    };

    const canCreate = !isLoading
        && !!commissionContract
        && !!alias
        && !!referralCode;

    return (
        <Dialog
            className={styles.addCommissionReferral}
            title="Add Referral Code"
            subtitle={`Referral code to be linked to contract with ${client!.firstName}.`}
            onClose={() => dispatch(closeAddReferral())}
            action="Save"
            actionDisabled={!canCreate}
            onAction={createCommissionReferral}
            loading={false}
            isOpen={isAddingReferral}
        >
            <div className={styles.form}>
                <Field
                    className={styles.field}
                    label="Alias"
                    placeholder="Cryptonic Family"
                    value={alias}
                    required={true}
                    onChange={setAlias}
                    type="text"
                />
                <Field
                    className={styles.field}
                    label="Referral Code"
                    placeholder="CRYPTONIC"
                    value={referralCode}
                    required={true}
                    onChange={setReferralCode}
                    type="text"
                />
            </div>
        </Dialog>
    );
};
