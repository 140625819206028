import { Socket } from 'socket.io-client';
import { Withdraw, WithdrawStatusEnum } from '../types/Withdraw';
import { getUrlGenerator } from '../utils';
import { Api } from './Api';

const baseUrl = process.env.REACT_APP_WITHDRAW_API!;

const url = getUrlGenerator(baseUrl);

export default class WithdrawService {
    public static getById = (id: string): Promise<Withdraw> => Api.get(url('admin', id));
    public static listPending = (): Promise<Withdraw[]> => Api.get(url('admin', 'pending'));

    public static connectWebsocket = (): Socket =>
        Api.createWebsocketConnection(url('socket', 'withdraw-info'), '/api/withdraw');

    public static updateStatus = (id: string, status: WithdrawStatusEnum, txId?: string): Promise<void> =>
        Api.patch(url('admin', id), { status, externalIdentifier: txId });
}
