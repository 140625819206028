import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../utils/button';
import { Card } from '../utils/card';
import { Field } from '../utils/field';
import { HeaderApp } from '../utils/header';
import styles from './settings.module.scss';
import { FormFieldWithHint } from '../utils/form-field-with-hint';
import { Switch } from '../utils/switch';
import { fetchSetting, getSettings, putSettings } from '../../store/settingsSlice';
import { AdapterQuoteOrigin, Settings as SettingsModel } from '../../types/Settings';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import { SelectField, SelectFieldOption } from "../utils/select-field";

const adapterQuoteOriginOptions: SelectFieldOption<AdapterQuoteOrigin>[] = [
    {label: 'HG Brasil' , value: AdapterQuoteOrigin.hgbrasil},
    {label: 'Awesome Api', value: AdapterQuoteOrigin.awesome}
]
export const Settings = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchSetting());
    }, []);

    const [sellFee, setSellFee] = useState(0);
    const [defaultFeePurchase, setDefaultFeePurchase] = useState(0);
    const [defaultBookFee, setDefaultBookFee] = useState(0);
    const [shouldPurchaseFromSupplier, setShouldPurchaseFromSupplier] = useState(false);
    const [purchaseMinimumValue, setPurchaseMinimumValue] = useState(0);
    const [sellMinimumValue, setSellMinimumValue] = useState(0);
    const [usdAdapterQuoteOrigin, setUsdAdapterQuoteOrigin] = useState<AdapterQuoteOrigin>(AdapterQuoteOrigin.awesome);
    const [withdrawFeeRealPercentage, setWithdrawFeeRealPercentage] = useState(0);
    const [withdrawFeeRealValue, setWithdrawFeeValue] = useState(0);
    const [withdrawFeeBitcoin, setWithdrawFeeBitcoin] = useState(0);
    const [withdrawFeeTetherEthereum, setWithdrawFeeTetherEthereum] = useState(0);
    const [withdrawFeeTetherTron, setWithdrawFeeTetherTron] = useState(0);
    const [withdrawFeeUsdCoin, setWithdrawFeeUsdCoin] = useState(0);
    const [withdrawFeeEther, setWithdrawFeeEther] = useState(0);
    const [maxDepositNonApproved, setMaxDepositNonApproved] = useState(0);
    const [manualDepositCompanyName, setManualDepositCompanyName] = useState('');
    const [manualDepositCompanyDocument, setManualDepositCompanyDocument] = useState('');
    const [manualDepositBank, setManualDepositBank] = useState('');
    const [manualDepositAgent, setManualDepositAgent] = useState('');
    const [manualDepositAccount, setManualDepositAccount] = useState('');
    const [manualDepositEmail, setManualDepositEmail] = useState('');

    const settings = useSelector(getSettings);

    const updateForm = useCallback((): void => {
        if (!settings) return;

        setSellFee(settings.exchange.sellFee);
        setDefaultFeePurchase(settings.exchange.defaultFeePurchase);
        setShouldPurchaseFromSupplier(settings.exchange.shouldPurchaseFromSupplier);
        setMaxDepositNonApproved(settings.deposit.maxDepositAmountForNonApprovedClients);
        setWithdrawFeeTetherEthereum(settings.withdraw.fees.tether.ethereum);
        setWithdrawFeeTetherTron(settings.withdraw.fees.tether.tron);
        setWithdrawFeeRealPercentage(settings.withdraw.fees.real.percentage);
        setWithdrawFeeValue(settings.withdraw.fees.real.fixed);
        setWithdrawFeeBitcoin(settings.withdraw.fees.bitcoin);
        setWithdrawFeeUsdCoin(settings.withdraw.fees.usdc);
        setWithdrawFeeEther(settings.withdraw.fees.ether);
        setManualDepositCompanyName(settings.deposit.manualDeposit.companyName);
        setManualDepositCompanyDocument(settings.deposit.manualDeposit.companyDocument);
        setManualDepositBank(settings.deposit.manualDeposit.bank);
        setManualDepositAgent(settings.deposit.manualDeposit.agent);
        setManualDepositAccount(settings.deposit.manualDeposit.account);
        setManualDepositEmail(settings.deposit.manualDeposit.email);
        setPurchaseMinimumValue(settings.exchange.minimumValues.purchase);
        setSellMinimumValue(settings.exchange.minimumValues.sell);
        setDefaultBookFee(settings.book.defaultFee);
    }, [settings]);

    useEffect(updateForm, [settings, updateForm]);

    const payload: SettingsModel = {
        deposit: {
            maxDepositAmountForNonApprovedClients: maxDepositNonApproved,
            manualDeposit: {
                email: manualDepositEmail,
                companyName: manualDepositCompanyName,
                companyDocument: manualDepositCompanyDocument,
                bank: manualDepositBank,
                agent: manualDepositAgent,
                account: manualDepositAccount,
            },
        },
        withdraw: {
            fees: {
                bitcoin: withdrawFeeBitcoin,
                tether: {
                    ethereum: withdrawFeeTetherEthereum,
                    tron: withdrawFeeTetherTron,
                },
                real: {
                    fixed: withdrawFeeRealValue,
                    percentage: withdrawFeeRealPercentage,
                },
                usdc: withdrawFeeUsdCoin,
                ether: withdrawFeeEther,
            },
        },
        exchange: {
            defaultFeePurchase,
            shouldPurchaseFromSupplier,
            minimumValues: {
                purchase: purchaseMinimumValue,
                sell: sellMinimumValue,
            },
            sellFee,
            dollarOriginInfo: usdAdapterQuoteOrigin
        },
        book: {
            defaultFee: defaultBookFee,
        },
    };

    if (!settings)
        return <HeaderApp title='Settings' />;

    return (
        <Card className={styles.settings}>
            <HeaderApp title='Settings'>
                <Button label='Discard changes' className={styles.discardButton} onClick={() => updateForm()}
                        appearance='outline' />
                <Button label='Save changes' onClick={() => dispatch(putSettings(payload))} disabled={false} />
            </HeaderApp>

            <div className={styles.body}>
                <h3>Withdraw Fees</h3>

                <FormFieldWithHint label='Real' hint='This fee will be applied if a withdraw is made in Brazilian Real'>
                    <div className={styles.withdrawReal}>
                        <Field
                            placeholder='10'
                            type='number'
                            value={withdrawFeeRealPercentage}
                            suffix='%'
                            onChange={setWithdrawFeeRealPercentage} />
                        <span>+</span>
                        <Field
                            placeholder='5.76'
                            type='number'
                            prefix='R$'
                            value={withdrawFeeRealValue}
                            onChange={setWithdrawFeeValue}
                        />
                    </div>
                </FormFieldWithHint>

                <FormFieldWithHint label='Bitcoin' hint='This fee will be applied if a withdraw is made in Bitcoin'>
                    <Field
                        placeholder='0.0032'
                        type='number'
                        prefix='BTC'
                        value={withdrawFeeBitcoin}
                        onChange={setWithdrawFeeBitcoin} />
                </FormFieldWithHint>

                <FormFieldWithHint label='Ether' hint='This fee will be applied if a withdraw is made in Ether'>
                    <Field
                        placeholder='0.0005'
                        type='number'
                        prefix='ETH'
                        value={withdrawFeeEther}
                        onChange={setWithdrawFeeEther} />
                </FormFieldWithHint>

                <FormFieldWithHint label='Tether' hint='This fee will be applied if a withdraw is made in USDT'>
                    <div className={styles.withdrawReal}>
                        <Field
                            placeholder='1.00'
                            type='number'
                            prefix='Ethereum'
                            value={withdrawFeeTetherEthereum}
                            onChange={setWithdrawFeeTetherEthereum} />
                        <Field
                            placeholder='1.00'
                            type='number'
                            prefix='TRON'
                            value={withdrawFeeTetherTron}
                            onChange={setWithdrawFeeTetherTron} />
                    </div>
                </FormFieldWithHint>


                <FormFieldWithHint label='USD Coin' hint='This fee will be applied if a withdraw is made in USDC'>
                    <Field
                        placeholder='1.00'
                        type='number'
                        prefix='USDC'
                        value={withdrawFeeUsdCoin}
                        onChange={setWithdrawFeeUsdCoin} />
                </FormFieldWithHint>


                <h3>Book</h3>
                <FormFieldWithHint label='Default Fee' hint='The default book fee assigned on the client creation'>
                    <Field
                        suffix='%'
                        type='number'
                        placeholder='5,70'
                        value={defaultBookFee}
                        onChange={setDefaultBookFee}
                    />
                </FormFieldWithHint>

                <h3>Exchange</h3>
                <FormFieldWithHint label='Minimum Purchase' hint='Minimum amount to purchase'>
                    <Field
                        placeholder='5.76'
                        type='number'
                        prefix='R$'
                        value={purchaseMinimumValue}
                        onChange={setPurchaseMinimumValue}
                    />
                </FormFieldWithHint>

                <FormFieldWithHint label='Minimum Sell' hint='Minimum amount to sell'>
                    <Field
                        placeholder='5.76'
                        type='number'
                        prefix='R$'
                        value={sellMinimumValue}
                        onChange={setSellMinimumValue}
                    />
                </FormFieldWithHint>

                <FormFieldWithHint label='Sell Fee' hint='Fee to be applied on sell'>
                    <Field
                        placeholder='15'
                        type='number'
                        suffix='%'
                        value={sellFee}
                        onChange={setSellFee}
                    />
                </FormFieldWithHint>

                <FormFieldWithHint label='Fee Purchase'
                                   hint='The default fee purchase applied when a new client signs up'>
                    <Field
                        placeholder='10'
                        type='number'
                        suffix='%'
                        value={defaultFeePurchase}
                        onChange={setDefaultFeePurchase}
                    />
                </FormFieldWithHint>

                <FormFieldWithHint label='Purchase on Supplier' hint='Execute crypto purchases on the crypto supplier'>
                    <Switch value={shouldPurchaseFromSupplier} onChange={setShouldPurchaseFromSupplier} />
                </FormFieldWithHint>

                <FormFieldWithHint label='USD info' hint='USD Info will come from wich API'>
                    <SelectField
                        options={adapterQuoteOriginOptions}
                        onChange={setUsdAdapterQuoteOrigin}
                        value={usdAdapterQuoteOrigin} />
                </FormFieldWithHint>

                <h3>Deposit</h3>

                <FormFieldWithHint label='Max deposit'
                                   hint='Max deposit amount for clients with pending approval status'>
                    <Field
                        placeholder='10000'
                        type='number'
                        prefix='R$'
                        value={maxDepositNonApproved}
                        onChange={setMaxDepositNonApproved} />
                </FormFieldWithHint>

                <h4>Manual Deposit</h4>

                <FormFieldWithHint label='Company Name' hint='Company name shown on the manual deposit information'>
                    <Field
                        placeholder='Grootbit LTDA'
                        onChange={setManualDepositCompanyName}
                        value={manualDepositCompanyName} />
                </FormFieldWithHint>

                <FormFieldWithHint label='Company Document'
                                   hint='Company document shown on the manual deposit information screen'>
                    <Field
                        placeholder='43.048.584/0001-88'
                        onChange={setManualDepositCompanyDocument}
                        value={manualDepositCompanyDocument} />
                </FormFieldWithHint>

                <FormFieldWithHint label='Bank' hint='Bank shown on the manual deposit information modal'>
                    <Field
                        placeholder='Itau (342)'
                        onChange={setManualDepositBank}
                        value={manualDepositBank} />
                </FormFieldWithHint>

                <FormFieldWithHint label='Agent' hint='Agent shown on the manual deposit information dialog'>
                    <Field
                        placeholder='3311'
                        onChange={setManualDepositAgent}
                        value={manualDepositAgent} />
                </FormFieldWithHint>

                <FormFieldWithHint label='Account' hint='Account shown on the manual deposit information page'>
                    <Field
                        placeholder='20423-8'
                        onChange={setManualDepositAccount}
                        value={manualDepositAccount} />
                </FormFieldWithHint>

                <FormFieldWithHint label='Email' hint='Email for the client send receipt from manual deposit.'>
                    <Field
                        placeholder='jhon@grootbit.com'
                        type='email'
                        onChange={setManualDepositEmail}
                        value={manualDepositEmail} />
                </FormFieldWithHint>
            </div>
        </Card>
    );
};
