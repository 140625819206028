import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import styles from './app.module.scss';
import ChartIcon from './assets/images/icons/leaderboard.svg';
import ManageAccountsIcon from './assets/images/icons/manage_accounts.svg';
import ClientsIcon from './assets/images/icons/people.svg';
import ReportViewerIcon from './assets/images/icons/report.svg';
import SettingsIcon from './assets/images/icons/settings.svg';
import SlimWalletIcon from './assets/images/icons/slim-wallet.svg';
import CoPresentIcon from './assets/images/icons/co-present.svg';
import { Alerts } from './components/app/alerts';
import { AppBar } from './components/app/app-bar';
import { Confirmation } from './components/app/confirmation';
import { Login } from './components/app/login';
import { Menu } from './components/app/menu';
import { RenewTokenHelper } from './components/app/renew-token-helper';
import { SnackBar } from './components/app/snack-bar';
import { ChangePassword } from './components/change-password';
import { Clients } from './components/clients';
import { Client } from './components/clients/client';
import { Dashboard } from './components/dashboard';
import { DepositNoticeDetail } from './components/deposit/deposit-notice-detail';
import { EthereumWallets } from './components/ethereum-wallets';
import { MySelf } from './components/my-self';
import { Operators } from './components/operators';
import { AddOperator } from './components/operators/add-operator';
import { UpdateOperator } from './components/operators/update-operator';
import { Index } from './components/reports';
import { Settings } from './components/settings';
import { Prevent } from './components/utils/prevent';
import { getLoggedUser } from './store/authSlice';
import { subscribeSocketDepositNotice, unsubscribeSocketDepositNotice } from './store/depositSlice';
import { subscribeSocketWithdraw, unsubscribeSocketWithdraw } from './store/withdrawSlice';
import { RoleEnum } from './types/User';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./store";
import { Referrals } from './components/address-binding-referrals';
import { TronWallets } from './components/tron-wallets';
import {Chats} from "./components/chats";

export interface IRoute {
    path: string;
    Component: () => JSX.Element;
    authorized: RoleEnum[];
}

export interface MenuItem extends IRoute {
    icon: any;
    label: string;
}

const routesWithMenu: MenuItem[] = [
    {
        icon: ChartIcon,
        label: 'Dashboard',
        path: '/dashboard',
        Component: Dashboard,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
    {
        icon: ClientsIcon,
        label: 'Clients',
        path: '/clients',
        Component: Clients,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
    {
        icon: SlimWalletIcon,
        label: 'Ethereum Wallets',
        path: '/ethereum-wallets',
        Component: EthereumWallets,
        authorized: [RoleEnum.admin]
    },
    {
        icon: SlimWalletIcon,
        label: 'Tron Wallets',
        path: '/tron-wallets',
        Component: TronWallets,
        authorized: [RoleEnum.admin]
    },
    {
        icon: ManageAccountsIcon,
        label: 'Operators',
        path: '/operators',
        Component: Operators,
        authorized: [RoleEnum.admin],
    },
    {
        icon: ReportViewerIcon,
        label: 'Report',
        path: '/reports',
        Component: Index,
        authorized: [RoleEnum.admin],
    },
    {
        icon: CoPresentIcon,
        label: 'Referrals',
        path: '/address-binding-referrals',
        Component: Referrals,
        authorized: [RoleEnum.admin],
    },
    {
        icon: SettingsIcon,
        label: 'Settings',
        path: '/settings',
        Component: Settings,
        authorized: [RoleEnum.admin],
    },
];

const routesWithoutMenu: IRoute[] = [
    {
        path: '/operators/add',
        Component: AddOperator,
        authorized: [RoleEnum.admin],
    },
    {
        path: '/operators/:id',
        Component: UpdateOperator,
        authorized: [RoleEnum.admin],
    },
    {
        path: '/clients/:id/*',
        Component: Client,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
    {
        path: '/my-self',
        Component: MySelf,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
];

const routes = [...routesWithoutMenu, ...routesWithMenu];

const App = () => {
    const dispatch = useDispatch<AppDispatch>();
    const loggedUser = useSelector(getLoggedUser);

    useEffect(() => {
        const unsubscribe = () => {
            dispatch(unsubscribeSocketWithdraw());
            dispatch(unsubscribeSocketDepositNotice());
        };
        if (!loggedUser)
            return unsubscribe;

        dispatch(subscribeSocketWithdraw());
        dispatch(subscribeSocketDepositNotice());

        return unsubscribe;
    }, [loggedUser]);

    if (!loggedUser)
        return <Login/>;

    return (
        <>
            <SnackBar/>
            <Alerts/>
            <RenewTokenHelper/>
            <Confirmation/>
            <BrowserRouter>
                <Routes>
                    {routes.map(route => (
                        <Route path={route.path} key={route.path} element={
                            <Prevent authorized={route.authorized}>
                                <div className={styles.app}>
                                    <Menu routes={routesWithMenu}/>
                                    <div className={styles.content}>
                                        <AppBar/>
                                        <route.Component/>
                                    </div>
                                </div>
                            </Prevent>
                        } />
                    ))}
                    <Route path="*" element={<Navigate to='/dashboard' />} />
                </Routes>
            </BrowserRouter>
            <DepositNoticeDetail/>
            <ChangePassword/>
        </>
    );
};

export default App;
