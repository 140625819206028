export enum ReportPerPeriodEnum {
    Client = 1,
    Exchange = 2,
    Deposit = 3,
    Withdraw = 4,
    CreditAnnotation = 5,
    BookOrder = 6,
    BookTransaction = 7,
    TravelexManualTrade = 8
}
