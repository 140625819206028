import { Amount } from '../types/Amount';
import { Api } from './Api';
import { getUrlGenerator } from '../utils';

const baseUrl = process.env.REACT_APP_ETHERSCAN_API;
const generateUrl = getUrlGenerator(baseUrl);

export default class EtherscanAdapterService {
    public static fetchEstimateFee = (): Promise<Amount> =>
        Api.get(generateUrl('withdraw', 'estimateFee'));
}