import React, {FunctionComponent} from "react";
import styles from  './floating-action-button.module.scss';

interface BasicButton{
    icon: string;
    onClick: Function;
}

export const FloatingActionButton: FunctionComponent<BasicButton> = (props) =>
    <div className={styles.floatingActionButton} onClick={() => props.onClick()}>
        <img src={props.icon} alt="icon" className={styles.content}/>
    </div>;
