import React, {FunctionComponent, useCallback, useRef, useState} from "react"
import styles from './dropdowns.module.scss';
import {IconButton} from "../icon-button";
import useOnClickOutside from "../../../hooks/useOnclickOutside";

export interface DropdownItem {
    icon: string;
    label: string;
    onClick: Function
}

interface Props {
    options: DropdownItem[];
    children?: React.ReactNode;
}

export const Dropdowns: FunctionComponent<Props> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const style = isOpen ? styles.opened : '';
    const ref = useRef(null);
    const toggleOpened = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    useOnClickOutside(ref, () => {
        if (!isOpen)
            return;

        toggleOpened();
    });

    const handleClick = (item: DropdownItem) => () => {
        setIsOpen(false);
        item.onClick();
    };

    const renderActionButton = () => {
        if (!!props.children)
            return (
                <div onClick={toggleOpened}>
                    {props.children}
                </div>
            )

        return <IconButton icon='more' onClick={toggleOpened}/>
    }

    return (
        <div className={`${styles.dropdowns} ${style}`} >
            {renderActionButton()}
            <div className={styles.popover} ref={ref}>
                {props.options.map((obj, i) => (
                    <div className={styles.dropDownItem} key={i} onClick={handleClick(obj)}>
                        <img src={obj.icon} alt='Icon'/>
                        <span>{obj.label}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
