import React, { useEffect } from 'react';
import styles from './dashboard.module.scss';
import { SummaryItem } from './summary-item';
import { PendingWithdraws } from '../withdraws/pending-withdraws';
import { HeaderApp } from '../utils/header';
import { fetchSummary, getSummary } from '../../store/summarySlice';
import { IconButton } from '../utils/icon-button';
import { Prevent } from '../utils/prevent';
import { RoleEnum } from "../../types/User";
import { PendingDepositNotice } from "../deposit/pending-deposit-notice";
import { useAppDispatch } from "../../hooks";
import { useSelector } from "react-redux";

export const Dashboard = () => {
    const dispatch = useAppDispatch();
    const summary = useSelector(getSummary);

    useEffect(() => {
        dispatch(fetchSummary());
    }, []);

    return (
        <div className={ styles.dashboard }>
            <HeaderApp title="Dashboard">
                <IconButton className={styles.iconReplay} icon={ 'replay' } onClick={ () => dispatch(fetchSummary()) } size="big"/>
            </HeaderApp>
            { !!summary && (
                <Prevent authorized={ RoleEnum.admin }>
                    <div className={ styles.info }>
                        <SummaryItem title="Totals balances" data={ summary.totalBalances } primary={ true }/>
                        <SummaryItem title="Withdraws last 24 hrs" data={ summary.withdraw } inverted={ true }/>
                        <SummaryItem title="Deposits last 24 hrs" data={ summary.deposits }/>
                    </div>
                </Prevent>
            ) }
            <PendingWithdraws/>
            <PendingDepositNotice/>
        </div>
    );
};
