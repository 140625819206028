import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hooks';
import {
    closeEthereumTransferPopUp,
    getEthereumTransactionId,
    getSelectedEthereumAddress,
} from '../../../store/ethereumWalletsSlice';
import { Button } from '../../utils/button';
import { Dialog } from '../../utils/dialog';
import styles from './success-transfer-ethereum.module.scss';

export const SuccessTransferEthereum = () => {
    const dispatch = useAppDispatch();

    const selectedBalance = useSelector(getSelectedEthereumAddress);
    const transferId = useSelector(getEthereumTransactionId);

    const isOpen = !!selectedBalance && !!transferId;

    const closePopUp = useCallback(() => dispatch(closeEthereumTransferPopUp()), []);
    const getLink = (text: string) => <a rel="noreferrer" href={`https://etherscan.io/tx/${transferId}`} target="_blank">{text}</a>;

    return (
        <Dialog
            isOpen={isOpen}
            title="Transfer Created Successfully"
            onClose={closePopUp}
            className={styles.successTransferEthereum}
        >
            <div className={styles.successMessage}>
                Your transfer was created with txId: {transferId}.<br/><br/>
                Click {getLink('here')} to see on Etherscan
            </div>

            <div className={styles.action}>
                <Button label="Close" onClick={closePopUp} appearance="outline"/>
            </div>
        </Dialog>
    );
};

