import { FunctionComponent } from "react";
import React from 'react';
import { Label } from "../../../utils/label";
import { StatsItem as StatsItemComponent } from '../stats-item/stats-item';
import { deltaTypes, getCoinInfo } from "../../../../utils/constants";
import { AmountWithStats } from "../../../../types/Stats";
import styles from './stats.module.scss';
import { formatAmount } from "../../../../utils";

interface Props {
    amountWithStats: AmountWithStats;
    inverted?: boolean;
}

export const Stats: FunctionComponent<Props> = props => {
    const { inverted } = props;
    const { coin, stats } = props.amountWithStats;

    return (
        <div className={styles.deltaStats}>
            <Label text={getCoinInfo(coin).label} content={formatAmount(props.amountWithStats)}/>
            <div className={styles.stats}>
                {stats.map((x, i) => {
                    const { label } = deltaTypes.get(x.type)!;

                    return <StatsItemComponent
                        key={i}
                        percent={x.percentage}
                        label={label}
                        inverted={inverted}
                    />;
                })}
            </div>
        </div>
    );
}
