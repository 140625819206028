import React, { useEffect } from 'react';
import styles from './client-documents.module.scss';
import {
    fetchDocumentImages, fetchIdWallReport,
    getDocumentImages, getIdWallReport,
    getSelectedClient,
    openChangeStatusClient
} from '../../../../store/clientSlice';
import { cnpjFormatter, cpfFormatter, getFullName } from '../../../../utils';
import { Label } from '../../../utils/label';
import { Button } from '../../../utils/button';
import { Client, ClientStatusEnum } from '../../../../types/Client';
import { RelatedPerson } from "../../../../types/idWall";
import { useAppDispatch } from '../../../../hooks';
import { useSelector } from "react-redux";

const IdWallInfo = () => {
    const idWallReport = useSelector(getIdWallReport);

    if (!idWallReport)
        return <span>Could not find a report</span>

    const renderCompanyInfo = () => {
        if (!idWallReport.companyReport)
            return;

        const renderRelatedPerson = (person: RelatedPerson) => {
            const documentName = cpfFormatter(person.document) + person.name;

            return <span className={styles.relatedPerson} key={person.document}> {documentName} </span>
        }

        return (
            <>
                <Label text={'Company Document'} content={idWallReport.companyReport.document}/>
                <Label text={'Company Nome'} content={cnpjFormatter(idWallReport.companyReport.document)}/>
                <Label text={'Company Public Name'} content={idWallReport.companyReport.publicName}/>
                <Label text={'Company Start Date'} content={idWallReport.companyReport.startDate.toLocaleDateString()}/>
                <h4>Company Related Person</h4>
                {idWallReport.companyReport.relatedPersons.map(renderRelatedPerson)}
            </>
        );
    }

    return (
        <>
            <Label text={'Document'} content={cpfFormatter(idWallReport.document)}/>
            <Label text={'Name'} content={idWallReport.name}/>
            <Label text={'Birth Date'} content={idWallReport.birthDate?.toLocaleDateString()}/>
            <Label text={'Politically Exposed Person'} content={idWallReport.politicallyExposedPerson?.toString()}/>
            <Label text={'Images Match'} content={idWallReport.imagesMatch.toString()}/>
            {renderCompanyInfo()}
        </>
    )
};

interface ClientInfoProps {
    client: Client;
}

const ClientInfo = (props: ClientInfoProps) => {
    const renderCompanyInfo = () => {
        if (!props.client.companyInfo)
            return;

        return (
            <>
                <Label text='Document' content={cnpjFormatter(props.client.companyInfo.document.number)}/>
                <Label text='Name' content={props.client.companyInfo.name}/>
            </>
        )
    }

    return (
        <>
            {!!props.client.document && <Label text='Document' content={cpfFormatter(props.client.document.number)}/>}
            <Label text='Name' content={getFullName(props.client)}/>
            <Label text='Birth or Start Date' content={props.client.birthOrStartDate.toLocaleDateString()}/>
            {renderCompanyInfo()}
        </>
    )
}

export const ClientDocuments = () => {
    const dispatch = useAppDispatch();
    const selectedClient = useSelector(getSelectedClient)!;
    const images = useSelector(getDocumentImages);

    useEffect(() => {
        dispatch(fetchDocumentImages());
        dispatch(fetchIdWallReport());
    }, []);

    const isPendingApproval = selectedClient.status === ClientStatusEnum.pendingApproval;

    return (
        <div className={styles.clientDocuments}>
            <div className={styles.infos}>
                <div>
                    <h3>Informed By Client</h3>
                    <ClientInfo client={selectedClient}/>
                </div>
                <div>
                    <h3>Id Wall Information</h3>
                    <IdWallInfo/>
                </div>
            </div>

            {images && images.map((x, i) => <img src={x} key={i} alt="Document"/>)}

            {isPendingApproval && (
                <div>
                    <Button label="Approve Document" onClick={() => dispatch(openChangeStatusClient(selectedClient))}/>
                </div>
            )}
        </div>
    );
};
