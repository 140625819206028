export type typeAlert = 'success' | 'error';

export interface BasicPerson {
    firstName: string;
    lastName: string;
}

export interface BasicUser extends BasicPerson {
    id: string;
}

export interface BasicClient extends BasicUser {
    email: string;
    phoneNumber: string;
}

export interface HasId {
    id: string;
}

export interface Alert {
    id: string;
    text: string;
    type: typeAlert;
    show: boolean;
}

export interface WithData<T> {
    data: T;
}

export interface BasePagination {
    pageIndex: number;
    pageSize: number;
}

export interface PaginationParams extends BasePagination {
    term?: string;
}

export interface PaginationResponse<T> extends PaginationParams {
    total: number;
    results: T[];
}

export interface Period {
    start: Date;
    end: Date;
}

export interface ConfirmationParams {
    title: string;
    subtitle?: string;
    text: string;
    onConfirm: Function;
}

export enum LoadingStateEnum {
    idle = 1,
    loading = 2,
    done = 3,
}
