import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ClientService from '../services/ClientService';
import { Client } from '../types/Client';
import { EthereumAddress, EthereumAddresses, EthereumAddressWithClient } from '../types/EthereumAddresses';
import { getFullName } from '../utils';
import { AppThunk, RootState } from './index';
import TronGatewayService from '../services/TronGatewayService';
import { EthereumStandaloneTransferRequest } from '../types/EthereumTransfer';
import { showAlert } from './notificationSlice';
import { TronAddress, TronAddressWithClient, TronBalance } from '../types/TronAddress';
import { Amount } from '../types/Amount';

interface TronGatewayState {
    addresses: TronAddress[];
    clients: Client[];
    selectedAddress: TronAddress | null;
    balances: TronBalance[];
    isTransferLoading: boolean;
    transactionId: string | null;
}

const initialState: TronGatewayState = {
    addresses: [],
    clients: [],
    balances: [],
    selectedAddress: null,
    isTransferLoading: false,
    transactionId: null,
};

const tronWalletsSlice = createSlice({
    name: 'tronGateway',
    initialState,
    reducers: {
        addBalance: (state, action: PayloadAction<TronBalance>) => {
            state.balances = [...state.balances, action.payload]
        },
        updateWallets: (state, action: PayloadAction<TronAddress[]>) => {
            state.addresses = action.payload;
        },
        clearTronState: (state) => {
            state.addresses = [];
            state.clients = [];
            state.balances = [];
            state.selectedAddress = null;
        },
        updateClients: (state, action: PayloadAction<Client[]>) => {
            state.clients = action.payload;
        },
        openTransferPopUp: (state, action: PayloadAction<TronAddress>) => {
            state.selectedAddress = action.payload;
        },
        closeTransferPopUp: (state) => {
            state.selectedAddress = null;
            state.transactionId = null;
        },
        setTransferLoading: (state, action: PayloadAction<boolean>) => {
            state.isTransferLoading = action.payload;
        },
        setTransactionId: (state, action: PayloadAction<string>) => {
            state.transactionId = action.payload;
        },
    },
});

export const {
    addBalance,
    clearTronState,
    updateWallets,
    updateClients,
    openTransferPopUp,
    closeTransferPopUp,
    setTransferLoading,
    setTransactionId,
} = tronWalletsSlice.actions;

export const fetchWallets = (): AppThunk => dispatch =>
    TronGatewayService.listWallets()
        .then(async lst => {
            dispatch(updateWallets(lst));

            const promises: Promise<Client>[] = lst
                .map(x => x.clientId)
                .filter(x => !!x)
                .map(x => ClientService.getClient(x!));

            const res = await Promise.allSettled(promises);
            const clients = res
                .filter(x => x.status === 'fulfilled')
                .map(y => (y as PromiseFulfilledResult<Client>).value);

            dispatch(updateClients(clients));
        });

export const fetchBalance = (wallet: TronAddress): AppThunk => dispatch =>
    TronGatewayService.getBalance(wallet.address)
        .then(x => {
            dispatch(addBalance(x));
        });

export const makeTransfer = (req: EthereumStandaloneTransferRequest): AppThunk => dispatch => {
    dispatch(setTransferLoading(true));
    TronGatewayService.transfer(req)
        .then(x => {
            dispatch(setTransactionId(x.txId));
            dispatch(showAlert('Transfer created'));
        })
        .finally(() => {
            dispatch(setTransferLoading(false));
        });
};

export const getAddresses = (state: RootState): TronAddress[] => state.tronWalletsSlice.addresses;
export const getAddressClients = (state: RootState): Client[] => state.tronWalletsSlice.clients;
export const getSelectedAddress = (state: RootState): TronAddress | null => state.tronWalletsSlice.selectedAddress;
export const getTransferIsLoading = (state: RootState): boolean => state.tronWalletsSlice.isTransferLoading;
export const getTransactionId = (state: RootState): string | null => state.tronWalletsSlice.transactionId;
export const getTronBalance = (wallet: TronAddress) => (state: RootState): Amount[] =>
    state.tronWalletsSlice.balances.find(x => x.clientId === wallet.clientId)?.balance || []
export const getSelectedAddressBalance = (state: RootState): Amount[] => {
    const address = getSelectedAddress(state);
    if(!address)
        return [];
    return getTronBalance(address)(state)
}

export const getAddressesWithClients = (state: RootState): TronAddressWithClient[] => {
    const { addresses, clients } = state.tronWalletsSlice;
    if (!addresses)
        return [];

    const findClient = (id: string | undefined): Client | undefined => clients.find(x => x.id === id);

    return addresses.map(address => ({
        ...address,
        client: findClient(address.clientId),
    }));
};

export const findWalletName = (balance: EthereumAddress | TronAddressWithClient | null, clients: Client[] = []): string => {
    if (!balance)
        return '';

    if (!balance.clientId)
        return 'Main';

    const client = (balance as EthereumAddressWithClient).client || clients.find(x => balance.clientId === x.id);
    return getFullName(client);
};

export default tronWalletsSlice.reducer;
